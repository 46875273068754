import React, { useState, useEffect } from "react";
import {
  getReportsByCountry,
  getReportsByCountryFile,
} from "../../../services";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useCurrency, useProfile } from "../../../contexts";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Filters from "../ByGame/components/Filters";
import Tabs from "../ByGame/components/Tabs";
import { BasicTable } from "../ByGame/components/Table";
import { Col, Row, Card, Spinner } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { chartOptions } from "../ByGame/utils";
import { useNotificationContext } from "../../../contexts/notificationContext";
import { useWebSocketContext } from "../../../contexts/websocketContext";
import { getIdToken } from "../../../services/httpClient";
import "../ByGame/styles.scss";
import { useDemo } from "../../../contexts/demoContext";
import CustomFilterModal from "../ByGame/components/CustomFilterModal";

const countriesNames = require("i18n-iso-countries");

countriesNames.registerLocale(require("i18n-iso-countries/langs/en.json"));

ChartJS.register(...registerables);
ChartJS.defaults.color = "#eaecf3";

const ByCountry = () => {
  const [data, setData] = useState();
  const [params, setParams] = useState({
    starting_from: moment().format("YYYY-MM-DD 00:00:01Z"),
    ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
    integrator: "",
    operator: "",
  });

  const { sourceUrl, setSourceUrl } = useWebSocketContext();
  const { resetDownloadsBadge } = useNotificationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { currency } = useCurrency();
  const { organizationId } = useProfile();
  const { is_demo } = useDemo();

  const [range, setRange] = useState("today");
  const [tab, setTab] = useState("users");
  const [activeRange, setActiveRange] = useState("today");
  const [activeTab, setActiveTab] = useState("users");
  const [openCustomFilter, setOpenCustomFilter] = useState(false);

  const [countries, setCountries] = useState();
  const [userCount, setUserCount] = useState();
  const [roundCount, setRoundCount] = useState();
  const [bets, setBets] = useState();
  const [wins, setWins] = useState();
  const [revenue, setRevenue] = useState();
  const [rtp, setRtp] = useState();
  const [freeRoundCount, setFreeRoundCount] = useState();
  const [freeRoundBets, setFreeRoundBets] = useState();
  const [freeRoundWins, setFreeRoundWins] = useState();
  const [freeRoundRevenue, setFreeRoundRevenue] = useState();
  const [roundPerUser, setRoundPerUser] = useState();
  const [betsPerUser, setBetsPerUser] = useState();
  const [revenuePerUser, setRevenuePerUser] = useState();

  const [renderData, setRenderData] = useState(userCount);

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );

  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const { isLoading, refetch, isRefetching } = useQuery(
    [
      "by_country-reports",
      {
        is_demo,
        currency,
        ...params,
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getReportsByCountry,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  const { refetch: fetchByCountryFile } = useQuery(
    [
      "by_country-file",
      {
        is_demo,
        currency,
        ...params,
      },
    ],
    getReportsByCountryFile,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        const newSourceUrl = `${sourceUrl}${data.id}?token=${getIdToken()}`;
        setSourceUrl(newSourceUrl);
      },
    }
  );

  const [chartData, setChartData] = useState({
    type: "bar",
    labels: countries,
    datasets: [
      {
        barPercentage: 1,
        barThickness: 15,
        maxBarThickness: 20,
        minBarLength: 2,
        data: new Array(countries?.length).fill(0),
        backgroundColor: "#38cab3",
      },
    ],
  });

  const modifiedData = data?.map((item) => {
    const { rtp } = item;

    return {
      ...item,
      rtp: rtp.toFixed(2),
    };
  });

  const getCountries = () => {
    const countries = data?.map((data) => {
      return countriesNames.getName(data.country, "en");
    });
    setCountries(Array.from(new Set(countries)));
  };

  const getCountryUsers = () => {
    const countryUsers = data?.reduce((acc, dataItem) => {
      const { country, user_count } = dataItem;

      if (!acc[country]) {
        acc[country] = user_count;
      } else {
        acc[country] += user_count;
      }

      return acc;
    }, {});

    setUserCount(countryUsers);
  };

  const getCountryRounds = () => {
    const countryRounds = data?.reduce((acc, dataItem) => {
      const { country, round_count } = dataItem;

      if (!acc[country]) {
        acc[country] = round_count;
      } else {
        acc[country] += round_count;
      }

      return acc;
    }, {});

    setRoundCount(countryRounds);
  };

  const getCountryBets = () => {
    const countryBets = data?.reduce((acc, dataItem) => {
      const { country, wager } = dataItem;

      if (!acc[country]) {
        acc[country] = wager;
      } else {
        acc[country] += wager;
      }

      return acc;
    }, {});

    setBets(countryBets);
  };

  const getCountryWins = () => {
    const countryWins = data?.reduce((acc, dataItem) => {
      const { country, award } = dataItem;

      if (!acc[country]) {
        acc[country] = award;
      } else {
        acc[country] += award;
      }

      return acc;
    }, {});

    setWins(countryWins);
  };

  const getCountryRevenue = () => {
    const countryRevenue = data?.reduce((acc, dataItem) => {
      const { country, revenue } = dataItem;

      if (!acc[country]) {
        acc[country] = revenue;
      } else {
        acc[country] += revenue;
      }

      return acc;
    }, {});

    setRevenue(countryRevenue);
  };

  const getCountryRtp = () => {
    const countryRtp = data?.reduce((acc, dataItem) => {
      const { country, rtp } = dataItem;

      if (!acc[country]) {
        acc[country] = rtp;
      } else {
        acc[country] += rtp;
      }

      return acc;
    }, {});

    setRtp(countryRtp);
  };

  const getFreeRounds = () => {
    const freeRounds = data?.reduce((acc, dataItem) => {
      const { country, pfr_round_count } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_round_count;
      } else {
        acc[country] += pfr_round_count;
      }

      return acc;
    }, {});

    setFreeRoundCount(freeRounds);
  };

  const getFreeRoundsBets = () => {
    const freeRoundsBets = data?.reduce((acc, dataItem) => {
      const { country, pfr_wager } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_wager;
      } else {
        acc[country] += pfr_wager;
      }

      return acc;
    }, {});

    setFreeRoundBets(freeRoundsBets);
  };

  const getFreeRoundsWins = () => {
    const freeRoundsWins = data?.reduce((acc, dataItem) => {
      const { country, pfr_award } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_award;
      } else {
        acc[country] += pfr_award;
      }

      return acc;
    }, {});

    setFreeRoundWins(freeRoundsWins);
  };

  const getFreeRoundsRevenue = () => {
    const freeRoundsRevenue = data?.reduce((acc, dataItem) => {
      const { country, pfr_revenue } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_revenue;
      } else {
        acc[country] += pfr_revenue;
      }

      return acc;
    }, {});

    setFreeRoundRevenue(freeRoundsRevenue);
  };

  const getRoundsPerUser = () => {
    const roundsPerUser = data?.reduce((acc, dataItem) => {
      const { country, round_per_user } = dataItem;

      if (!acc[country]) {
        acc[country] = round_per_user;
      } else {
        acc[country] += round_per_user;
      }

      return acc;
    }, {});

    setRoundPerUser(roundsPerUser);
  };

  const getBetsPerUser = () => {
    const betsPerUser = data?.reduce((acc, dataItem) => {
      const { country, wager_per_user } = dataItem;

      if (!acc[country]) {
        acc[country] = wager_per_user;
      } else {
        acc[country] += wager_per_user;
      }

      return acc;
    }, {});

    setBetsPerUser(betsPerUser);
  };

  const getRevenuePerUser = () => {
    const revenuePerUser = data?.reduce((acc, dataItem) => {
      const { country, revenue_per_user } = dataItem;

      if (!acc[country]) {
        acc[country] = revenue_per_user;
      } else {
        acc[country] += revenue_per_user;
      }

      return acc;
    }, {});

    setRevenuePerUser(revenuePerUser);
  };

  const handleSubmit = (range) => {
    const queryParams = new URLSearchParams(location.search);
    const formattedStartingFrom = params.starting_from
      ? params.starting_from
      : "";
    const formattedEndingAt = params.ending_at ? params.ending_at : "";
    navigate({
      pathname: "/reports/by-country/",
      search: `?${createSearchParams({
        ...params,
        starting_from: formattedStartingFrom,
        ending_at: formattedEndingAt,
        integrator: queryParams.get("integrator") || "",
        operator: queryParams.get("operator") || "",
      })}`,
    });
    setRange(range);
    setActiveRange(range);
  };

  useEffect(() => {
    getCountries();
    getCountryUsers();
    getCountryRounds();
    getCountryBets();
    getCountryWins();
    getCountryRevenue();
    getCountryRtp();
    getFreeRounds();
    getFreeRoundsBets();
    getFreeRoundsWins();
    getFreeRoundsRevenue();
    getRoundsPerUser();
    getBetsPerUser();
    getRevenuePerUser();
  }, [data]);

  useEffect(() => {
    let dataToRender;
    switch (tab) {
      case "users":
        dataToRender = userCount;
        break;
      case "rounds":
        dataToRender = roundCount;
        break;
      case "bets":
        dataToRender = bets;
        break;
      case "wins":
        dataToRender = wins;
        break;
      case "revenue":
        dataToRender = revenue;
        break;
      case "rtp":
        dataToRender = rtp;
        break;
      case "freeRounds":
        dataToRender = freeRoundCount;
        break;
      case "freeRoundsBets":
        dataToRender = freeRoundBets;
        break;
      case "freeRoundsWins":
        dataToRender = freeRoundWins;
        break;
      case "freeRoundsRevenue":
        dataToRender = freeRoundRevenue;
        break;
      case "roundPerUser":
        dataToRender = roundPerUser;
        break;
      case "betsPerUser":
        dataToRender = betsPerUser;
        break;
      case "revenuePerUser":
        dataToRender = revenuePerUser;
        break;
      default:
        dataToRender = userCount;
        break;
    }
    setRenderData(dataToRender);
  }, [tab, userCount, roundCount, is_demo, currency]);

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: renderData,
        },
      ],
    }));
  }, [renderData, params]);

  useEffect(() => {
    let startingFrom, endingAt, integrator, operator;
    const queryParams = new URLSearchParams(location.search);
    integrator = queryParams.get("integrator") || "";
    operator = queryParams.get("operator") || "";
    if (range === "today") {
      startingFrom = moment().format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD HH:mm:ssZ");
    } else if (range === "yesterday") {
      startingFrom = moment().subtract(1, "day").format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().subtract(1, "day").format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "last-week") {
      startingFrom = moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "last-month") {
      startingFrom = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "3-months") {
      startingFrom = moment()
        .subtract(3, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "6-months") {
      startingFrom = moment()
        .subtract(6, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "1-year") {
      startingFrom = moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "all-time") {
      startingFrom = "";
      endingAt = "";
    }

    setParams({
      starting_from: startingFrom,
      ending_at: endingAt,
      integrator: integrator,
      operator: operator,
    });
  }, [range, location]);

  useEffect(() => {
    refetch();
  }, [
    params,
    currency,
    organizationId,
    is_demo,
    selectedIntegrator,
    selectedOperator,
  ]);

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            By country
          </span>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={12}>
          <div className="mg-b-20">
            <Filters
              setRange={setRange}
              activeRange={activeRange}
              setActiveRange={setActiveRange}
              handleSubmit={handleSubmit}
              setOpenCustomFilter={setOpenCustomFilter}
            />
          </div>
          <Row>
            <Col md={8} className="solid-chart mg-l-80">
              <div className="chart-container">
                {renderData ? (
                  <Bar
                    data={chartData}
                    options={chartOptions}
                    height={100}
                    className="barchart"
                    id="chartBar1"
                  />
                ) : (
                  <Spinner variant="primary" className="" />
                )}
              </div>
            </Col>
            <div>
              <Tabs
                setTab={setTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </Row>
        </Col>
      </Row>
      <div className="d-flex align-items-end justify-content-end mg-t-20 mg-r-25">
        <button
          className="btn btn-primary mg-r-25"
          onClick={() => {
            fetchByCountryFile();
            resetDownloadsBadge();
          }}
        >
          Export
        </button>
      </div>
      <Card className="mg-t-10 card custom-card">
        <Card.Body className="">
          <div className="table-responsive">
            <Row>
              <Col sm={12} className="col-12">
                <BasicTable
                  isGameTable={false}
                  classname="pinned-table"
                  loading={isLoading || isRefetching}
                  data={modifiedData ? modifiedData : []}
                  range={range}
                  setRange={setRange}
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      <CustomFilterModal
        openCustomFilter={openCustomFilter}
        setOpenCustomFilter={setOpenCustomFilter}
        closeModal={() => setOpenCustomFilter(false)}
        params={params}
        setParams={setParams}
        setRange={setRange}
        setActiveRange={setActiveRange}
      />
    </React.Fragment>
  );
};

export default ByCountry;
