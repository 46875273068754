import {Button, FormControl} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {putFreeSpins} from "../api/free_spins";
import { useQuery } from "react-query";
import { getCurrenciesByPair, getOrganizationPair, getOrganizations } from "../../../services";
import { useProfile } from "../../../contexts";

interface FreeSpinButtonProps {
    gameName: string;
    gameOwner: string;
    userID: string;
    currency: string;
    env: string;
    gameWagerSet: any;
    integrator: string;
}

const FreeSpinButton = (props: FreeSpinButtonProps) => {
    const { gameName, gameOwner, userID, currency, env, gameWagerSet, integrator } = props;
    // @ts-ignore
    const { organizationId } = useProfile();

    const [freeSpinURL, setFreeSpinURL] = useState("");
    const [betValue, setBetValue] = useState(gameWagerSet.default_wager);
    const [pair, setPair] = useState(null);
    const [integratorId, setIntegratorId] = useState(null);
    const [multiplier, setMultiplier] = useState(null);

    const handleBetValue = (value: number) => {
        setBetValue(value);
    };

    const { refetch, isLoading, data: multipliers } = useQuery(
        ["get-currencies", { organization_pair_id: pair }],
        getCurrenciesByPair,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: pair !== null,
            onSuccess: (data) => {
                const multipliersData = data.find((item: any) => item?.title === currency.toLowerCase());
                if (multipliersData) {
                    setMultiplier(multipliersData.multiplier);
                }
            },
        }
    );

    const { refetch: refetchPair } = useQuery(
        [
            "organizations",
            {
                integrator_id: integratorId,
                provider_id: organizationId
            },
        ],
        getOrganizationPair,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: integratorId !== null && organizationId !== null,
            onSuccess: data => {
                setPair(data.id);
            },
        }
    );

    let { refetch: refetchIntegrators } = useQuery(
        ["integrators"],
        getOrganizations,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            onSuccess: (data) => {
                const integratorData = data.find((item: any) => item?.name === integrator);
                if (integratorData) {
                    setIntegratorId(integratorData.id);
                }
            },
        }
    );

    function getMainDomain(hostname: string) {
        // Split hostname into parts
        if (hostname === 'localhost') {
            return 'heronbyte.com'
        }
        var parts = hostname.split('.');
        var len = parts.length;

        // Check for known SLDs that use a second level domain (e.g., .co.uk)
        if (len > 2) {
            // List of common second-level domains
            const secondLevelDomains = ['co.uk', 'gov.uk', 'com.au', 'co.in', 'co.jp', 'gov.au', 'gov.in'];
            // Combine the last two parts and check if they form a known second-level domain
            let lastTwoParts = parts[len - 2] + '.' + parts[len - 1];
            if (secondLevelDomains.includes(lastTwoParts)) {
                // Return the last three parts if a known second-level domain is found
                return parts[len - 3] + '.' + lastTwoParts;
            }
        }
        // By default, return the last two parts
        return parts[len - 2] + '.' + parts[len - 1];
    }

    useEffect(() => {
        refetchIntegrators();
    }, []);

    useEffect(() => {
        if (integratorId) {
            refetchPair();
        }
    }, [integratorId]);

    useEffect(() => {
        if (pair) {
            refetch();
        }
    }, [pair]);

    useEffect(() => {
        if (multipliers) {
            const multipliersData = multipliers.find((item: any) => item?.title === currency.toLowerCase());
            if (multipliersData) {
                setMultiplier(multipliersData.multiplier);
            }
        }
    }, [currency]);

    useEffect(() => {
        const domainName = getMainDomain(window.location.hostname)

        const url = new URL(`https://${env !== '' ? `${env}-` : ''}games.${domainName}/pfr/create`);
        setFreeSpinURL(url.toString());
    }, [env]);

    return (
        <div className="d-flex">
            <button
                className="btn btn-outline-light mg-r-15"
                onClick={() => {
                    putFreeSpins(freeSpinURL, gameName, userID, currency, betValue);
                }}
            >
                Add 2 Free spins for this user
            </button>
            <FormControl>
                <select className="form-control" value={betValue} onChange={e => handleBetValue(Number(e.target.value))}>
                    <option value={0}>0</option>
                    {gameWagerSet?.wager_levels?.map(
                        (wager: number, index: number) => (
                            <option key={index} value={multiplier ? wager * multiplier : wager}>
                                {multiplier ? wager * multiplier : wager}
                            </option>
                        ),
                    )}
                </select>
            </FormControl>
        </div>
    );
};

export {FreeSpinButton};
