import React, {useEffect, useState} from 'react';
import {Button, Modal, Row} from "react-bootstrap";
import {useMutation} from "react-query";
import {updateWagerSet} from "../../../../services";
import {Slide, toast} from "react-toastify";

const ConfirmModal = ({ confirmModal, closeModal, onSettled, selectedItem }) => {

    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const {mutate} = useMutation(updateWagerSet, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            closeModal("confirmModal");
        },
        onError: (error) => {
            setError(error.response.data.data);
            setHasError(true);
            closeModal("confirmModal");
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Wager set status was updated successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

        const onSubmit = () => {
            let formData = {};
            formData.name = selectedItem?.name;
            formData.id = selectedItem?.id;
            formData.default_wager = selectedItem?.default_wager;
            formData.wager_levels = selectedItem?.wager_levels;
            formData.is_active = !selectedItem?.is_active;
            console.log(formData)
            mutate(formData);
        }

    useEffect(() => {
        if (hasError) {
            toastAlert("error");
            setHasError(false);
        }
    }, [error, hasError]);

    return (
        <Modal
            size="sm"
            show={confirmModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        closeModal("confirmModal")
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className='wager-sets-modal'>
                    <Row className="row-sm">
                        <div>
                            <h3 className='font-weight-bold text-center mg-45'>Are you sure?</h3>
                        </div>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button className='wd-45p' variant="primary" onClick={() => {
                    onSubmit();
                    closeModal("confirmModal")
                }}>
                    Yes
                </Button>
                <Button className='wd-45p' variant="secondary" onClick={() => {
                    closeModal("confirmModal")
                }}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;