import React from "react";
import { bonusSpinCounter } from "../helpers/bonusGame/bonusSpinCounter";
import { totalPaylines } from "../helpers/paylines/totalPaylines";

import { isFSRetriggered } from "../helpers/freespins/freespinRetrigger";
import { shouldShowRetriggerFreeSpin } from "../helpers/freespins/shouldShowRetriggerFreespin";

export const GameStatisticsTable = ({ data, gameName }) => {
  return (
    <>
      <table className="game-stats-info__stats-class">
        <thead>
          <tr>
            <td colSpan="2">Game Statistics</td>
          </tr>
        </thead>
        <tbody>
          {gameName !== "plinko" && gameName !== "aviator" && (
            <tr>
              <td>Base Game Paylines</td>
              <td id="totalPayline">
                {totalPaylines(gameName, data) || " - "}
              </td>
            </tr>
          )}
          <tr>
            <td>Wager</td>
            <td id="totalBet">{data?.wager}</td>
          </tr>
          {gameName !== "plinko" && gameName !== "aviator" && (
            <tr>
              <td>Total Award</td>
              <td id="totalWin">{data?.base_award + data?.bonus_award}</td>
            </tr>
          )}
          <tr>
            <td>Base award</td>
            <td id="currentWin">{data?.base_award}</td>
          </tr>
          {shouldShowRetriggerFreeSpin(gameName) && (
            <tr>
              <td>Free spin Re-triggered</td>
              <td id="retrigerFreespin">{isFSRetriggered(data)}</td>
            </tr>
          )}
          <tr>
            <td>Currency</td>
            <td id="currency">{data?.currency?.toUpperCase()}</td>
          </tr>
          {bonusSpinCounter(gameName, data) !== "-" && (
            <tr id="freespinCounterInfo">
              <td>Free Spin Counter:</td>
              <td id="freespinCounterNum">
                {bonusSpinCounter(gameName, data) || "0"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
