import httpClient from "./httpClient";

export const authLogIn = (data) => httpClient.post("/auth/login", { ...data });
export const authChangeOrganization = (data) =>
  httpClient.post("/auth/organization", { ...data });

export const getUserProfile = async () => {
  const { data } = await httpClient.get("auth/session");
  if (data) {
    return data.data;
  }
};
export const updatePassword = async (formData) => {
  const { data } = await httpClient.post("/auth/password/change", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const resetPasswordRequest = async (formData) => {
  const { data } = await httpClient.post("/auth/password/reset", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const resetPassword = async (formData) => {
  const { data } = await httpClient.post(
    `/auth/password/reset/${formData.token}`,
    {
      ...formData,
    }
  );
  if (data) {
    return data.data;
  }
};
