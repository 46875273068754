import React from "react";

const transpose = (array) => {
  if (array) {
    return array[0].map((_, colIndex) => array.map((row) => row[colIndex]));
  }
  return null;
};

export const BonusMultiplayerTable = ({ data }) => {
  const transposedData = transpose(data.window);

  return (
    <>
      {transposedData?.length > 0 && (
        <>
          <p className="tx-16 tx-bold mt-4 mb-2">Bonus multiplier game</p>
          <table
            className="game-stats-info__stats-class mt-0 mb-4"
            id="win_lines_table"
          >
            <thead>
              <tr>
                <td colSpan={transposedData[0].length}>
                  Total win: {data.award / 1000}
                </td>
              </tr>
            </thead>
            <tbody>
              {transposedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell / 1000}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
