import React from "react";
import {
  RevenueBlock,
  PerformanceBlock,
  GamePerformanceBlock,
} from "./components";
import { getGames, getHosts } from "../../services";
import { useQuery } from "react-query";

const Dashboard = () => {
  const { data: games } = useQuery(["games"], getGames, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const { data: hosts } = useQuery(["hosts"], getHosts, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Dashboard</span>
        </div>
      </div>
      <RevenueBlock />
      <div className="py-3">
        <PerformanceBlock games={games} hosts={hosts} />
      </div>
      <div className="pb-3">
        <PerformanceBlock games={games} hosts={hosts} type="daily" />
      </div>
      <GamePerformanceBlock games={games} hosts={hosts} />
    </React.Fragment>
  );
};

export default Dashboard;
