import React, { useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { ResultTable } from "../tables/ResultTable";
import { stringify } from "../../../utilities";

export const ResultBlock = ({ data, isPublick }) => {
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState("details");

  useEffect(() => {
    setOpen(isPublick);
  }, [isPublick]);

  return (
    <div className="bd-b mt-4">
      <div
        className="d-flex align-items-center justify-content-between pb-2 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <h5 className="mb-0 text-info tx-bold tx-16">Result</h5>
        <i className={`ms-auto text-info fe fe-${open ? "minus" : "plus"}`}></i>
      </div>
      <Collapse in={open}>
        <div>
          <div className="text-wrap">
            <div className="btn-group my-3">
              <button
                className={`btn btn${tab === "details" ? "" : "-outline"}-info`}
                onClick={() => tab !== "details" && setTab("details")}
              >
                Details
              </button>
              <button
                className={`btn btn${tab === "json" ? "" : "-outline"}-info`}
                onClick={() => tab !== "json" && setTab("json")}
              >
                Raw JSON
              </button>
            </div>
          </div>
          {tab === "details" ? (
            <>
              <ResultTable gameName={data?.game} data={data} />
            </>
          ) : (
            <div className="bg-gray-200 radius-10 overflow-hidden mb-3 wd-80p mx-auto">
              <pre className="overflow-scroll" style={{ maxHeight: 700 }}>
                {stringify(data?.spin)}
              </pre>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};
