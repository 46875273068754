import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Row, Card, Col, Table } from "react-bootstrap";
import { getCurrenciesExchangeRate } from "../../../services";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DeleteExchangeModal } from "./DeleteExchangeModal";
import { CreateExchangeModal } from "./CreateExchangeModal";

export const ExchangeRateModal = ({ setShowExchangeRateModal, allias }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const { data, isLoading } = useQuery(
    [allias, { limit, page, order: 'created_at DESC' }],
    getCurrenciesExchangeRate,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  return (
    <>
      {showDeleteModal && (
        <DeleteExchangeModal
          setShowModal={setShowDeleteModal}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          allias={allias}
          limit={limit}
          page={page}
        />
      )}

      {showAddModal && (
        <CreateExchangeModal setShowModal={setShowAddModal} allias={allias} />
      )}

      <Modal
        show
        onHide={() => setShowExchangeRateModal(false)}
        centered={true}
        size="lg"
      >
        <Modal.Header>
          <h6 className="modal-title">Create currency</h6>
          <Button
            variant=""
            type="button"
            onClick={() => setShowExchangeRateModal(false)}
          >
            <span aria-hidden="true" className="text-dark">
              X
            </span>
          </Button>
        </Modal.Header>
        <Row>
          <form
            className="wd-95p mg-l-20"
            id="filters"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="w-100 d-inline-block">
              <div className="row align-items-center justify-content-between">
                <div className="form-group col-2">
                  <div
                    className="tx-15 mg-l-45 mg-t-30 cursor-pointer"
                    onClick={() => setShowAddModal(true)}
                  >
                    <span>Create</span>
                    <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Row>
        <Modal.Body style={{ width: "100%" }}>
          <Table striped="columns">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Created at</th>
                <th>From currency</th>
                <th>To currency</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {data
                ?.slice()
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item.created_at}</td>
                    <td>{item.from}</td>
                    <td>{item.to}</td>
                    <td>{item.rate}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {/* <button
                          className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 mg-r-10"
                          onClick={() => setShowAddModal(true)}
                        >
                          <i className="fe fe-edit-3" />
                        </button> */}
                        <button
                          className={`btn btn-outline-danger d-flex align-items-center justify-content-center p-1`}
                          onClick={() => {
                            setSelectedData(item);
                            setShowDeleteModal(true);
                          }}
                        >
                          <i className="fe fe-trash" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
//  <Spinner
//             animation="border"
//             variant="primary"
//             className="spinner-border me-2 text-light"
//             role="status"
//           >
//             <span className="sr-only">Loading...</span>
//           </Spinner>
