import React, { useEffect, useState, useRef } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Table } from "../../../components/Tables";
import { useQuery, useMutation } from "react-query";
import {
  getCurrenciesByPair,
  getOrganizationPair,
  getOrganizations,
} from "../../../services";
import {
  getReportsByMultiplier,
  uploadReportsByMultiplier,
} from "../../../services";
import { EditCurrency } from "./components";
import { DeleteCurrency } from "./components/DeleteModal";
import { AddCurrency } from "./components/CreateModal";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

const Currencies = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [pair, setPair] = useState([]);
  const [integrators, setIntegrators] = useState([]);
  const [providers, setProviders] = useState([]);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedOrganizationName, setSelectedOrganizationName] =
    useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedProviderName, setSelectedProviderName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const {
    refetch,
    isLoading,
    data: multipliers,
  } = useQuery(
    ["get-currencies", { organization_pair_id: pair }],
    getCurrenciesByPair,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: pair.length !== 0,
    }
  );

  let { refetch: refetchIntegrators } = useQuery(
    ["integrators"],
    getOrganizations,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setIntegrators(
          data.filter((organization) => organization.type === "integrator")
        );
        setProviders(data.filter((org) => org.type === "provider"));
      },
    }
  );

  const { refetch: refetchPair } = useQuery(
    [
      "organizations",
      {
        integrator_id: selectedOrganization,
        provider_id: selectedProvider,
      },
    ],
    getOrganizationPair,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: selectedOrganization !== null && selectedProvider !== null,
      onSuccess: (data) => {
        setPair(data.id);
      },
      onError: (error) => {
        setError(error.response?.data?.data || "An unknown error occurred");
        if (error.response?.data?.data === "not found") {
          setHasError(false);
          setPair([]);
        } else {
          setHasError(true);
        }
      },
    }
  );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Currency",
      highlight: true,
      render: (data) => (
        <div
          className="d-flex align-items-center justify-content-center"
          onClick={() => {
            setSelectedData(data);
            setShowEditModal(true);
          }}
        >
          <span
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data?.title?.toUpperCase()}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "provider_integrator_pair",
      name: "Integrator",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <span
            className="d-block text-truncate me-2"
            style={{
              maxWidth: 140,
            }}
          >
            {data?.integrator?.name}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "provider_integrator_pair",
      name: "Provider",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data?.provider?.name}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "multiplier",
      name: "Multiplier",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "synonym",
      name: "Synonym",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <button
            className={`btn btn-outline-danger d-flex align-items-center justify-content-center p-1`}
            onClick={() => {
              setSelectedData(data);
              setShowDeleteModal(true);
            }}
          >
            <i className="fe fe-trash" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetchIntegrators();
  }, []);

  useEffect(() => {
    if (pair.length !== 0) {
      refetch();
    }
  }, [pair]);

  useEffect(() => {
    if (selectedOrganization !== null && selectedProvider !== null) {
      refetchPair();
    }
  }, [selectedOrganization, selectedProvider, refetchPair]);

  const { refetch: fetchByCurrencyMultiplier } = useQuery(
    ["by_country-file", pair],
    () => getReportsByMultiplier({ organization_pair_id: pair }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `multipliers_${selectedOrganizationName}_${selectedProviderName}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      },
    }
  );

  const mutation = useMutation(uploadReportsByMultiplier, {
    onError: (error) => {
      setError(error);
      setHasError(true);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Integration was modified successfully`
          : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
    }
  }, [hasError]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("organization_pair_id", pair);

      mutation.mutate({ formData });
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleChangeOrganization = (value) => {
    const selectedOrg = integrators.find((org) => org.id === value);
    if (selectedOrg) {
      setSelectedOrganization(value);
      setSelectedOrganizationName(selectedOrg.name);
    }
  };

  const handleChangeProvider = (value) => {
    const provider = providers.find((prov) => prov.id === value);
    if (provider) {
      setSelectedProvider(value);
      setSelectedProviderName(provider.name);
    }
  };

  return (
    <>
      {showEditModal && selectedData && (
        <EditCurrency
          setShowModal={setShowEditModal}
          selectedData={selectedData}
          data={selectedData}
          setSelectedData={setSelectedData}
        />
      )}

      {showDeleteModal && selectedData && (
        <DeleteCurrency
          setShowModal={setShowDeleteModal}
          selectedData={selectedData}
          data={selectedData}
          setSelectedData={setSelectedData}
        />
      )}

      {showAddModal && (
        <AddCurrency
          setShowModal={setShowAddModal}
          integrators={integrators}
          providers={providers}
          error={error}
          setError={setError}
          setHasError={setHasError}
        />
      )}

      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Currency Multipliers
          </span>
        </div>
      </div>

      <Row>
        <form
          className="wd-95p mg-l-20"
          id="filters"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="w-100 d-inline-block">
            <div
              className="row align-items-center justify-content-between"
              style={{ maxWidth: "1235px" }}
            >
              <div className="wd-40p d-flex align-items-center justify-content-between">
                <div className="wd-200">
                  <label>Integrator</label>
                  <Select
                    className="form-control"
                    optionFilterProp="children"
                    placeholder={"Select integrator..."}
                    value={selectedOrganization}
                    onChange={handleChangeOrganization}
                    options={
                      integrators?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      })) || []
                    }
                  />
                </div>
                <div className="wd-200">
                  <label>Provider</label>
                  <Select
                    className="form-control"
                    optionFilterProp="children"
                    placeholder={"Select provider..."}
                    value={selectedProvider}
                    onChange={handleChangeProvider}
                    options={
                      providers?.map((item) => ({
                        value: item?.id,
                        label: item?.name,
                      })) || []
                    }
                  />
                </div>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className="tooltip tooltip-primary1">
                      Choose integrator and provider to get all available
                      currencies
                    </Tooltip>
                  }
                >
                  <i className="fe fe-info cursor-pointer text-muted mg-t-25"></i>
                </OverlayTrigger>
              </div>
              <div
                className="form-group col-2"
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "baseline",
                }}
              >
                <button
                  disabled={pair.length === 0}
                  className="btn btn-primary"
                  onClick={() => {
                    fetchByCurrencyMultiplier();
                  }}
                >
                  Export
                </button>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "baseline",
                  }}
                >
                  <i
                    className="bi bi-upload"
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    disabled={!selectedFile || !pair}
                    onClick={triggerFileInput}
                  ></i>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    disabled={!selectedFile || !pair}
                    onClick={handleUpload}
                    className="btn btn-primary"
                  >
                    Upload
                  </button>
                </div>
                <div
                  className="tx-15 mg-t-30"
                  onClick={() => setShowAddModal(true)}
                >
                  <span>Create</span>
                  <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Row>

      <Row className="mb-3 mt-4 justify-content-center">
        <Col xl={11}>
          <Card>
            <Card.Body className="pt-4 example1-table">
              <Table
                loading={isLoading}
                data={multipliers ? multipliers : []}
                columns={columns}
                total={multipliers ? multipliers?.length : 0}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Currencies;
