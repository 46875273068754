import { CONFIG } from "../config";
import { apiInfo } from "./auth";

const getHistory = (onSuccess: (records: never[]) => void, onFailure: () => void): void => {
    fetch(`${CONFIG.server_url}history`, {
        method: "GET",
        headers: {
            "X-Auth": apiInfo.accessToken,
        },
    })
        .then(res => res.json())
        .then(result => onSuccess(result.data))
        .catch(onFailure);
};

const putHistory = (body: any) => {
    fetch(`${CONFIG.server_url}history`, {
        method: "POST",
        headers: {
            "X-Auth": apiInfo.accessToken,
        },
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
    });
};

export { getHistory, putHistory };
