export const symbolImageLink = (gameName) => {
  let imageLink;
  switch (gameName) {
    case "stones-of-magic":
      imageLink = "stonesofmagic";
      break;
    case "book-of-jones-96":
    case "book-of-jones-94":
      imageLink = "book_of_jones";
      break;
    case "smashing-hot":
    case "smashing-hot-20":
    case "smashing-hot-94":
    case "smashing-hot-96":
    case "smashing-hot-20-94":
    case "smashing-hot-20-96":
      imageLink = "smashing_hot";
      break;
    case "asgard-party":
      imageLink = "asgardparty";
      break;
    case "sweet-mystery-flexiways":
      imageLink = "sweet-mystery-flexiways";
      break;
    case "coral-reef-flexiways":
      imageLink = "coral-reef-flexiways";
      break;
    case "cat-mansion":
      imageLink = "cat-mansion";
      break;
    case "coral-cash":
      imageLink = "coral-cash";
      break;
    case "anubis-secret":
      imageLink = "anubis-secret";
      break;
    case "viking-saga-flexiways":
      imageLink = "viking-saga-flexiways";
      break;
    case "ego-draconis":
      imageLink = "ego_draconis";
      break;
    case "burning-20-wins":
      imageLink = "burning_20_wins";
      break;
    case "book-of-dynasty":
    case "book-of-dynasty-94":
    case "book-of-dynasty-96":
      imageLink = "book_of_dynasty";
      break;
    case "book-of-sacred":
    case "book-of-sacred-94":
    case "book-of-sacred-96":
      imageLink = "book_of_sacred";
      break;
    case "blazing-clovers":
    case "blazing-clovers-94":
    case "blazing-clovers-96":
    case "blazing-clovers-20":
    case "blazing-clovers-20-94":
    case "blazing-clovers-20-96":
    case "blazing-clovers-40":
    case "blazing-clovers-40-94":
    case "blazing-clovers-40-96":
      imageLink = "blazing_clovers";
      break;
    case "cyber-town":
      imageLink = "cyber_town";
      break;
    case "secret-totems":
      imageLink = "secrettotems";
      break;
    case "precious-bugs":
      imageLink = "preciousbugs";
      break;
    case "wild-west-john":
    case "wild-west-john-93":
    case "wild-west-john-95":
      imageLink = "wild_west_john";
      break;
    case "majestic-crown":
    case "majestic-crown-94":
    case "majestic-crown-96":
      imageLink = "magestic_crown";
      break;
    case "lucky-pint":
      imageLink = "lucky_pint";
      break;
    case "retro-royale":
      imageLink = "retro_royale";
      break;
    case "undead-riches":
      imageLink = "undead_riches";
      break;
    case "egyptians-book":
      imageLink = "egyptians_book";
      break;
    case "toreadors-fortune":
      imageLink = "toreadors_fortune";
      break;
    case "olympus-quest":
      imageLink = "olympus_quest";
      break;
    case "green-gold":
      imageLink = "green_gold";
      break;
    case "toucan-fruits":
      imageLink = "toucan_fruits";
      break;
    case "crystal-ways":
      imageLink = "crystal_ways";
      break;
    case "magic-moonlight":
      imageLink = "magic_moonlight";
      break;
    case "cleos-riches-flexiways":
      imageLink = "cleos-riches-flexiways";
      break;
    case "great-bear-multiways":
      imageLink = "great-bear-multiways";
      break;
    case "witch-of-fortune-multiways":
      imageLink = "witch-of-fortune-multiways";
      break;
    case "odins-gold-spins-dice":
      imageLink = "odins_gold_spins_dice";
      break;
    case "lucky-skulls-bonanza":
      imageLink = "lucky-skulls-bonanza";
      break;
    case "rsh-gates-of-olympus":
      imageLink = "rsh-gates-of-olympus";
      break;
    case "gamblers-gala":
      imageLink = "gamblers-gala";
      break;
    case "rush-moji":
      imageLink = "rush-moji";
      break;
    case "tutti-frutti":
      imageLink = "tutti-frutti";
      break;
    case "candy-crashout":
      imageLink = "candy-crashout";
      break;
    case "cowboy-cashout":
      imageLink = "cowboy-cashout";
      break;
    case "zeus-vs-hades":
      imageLink = "zeus-vs-hades";
      break;
    case "lucky-santa-bonanza":
      imageLink = "lucky-santa-bonanza";
      break;
    case "jelly-joy-bonanza":
      imageLink = "jelly-joy-bonanza";
      break;
    case "irish-riches-bonanza":
      imageLink = "irish-riches-bonanza";
      break;
    case "clover-cluster":
      imageLink = "clover-cluster";
      break;
    case "horror-depths-respin":
      imageLink = "horror-depths-respin";
      break;
    case "quest-of-ra":
      imageLink = "quest-of-ra";
      break;
    case "dragons-888":
      imageLink = "dragons-888";
      break;
    case "carnival-beauty-respin":
      imageLink = "carnival-beauty-respin";
      break;
    case "stellar-wilds":
    case "stellar-wilds-xtreme":
      imageLink = "stellar-wilds";
      break;
    case "gonzo-quest":
      imageLink = "gonzo-quest";
      break;
    case "vampire-vault-hold-n-win":
      imageLink = "vampire-vault-hold-n-win";
      break;
    case "wild-dragon-respin":
      imageLink = "wild-dragon-respin";
      break;
    case "admiral-wilds":
      imageLink = "admiral-wilds";
      break;
    case "king-of-vegas-nights":
      imageLink = "king-of-vegas-nights";
      break;
    case "dino-reels":
      imageLink = "dino-reels";
      break;
    case "fortune-777-respin":
      imageLink = "fortune-777-respin";
      break;
    case "yakuza-clash-hold-n-win":
      imageLink = "yakuza-clash-hold-n-win";
      break;
    case "rio-bay-hold-n-win":
      imageLink = "rio-bay-hold-n-win";
      break;
    default:
      imageLink = null;
      break;
  }
  return imageLink;
};
