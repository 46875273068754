import getSymbolFromCurrency from "currency-symbol-map";
import { addComma } from "./";

export const convertToCurrency = (data, currency, divide = false) => {
  let newData = addComma(Math.abs(data));
  if (divide) {
    newData = Math.abs(data) / 100;
  }
  return `${data < 0 ? "-" : ""}${
    getSymbolFromCurrency(currency?.toUpperCase()) || currency?.toUpperCase()
  }${newData}`;
};
