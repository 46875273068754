import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { chartOptions, formatGameName } from "../utils";
import { Chart as ChartJS, registerables } from "chart.js";
import { useQuery } from "react-query";
import { getGameReport } from "../../../../services";
import { useCurrency } from "../../../../contexts";
import { useDemo } from "../../../../contexts/demoContext";
import Filters from "./Filters";
import Tabs from "./Tabs";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { BasicTable } from "./Table";

const countriesNames = require("i18n-iso-countries");
countriesNames.registerLocale(require("i18n-iso-countries/langs/en.json"));

ChartJS.register(...registerables);
ChartJS.defaults.color = "#eaecf3";

const useHandleQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const GamePopup = ({
  showGameModal,
  closeModal,
  selectedItem,
  range,
  setRange,
}) => {
  const query = useHandleQuery();
  const integrator = query.get("integrator") || "";

  const [params, setParams] = useState({
    starting_from: moment().format("YYYY-MM-DD 00:00:01Z"),
    ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
    integrator: integrator,
  });

  const [countries, setCountries] = useState();
  const [data, setData] = useState();
  const [fullscreen, setFullscreen] = useState(true);
  // const [range, setRange] = useState("today");
  const [tab, setTab] = useState("users");
  const [activeRange, setActiveRange] = useState("today");
  useEffect(() => {
    setActiveRange(range);
  }, [range]);

  const [activeTab, setActiveTab] = useState("users");

  const [userCount, setUserCount] = useState();
  const [roundCount, setRoundCount] = useState();
  const [bets, setBets] = useState();
  const [wins, setWins] = useState();
  const [revenue, setRevenue] = useState();
  const [rtp, setRtp] = useState();
  const [freeRoundCount, setFreeRoundCount] = useState();
  const [freeRoundBets, setFreeRoundBets] = useState();
  const [freeRoundWins, setFreeRoundWins] = useState();
  const [freeRoundRevenue, setFreeRoundRevenue] = useState();
  const [roundPerUser, setRoundPerUser] = useState();
  const [betsPerUser, setBetsPerUser] = useState();
  const [revenuePerUser, setRevenuePerUser] = useState();

  const [renderData, setRenderData] = useState(userCount);

  const { currency } = useCurrency();
  const { is_demo } = useDemo();
  const navigate = useNavigate();
  const location = useLocation();
  let game = selectedItem;

  const { refetch, isRefetching, isLoading } = useQuery(
    [
      "game-report",
      game,
      {
        is_demo,
        currency,
        ...params,
      },
    ],
    getGameReport,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  const [chartData, setChartData] = useState({
    type: "bar",
    labels: countries,
    datasets: [
      {
        barPercentage: 1,
        barThickness: 15,
        maxBarThickness: 20,
        minBarLength: 2,
        data: new Array(countries?.length).fill(0),
        backgroundColor: "#38cab3",
      },
    ],
  });

  const getCountries = () => {
    const countries = data?.map((data) => {
      return countriesNames.getName(data.country, "en");
    });
    setCountries(Array.from(new Set(countries)));
  };

  const getCountryUsers = () => {
    const countryUsers = data?.reduce((acc, dataItem) => {
      const { country, user_count } = dataItem;

      if (!acc[country]) {
        acc[country] = user_count;
      } else {
        acc[country] += user_count;
      }

      return acc;
    }, {});

    setUserCount(countryUsers);
  };

  const getCountryRounds = () => {
    const countryRounds = data?.reduce((acc, dataItem) => {
      const { country, round_count } = dataItem;

      if (!acc[country]) {
        acc[country] = round_count;
      } else {
        acc[country] += round_count;
      }

      return acc;
    }, {});

    setRoundCount(countryRounds);
  };

  const getCountryBets = () => {
    const countryBets = data?.reduce((acc, dataItem) => {
      const { country, wager } = dataItem;

      if (!acc[country]) {
        acc[country] = wager;
      } else {
        acc[country] += wager;
      }

      return acc;
    }, {});

    setBets(countryBets);
  };

  const getCountryWins = () => {
    const countryWins = data?.reduce((acc, dataItem) => {
      const { country, award } = dataItem;

      if (!acc[country]) {
        acc[country] = award;
      } else {
        acc[country] += award;
      }

      return acc;
    }, {});

    setWins(countryWins);
  };

  const getCountryRevenue = () => {
    const countryRevenue = data?.reduce((acc, dataItem) => {
      const { country, revenue } = dataItem;

      if (!acc[country]) {
        acc[country] = revenue;
      } else {
        acc[country] += revenue;
      }

      return acc;
    }, {});

    setRevenue(countryRevenue);
  };

  const getCountryRtp = () => {
    const countryRtp = data?.reduce((acc, dataItem) => {
      const { country, rtp } = dataItem;

      if (!acc[country]) {
        acc[country] = rtp;
      } else {
        acc[country] += rtp;
      }

      return acc;
    }, {});

    setRtp(countryRtp);
  };

  const getFreeRounds = () => {
    const freeRounds = data?.reduce((acc, dataItem) => {
      const { country, pfr_round_count } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_round_count;
      } else {
        acc[country] += pfr_round_count;
      }

      return acc;
    }, {});

    setFreeRoundCount(freeRounds);
  };

  const getFreeRoundsBets = () => {
    const freeRoundsBets = data?.reduce((acc, dataItem) => {
      const { country, pfr_wager } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_wager;
      } else {
        acc[country] += pfr_wager;
      }

      return acc;
    }, {});

    setFreeRoundBets(freeRoundsBets);
  };

  const getFreeRoundsWins = () => {
    const freeRoundsWins = data?.reduce((acc, dataItem) => {
      const { country, pfr_award } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_award;
      } else {
        acc[country] += pfr_award;
      }

      return acc;
    }, {});

    setFreeRoundWins(freeRoundsWins);
  };

  const getFreeRoundsRevenue = () => {
    const freeRoundsRevenue = data?.reduce((acc, dataItem) => {
      const { country, pfr_revenue } = dataItem;

      if (!acc[country]) {
        acc[country] = pfr_revenue;
      } else {
        acc[country] += pfr_revenue;
      }

      return acc;
    }, {});

    setFreeRoundRevenue(freeRoundsRevenue);
  };

  const getRoundsPerUser = () => {
    const roundsPerUser = data?.reduce((acc, dataItem) => {
      const { country, round_per_user } = dataItem;

      if (!acc[country]) {
        acc[country] = round_per_user;
      } else {
        acc[country] += round_per_user;
      }

      return acc;
    }, {});

    setRoundPerUser(roundsPerUser);
  };

  const getBetsPerUser = () => {
    const betsPerUser = data?.reduce((acc, dataItem) => {
      const { country, wager_per_user } = dataItem;

      if (!acc[country]) {
        acc[country] = wager_per_user;
      } else {
        acc[country] += wager_per_user;
      }

      return acc;
    }, {});

    setBetsPerUser(betsPerUser);
  };

  const getRevenuePerUser = () => {
    const revenuePerUser = data?.reduce((acc, dataItem) => {
      const { country, revenue_per_user } = dataItem;

      if (!acc[country]) {
        acc[country] = revenue_per_user;
      } else {
        acc[country] += revenue_per_user;
      }

      return acc;
    }, {});

    setRevenuePerUser(revenuePerUser);
  };

  const modifiedData = data?.map((item) => {
    const { rtp } = item;

    return {
      ...item,
      rtp: rtp.toFixed(2),
    };
  });

  const handleSubmit = (range) => {
    const formattedStartingFrom = params.starting_from
      ? params.starting_from
      : "";
    const formattedEndingAt = params.ending_at ? params.ending_at : "";
    navigate({
      pathname: "/reports/by-country/",
      search: `?${createSearchParams({
        ...params,
        starting_from: formattedStartingFrom,
        ending_at: formattedEndingAt,
      })}`,
    });
    setRange(range);
    setActiveRange(range);
  };

  useEffect(() => {
    refetch();
  }, [params, selectedItem]);

  useEffect(() => {
    getCountries();
    getCountryUsers();
    getCountryRounds();
    getCountryBets();
    getCountryWins();
    getCountryRevenue();
    getCountryRtp();
    getFreeRounds();
    getFreeRoundsBets();
    getFreeRoundsWins();
    getFreeRoundsRevenue();
    getRoundsPerUser();
    getBetsPerUser();
    getRevenuePerUser();
  }, [data]);

  useEffect(() => {
    let dataToRender;
    switch (tab) {
      case "users":
        dataToRender = userCount;
        break;
      case "rounds":
        dataToRender = roundCount;
        break;
      case "bets":
        dataToRender = bets;
        break;
      case "wins":
        dataToRender = wins;
        break;
      case "revenue":
        dataToRender = revenue;
        break;
      case "rtp":
        dataToRender = rtp;
        break;
      case "freeRounds":
        dataToRender = freeRoundCount;
        break;
      case "freeRoundsBets":
        dataToRender = freeRoundBets;
        break;
      case "freeRoundsWins":
        dataToRender = freeRoundWins;
        break;
      case "freeRoundsRevenue":
        dataToRender = freeRoundRevenue;
        break;
      case "roundPerUser":
        dataToRender = roundPerUser;
        break;
      case "betsPerUser":
        dataToRender = betsPerUser;
        break;
      case "revenuePerUser":
        dataToRender = revenuePerUser;
        break;
      default:
        dataToRender = userCount;
        break;
    }
    setRenderData(dataToRender);
  }, [tab, userCount, roundCount, is_demo, currency]);

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: renderData,
        },
      ],
    }));
  }, [renderData, params]);

  useEffect(() => {
    let startingFrom, endingAt;
    if (range === "today") {
      startingFrom = moment().format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD HH:mm:ssZ");
    } else if (range === "yesterday") {
      startingFrom = moment().subtract(1, "day").format("YYYY-MM-DD HH:mm:ssZ");
      endingAt = moment().format("YYYY-MM-DD HH:mm:ssZ");
    } else if (range === "last-week") {
      startingFrom = moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "last-month") {
      startingFrom = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "3-months") {
      startingFrom = moment()
        .subtract(3, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "6-months") {
      startingFrom = moment()
        .subtract(6, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "1-year") {
      startingFrom = moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "all-time") {
      startingFrom = "";
      endingAt = "";
    }
    setParams({
      starting_from: startingFrom,
      ending_at: endingAt,
      integrator: integrator,
    });
  }, [range, location]);

  return (
    <Modal
      fullscreen={fullscreen}
      show={showGameModal}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
    >
      <Modal.Header>
        <Modal.Title>{formatGameName(selectedItem)}</Modal.Title>
        <Button
          variant=""
          className="btn btn-close ms-auto"
          onClick={() => {
            closeModal("showGameModal");
          }}
        >
          x
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="row-sm">
            <Col md={12}>
              <div className="mg-b-20">
                <Filters
                  setRange={setRange}
                  activeRange={activeRange}
                  setActiveRange={setActiveRange}
                  handleSubmit={handleSubmit}
                  isPopup={fullscreen}
                />
              </div>
              <Row>
                <Col md={8} className="solid-chart mg-l-80">
                  <div className="chart-container">
                    {renderData ? (
                      <Bar
                        data={chartData}
                        options={chartOptions}
                        height={100}
                        className="barchart"
                        id="chartBar1"
                      />
                    ) : (
                      <Spinner variant="primary" className="" />
                    )}
                  </div>
                </Col>
                <div>
                  <Tabs
                    setTab={setTab}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Card className="mg-t-10 card custom-card">
            <Card.Body className="">
              <Row className="mg-t-50">
                <Col sm={12} className="col-12">
                  <BasicTable
                    isPopup={fullscreen}
                    isGameTable={false}
                    height={100}
                    classname="pinned-table"
                    loading={isLoading || isRefetching}
                    data={modifiedData ? modifiedData : []}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            closeModal("showGameModal");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GamePopup;
