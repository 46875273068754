import httpClient from "./httpClient";

export const getFiles = async () => {
    const { data } = await httpClient.get("/files");
    if (data) {
        return data.data;
    }
}

export const downloadFile = async (id) => {
    const { data } = await httpClient.getFetch(`/files/${id}`);
    if (data) {
        return data.data;
    }
};
