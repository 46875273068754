import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSpin } from "../../services";
import { GameStateCollapse } from "./blocks/gameStateCollapse";
import { convertToCurrency } from "../../utilities";
import { useCurrency } from "../../contexts";

const SpinDetail = () => {
  const { id } = useParams();
  const { currency } = useCurrency();

  const { data, isLoading } = useQuery(
    ["spin-detail", { id }, { currency }],
    getSpin,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const renderData = (displayData, convert = false) => {
    if (isLoading) {
      return <Spinner size="sm" />;
    }
    if (data) {
      return convert
        ? convertToCurrency(displayData, data?.currency + " ")
        : displayData;
    }
    return " - ";
  };

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Game Round Detail
          </span>
        </div>
      </div>
      <Card>
        <Card.Body className="p-3">
          <Row className="m-2 py-3">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Game Name</div>
              <div className="tx-18 text-info">{renderData(data?.game)}</div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">User ID</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.external_user_id)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Session ID</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.session_token)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Integrator</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.integrator)}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Currency</div>
              <div className="tx-18 text-info">
                {renderData(data?.currency)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Start Balance</div>
              <div className="tx-18 text-info">
                {renderData(data?.start_balance, true)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">End Balance</div>
              <div className="tx-18 text-info">
                {renderData(data?.end_balance, true)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Wager</div>
              <div className="tx-18 text-info">
                {renderData(data?.wager, true)}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Base Award</div>
              <div className="tx-18 text-info">
                {renderData(data?.base_award, true)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Bonus Award</div>
              <div className="tx-18 text-info">
                {renderData(data?.bonus_award, true)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Created At</div>
              <div className="tx-18 text-info">
                {renderData(
                  moment(data?.created_at).format("DD-MM-YYYY HH:mm UTC")
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Updated At</div>
              <div className="tx-18 text-info">
                {renderData(
                  moment(data?.updated_at).format("DD-MM-YYYY HH:mm UTC")
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {data ? <GameStateCollapse data={data} /> : ""}
    </React.Fragment>
  );
};

export default SpinDetail;
