import React, { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import "./_tables.scss";

export const Table = ({
  data,
  columns,
  loading,
  paginate = null,
  total,
  height = null,
  sizes = [10, 25, 50],
  onSelect = null,
  setOrderParams = null,
}) => {
  const pages = Math.ceil(total / paginate?.limit);

  const handleSort = (columnName, sortKey) => {
    const col = columns.find((column) => column.key === columnName);
    if (data && data.length > 0 && col.key === "rtp") {
      setOrderParams(`rtp ${sortKey}`);
    }

    if (data && data.length > 0 && (col.key === "award" || col.key === "all")) {
      setOrderParams(`final_award ${sortKey}`);
    }

    if (data && data.length > 0 && col.key === "wagerGameRounds") {
      setOrderParams(`wager ${sortKey}`);
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-bordered text-nowrap mb-0">
        <thead>
          <tr>
            {onSelect && (
              <th style={{ width: 30 }}>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    style={{
                      width: 14,
                      height: 14,
                      transform: "translateY(-1px)",
                    }}
                    type="checkbox"
                    checked={
                      JSON.stringify(onSelect.selectedIDs) ===
                      JSON.stringify(data?.map((item) => item?.id))
                    }
                    onChange={onSelect.onSelectAll}
                  />
                </div>
              </th>
            )}
            {columns.map((item, key) => {
              return (
                <th
                  {...(item?.width ? { style: { width: item?.width } } : "")}
                  className={item.className ? item.className : ""}
                  key={key}
                >
                  {item.name}
                  {item.key === "rtp" && setOrderParams && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="fa fa-sort-amount-down"
                        onClick={() => handleSort("rtp", "desc")}
                      ></div>
                      <div
                        className="fa fa-sort-amount-up"
                        onClick={() => handleSort("rtp", "asc")}
                      ></div>
                    </div>
                  )}
                  {(item.key === "award" || item.key === "all") &&
                    setOrderParams && (
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          className="fa fa-sort-amount-down"
                          onClick={() => handleSort(item.key, "desc")}
                        ></div>
                        <div
                          className="fa fa-sort-amount-up"
                          onClick={() => handleSort(item.key, "asc")}
                        ></div>
                      </div>
                    )}
                  {item.key === "wagerGameRounds" && setOrderParams && (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="fa fa-sort-amount-down"
                        onClick={() => handleSort("wagerGameRounds", "desc")}
                      ></div>
                      <div
                        className="fa fa-sort-amount-up"
                        onClick={() => handleSort("wagerGameRounds", "asc")}
                      ></div>
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        {data?.length > 0 && data !== null && !loading && (
          <tbody>
            {data.map((row, key) => {
              return (
                <tr key={key}>
                  {onSelect && (
                    <th style={{ width: 30 }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <input
                          style={{
                            width: 14,
                            height: 14,
                            transform: "translateY(-1px)",
                          }}
                          type="checkbox"
                          checked={onSelect.selectedIDs?.includes(row?.id)}
                          onChange={(e) => onSelect.onSelect(row)}
                        />
                      </div>
                    </th>
                  )}
                  {columns.map((column, key) => {
                    return (
                      <td
                        {...(column?.width
                          ? { style: { width: column?.width } }
                          : "")}
                        className={column.className ? column.className : ""}
                        key={key}
                      >
                        {column?.render
                          ? column.render(
                              column.key === "all" ||
                                column.key === "wagerGameRounds"
                                ? row
                                : row[column.key]
                            )
                          : row[column.key] || "-"}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {(data === null || data.length === 0 || loading) && (
        <div
          className="d-flex align-items-center justify-content-center text-primary mt-4"
          style={{ minHeight: height ? height : 500 }}
        >
          {(data === null || data.length === 0) && !loading ? (
            "No data to display"
          ) : (
            <Spinner variant="primary" />
          )}
        </div>
      )}
      {paginate && (
        <div className="d-flex align-items-center d-sm-flex mt-4 ">
          <span className="">
            Page{" "}
            <strong>
              {paginate.page} of {pages}
            </strong>{" "}
          </span>
          <select
            className="selectpage ms-2 border me-1"
            value={paginate.limit}
            onChange={(e) => {
              paginate.setLimit(Number(e.target.value));
              paginate.setPage(1);
            }}
          >
            {sizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <span className="ms-sm-auto ">
            <Button
              variant=""
              className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
              onClick={() => paginate.setPage(1)}
              disabled={pages === 0 || paginate.page === 1}
            >
              {" Previous "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => paginate.setPage(paginate.page - 2)}
              disabled={pages === 0 || paginate.page <= 2}
            >
              {" << "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => paginate.setPage(paginate.page - 1)}
              disabled={pages === 0 || paginate.page === 1}
            >
              {" < "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => paginate.setPage(paginate.page + 1)}
              disabled={pages === 0 || paginate.page === pages}
            >
              {" > "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 my-1"
              onClick={() => paginate.setPage(pages)}
              disabled={pages === 0 || paginate.page === pages}
            >
              {" >> "}
            </Button>
            <Button
              variant=""
              className="btn-default tablebutton me-2 d-sm-inline d-block my-1"
              onClick={() => paginate.setPage(paginate.page + 1)}
              disabled={pages === 0 || paginate.page === pages}
            >
              {" Next "}
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};
