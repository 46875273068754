export const shouldShowRetriggerFreeSpin = gameName =>
    gameName !== "plinko" &&
    gameName !== "aviator" &&
    !gameName?.includes("smashing-hot") &&
    !gameName?.includes("blazing-clovers") &&
    !gameName?.includes("book-of-dynasty") &&
    !gameName?.includes("book-of-sacred") &&
    !gameName?.includes("majestic-crown") &&
    !gameName?.includes("crystal-ways") &&
    !gameName?.includes("wild-west-john");
