import React, { createContext, useContext, useState } from 'react';

const DownloadContext = createContext();

export const DownloadProvider = ({ children }) => {
    const baseURL = process.env.REACT_APP_API_ADDRESS ? `${process.env.REACT_APP_API_ADDRESS}/files/` : '/api/files/';
    const [downloadUrl, setDownloadUrl] = useState(baseURL);

    return (
        <DownloadContext.Provider value={{ downloadUrl, setDownloadUrl }}>
            {children}
        </DownloadContext.Provider>
    );
};

export const useDownloadContext = () => {
    return useContext(DownloadContext);
};
