import React, {useEffect, useState} from "react";
import {Breadcrumb, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { useProfile } from "../../contexts";
import {getRoles, deleteRole, getPermissons} from "../../services";
import { RolesModal } from "./components";
import { toast, Slide } from "react-toastify";
import { DeleteModal } from "./components/deleteModal";
import { Table } from "../../components/Tables";
import { EditRoleModal } from "./components/editRoleModal";
import { EditRolePermissionsModal } from "./components/editRolePermissionsModal";

const Roles = () => {
  const [data, setData] = useState();
  const [limit] = useState(100);
  const [page] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [showEditPermissionsModal, setShowPermissionsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [rolePermissions, setRolePermissions] = useState([]);
  const [permissionsList, setPermissionsList] = useState(null);
  const [addPermissions, setAddPermissions] = useState([])

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const { organizationId } = useProfile();
  const { isLoading, isRefetching, refetch } = useQuery(
    [
      "roles-request",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getRoles,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: true,
      onSuccess: data => {
        setData(data);
      },
    }
  );

  const { refetch: refetchPermissions } = useQuery(
      [
        "permissions",
        {
          limit: 0,
          offset: 0,
        },
      ],
      getPermissons,
      {
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
          setPermissionsList(data);
        },
      }
  );

  const { mutate: deleteRoleReq } = useMutation(deleteRole, {
    onSuccess: () => {
      setShowDeleteModal(false);
      toastAlert("success");
      refetch();
      selectedData && setSelectedData(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setShowDeleteModal(false);
      selectedData && setSelectedData(null);
    },
  });

  const toastAlert = type =>
    toast.success(
        <p className="text-white tx-16 mb-0 ">
          {type === "success"
              ? `Role was modified successfully`
              : `Error: ${error}`}
        </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-info" : "bg-danger",
        type,
      }
    );

  const handleItemClick = (propertyValue) => {
    const selectedItem = data?.find(item => item.id === propertyValue);
    setSelectedData(selectedItem);
    setShowEditRoleModal(true);
  };

  const handleEditButtonClick = (propertyValue) => {
    const selectedItem = data?.find(item => item.id === propertyValue);
    setSelectedData(selectedItem);

    const selectedRolePermission = selectedItem?.permissions?.map(permission => ({
      label: permission.name,
      value: permission.id
    }));

    const modifiedList = permissionsList
        .filter(permission => !selectedRolePermission.some(rolePermission => rolePermission.value === permission.id))
        .map(permission => ({ label: permission.name, value: permission.id }));

    setAddPermissions(modifiedList);
    setRolePermissions(selectedRolePermission);
    setShowPermissionsModal(true);
  };

  const columns = [
    {
      className: "text-center",
      key: "id",
      name: "ID",
      render: data =>
          <div>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="tooltip tooltip-primary1">Edit role data</Tooltip>}
            >
              <span
                  onClick={() => {handleItemClick(data)}}
                  className="text-primary text-underline cursor-pointer d-block text-truncate mx-auto"
                  style={{
                    textDecoration: "underline",
                    maxWidth: 120,
                  }}
              >
                {data}
              </span>
            </OverlayTrigger>
          </div>
    },
    {
      className: "text-center",
      key: "name",
      name: "Name"
    },
    {
      className: "text-center",
      key: "type",
      name: "Type",
    },
    {
      className: "text-center",
      key: "description",
      name: "Description",
    },
    {
      className: "text-center",
      key: "permissions",
      name: "Permissions",
      render: data => {
        return (
          <div className="d-flex align-items-center flex-wrap">
            {data
              ? data?.map((role, key) => (
                  <span key={key} className="badge bg-primary me-1 my-2 tx-12">
                    {role?.name}
                  </span>
                ))
              : "-"}
          </div>
        );
      },
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: data =>
        data?.type === "admin" ? (
          ""
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <button
                className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 mg-r-10"
                onClick={() => handleEditButtonClick(data?.id)}
            >
              <i className="fe fe-edit-3" />
            </button>
            <button
              className="btn btn-outline-danger d-flex align-items-center justify-content-center p-1"
              onClick={() => {
                console.log(data)
                setSelectedData(data);
                setShowDeleteModal(true);
              }}
            >
              <i className="fe fe-trash" />
            </button>
          </div>
        ),
    },
  ];

  useEffect(() => {
    refetch();
    refetchPermissions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [organizationId]);

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  return (
    <>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Roles</span>
        </div>
        <div className="right-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => setShowModal(true)}>
              <span>Create</span>
              <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {showModal && (
        <RolesModal setShowModal={setShowModal} onSettled={refetch} />
      )}
      {showEditRoleModal && (
          <EditRoleModal
              setShowModal={setShowEditRoleModal}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              refetch={refetch}
          />
      )}
      {showEditPermissionsModal && (
          <EditRolePermissionsModal
              rolePermissions={rolePermissions}
              permissions={addPermissions}
              setShowModal={setShowPermissionsModal}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              refetch={refetch}
          />
      )}
      {showDeleteModal && (
        <DeleteModal
          title="Role"
          onAccept={() =>
              deleteRoleReq(selectedData?.id)}
          setShowModal={() => {
            setShowDeleteModal();
            setSelectedData(null);
          }}
          additionalInfo={`Role: ${selectedData?.name}`}
        />
      )}
      <Card>
        <Card.Body className="pt-4 example1-table">
          <Table
            loading={isLoading || isRefetching}
            data={data ? data : []}
            columns={columns}
            total={data ? data?.length : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Roles;
