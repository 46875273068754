import React from "react";
import { symbolImageLink } from "../symbols/symbolImageLink";
import { getRowsForGame } from "../rows/rows";

function AsgardPartyRenderer(stage) {
  const { payouts } = stage.stage;
  const winningFigureIds = [];
  if (payouts && payouts.values) {
    payouts.values.forEach((payout) => {
      if (payout.figures) {
        winningFigureIds.push(...payout.figures);
      }
    });
  }

  const topRowFigures = Array.from({ length: 7 }, (_, figureIndex) => {
    if (figureIndex >= 2 && figureIndex <= 4) {
      const offset = figureIndex - 2;
      const figure = stage.currentTopFigures[offset];
      const symbol = figure ? figure.symbol : "";

      const isSymbolInWinLine = winningFigureIds.includes(figure?.id);
      const isSpecialFigure = figure?.is_special && figure?.name.includes("m");

      const brightness = isSymbolInWinLine || isSpecialFigure ? 1 : 0.4;

      return (
        <td
          key={`${figure.name}-${figureIndex}-${symbol}`}
          style={{
            filter: `brightness(${brightness})`,
            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
              stage.gameName
            )}/${figure?.name}.png)`,
            backgroundSize: "cover",
          }}
        >
          {!symbolImageLink(stage.gameName) && symbol?.toUpperCase()}
        </td>
      );
    } else {
      return <td key={figureIndex}></td>;
    }
  });

  let tableRows = getRowsForGame(stage.gameName)
    .slice(1)
    .map((rowIndex) => {
      const rowCells = Array.from({ length: 7 }, (_, figureIndex) => {
        const figureSet = stage.currentFigures.find(
          (figSet) => figSet[0]?.y === rowIndex && figSet[0]?.x === figureIndex
        );

        const symbol = figureSet ? figureSet[0].symbol : "";

        let isSymbolInWinLine = figureSet?.find((fig) =>
          winningFigureIds.includes(fig.id)
        );
        let isSpecialFigure = false;
        let highlightedFigureIndex = -1;

        if (figureSet) {
          for (let i = 0; i < figureSet.length; i++) {
            const figure = figureSet[i];
            if (figure.is_special && figure.name.includes("m")) {
              isSpecialFigure = true;
              highlightedFigureIndex = i;

              break;
            } else if (winningFigureIds.includes(figure.id)) {
              isSymbolInWinLine = true;
              highlightedFigureIndex = i;
              break;
            }
          }
        }

        if (figureSet) {
          const figureComponents = figureSet.map((figure, index) => {
            const isHighlighted =
              (isSpecialFigure && index === highlightedFigureIndex) ||
              (isSymbolInWinLine && index === highlightedFigureIndex);

            const brightness = isHighlighted ? 1 : 0.4;
            if (figure.name === "c") {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "150px",
                    height: "150px",
                    position: "absolute",
                    marginTop: "-24px",
                    marginLeft: "2.5%",
                  }}
                ></div>
              );
            } else if (figure.name === "r1") {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "50px",
                    height: "100px",
                    position: "absolute",
                    marginTop: "-24px",
                  }}
                ></div>
              );
            } else if (figure.name === "r2") {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100px",
                    height: "200px",
                    position: "absolute",
                    marginTop: "-24px",
                    marginLeft: "1%",
                  }}
                ></div>
              );
            } else if (figure.name === "h") {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "145px",
                    height: "200px",
                    position: "absolute",
                    marginTop: "-25px",
                    marginLeft: "2.8%",
                  }}
                ></div>
              );
            } else if (
              figure.name === "m2" ||
              figure.name === "m12" ||
              figure.name === "m22"
            ) {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100px",
                    height: "100px",
                    position: "absolute",
                    marginTop: "-25px",
                    marginLeft: "1.2%",
                  }}
                ></div>
              );
            } else if (
              figure.name === "m3" ||
              figure.name === "m13" ||
              figure.name === "m23"
            ) {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "150px",
                    height: "150px",
                    position: "absolute",
                    marginTop: "-25px",
                    marginLeft: "2%",
                  }}
                ></div>
              );
            } else if (figure.name === "m4") {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "205px",
                    height: "205px",
                    position: "absolute",
                    marginTop: "-34px",
                    marginLeft: "3.5%",
                  }}
                ></div>
              );
            } else {
              return (
                <div
                  key={figure.id + index}
                  style={{
                    filter: `brightness(${brightness})`,
                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                      stage.gameName
                    )}/${figure.name}.png)`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "50px",
                    height: "50px",
                    position: "absolute",
                    marginTop: "-25px",
                    marginLeft: "0.2%",
                  }}
                >
                  {!symbolImageLink(stage.gameName) && symbol?.toUpperCase()}
                </div>
              );
            }
          });
          return <td>{figureComponents}</td>;
        }

        return (
          <td
            key={figureIndex}
            style={{
              backgroundImage: figureSet
                ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                    stage.gameName
                  )}/${figureSet[0]?.name}.png)`
                : "none",
              backgroundSize: "cover",
            }}
          >
            {!symbolImageLink(stage.gameName) && symbol?.toUpperCase()}
          </td>
        );
      });

      let rowClass;

      if (rowIndex === 4) {
        rowClass = "fifth-row";
      } else if (rowIndex >= 4) {
        rowClass = "bordered-row";
      } else {
        rowClass = "";
      }

      return (
        <tr key={rowIndex} className={rowClass}>
          {rowCells.map((td, index) => (
            <React.Fragment key={index}>{td}</React.Fragment>
          ))}
        </tr>
      );
    });

  tableRows.unshift(<tr key="topRow">{topRowFigures}</tr>);

  const lastRowIndex = tableRows.length - 1;
  if (lastRowIndex >= 0) {
    tableRows[lastRowIndex] = React.cloneElement(tableRows[lastRowIndex], {
      className: "last-row",
    });
  }
  return tableRows;
}

export default AsgardPartyRenderer;
