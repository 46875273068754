import React, { useEffect, useState } from "react";
import { IGame } from "./Lobby";
import URLControl from "./URLControl";
import MockQueryBuilder from "./MockQueryBuilder";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useProfile } from "../../../contexts";
import { getIntegrators } from "../api/init";
import "../styles/editor.scss";

interface Props {
  key: number;
  game: IGame;
  env: "dev" | "stage" | "prod" | "";
}

interface Integrators {
  api_key: string;
  id: string;
  name: string;
  status: number;
  type: string;
}

const Editor = (props: Props) => {
  // @ts-ignore
  const { organizationId } = useProfile();
  const { game, env } = props;
  const [url, setURL] = useState("");
  const [session, setSession] = useState("");
  const [integrator, setIntegrator] = useState("mock");
  const [baseUrl, setBaseUrl] = useState(
    `https://dev-games.heronbyte.com/${game.name}/`
  );
  const [queryBuilders, setQueryBuilders] = useState<Integrators[]>([]);
  const [environment, setEnvironment] = useState(env);

  useEffect(() => {
    const isDevEnvironment = window.location.host.includes("dev");
    const isStageEnvironment = window.location.host.includes("stage");
    const isProdEnvironment = window.location.host.includes("prod");

    if (isDevEnvironment) {
      setEnvironment("dev");
    } else if (isStageEnvironment) {
      setEnvironment("stage");
    } else if (isProdEnvironment) {
      setEnvironment("prod");
    } else {
      setEnvironment("");
    }
  }, []);

  useEffect(() => {
    getIntegrators(
      organizationId,
      (integratorsData) => {
        setQueryBuilders(integratorsData);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [organizationId]);

  useEffect(() => {
    if (window.location.host.includes("localhost")) {
      setBaseUrl(`https://dev-games.heronbyte.com/${game.name}/`);
    } else {
      setBaseUrl(
        "https://" +
          window.location.host.replace(
            window.location.host.split(".")[0],
            window.location.host.split(".")[0].replace("backoffice", "games")
          ) +
          `/${game.name}/`
      );
    }
  }, [baseUrl, url, game]);

  return (
    <div>
      {/* <p className="form-label">Integrator</p>
            <ToggleButtonGroup
                value={integrator}
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
                    setIntegrator(value);
                }}
                aria-label="text alignment"
            >
                {queryBuilders.map(intg => {
                    return (
                        <ToggleButton
                            className='editor-button'
                            key={intg.api_key}
                            value={intg.name}
                            disabled={integrator === intg.name}
                            aria-label="left aligned">
                            {intg.name}
                        </ToggleButton>
                    );
                })}

            </ToggleButtonGroup> */}

      <MockQueryBuilder
        game={game}
        integrator={integrator}
        env={environment}
        setSearchParams={(sp) => setURL(baseUrl + "?" + sp)}
        setSession={setSession}
      />
      <hr></hr>

      <URLControl url={url} session={session} />
    </div>
  );
};

export { Editor };
