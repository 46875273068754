import React from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { Table } from "../../../components/Tables";
import { getSessionsList, getRevenueReports } from "../../../services";
import { Select } from "antd";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useCurrency } from "../../../contexts/currencyContext";
import { convertToCurrency } from "../../../utilities";
import getSymbolFromCurrency from "currency-symbol-map";
import { useProfile } from "../../../contexts";
import { useDemo } from "../../../contexts/demoContext";

export const GamePerformanceBlock = ({ games, hosts, type = null }) => {
  const params = {
    starting_from: "",
    ending_at: "",
  };
  const [game, setGame] = React.useState("");
  const [data, setData] = React.useState();
  const [host, setHost] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const { currency } = useCurrency();
  const { organizationId } = useProfile();
  const { is_demo } = useDemo();

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );

  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const {
    data: revenueData,
    refetch: refetchRevenueData,
    isLoading: revenueDataLoading,
  } = useQuery(
    [
      "revenue-reports",
      {
        is_demo,
        currency,
        game,
        host,
        ...params,
      },
    ],
    getRevenueReports,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
      enabled: false,
    }
  );

  const { refetch, isLoading, isRefetching } = useQuery(
    [
      `session-spins${type ? "-game" : ""}`,
      {
        currency,
        limit,
        page,
        host: host || "",
        game: game || "",
        ...params,
        order: "created_at desc",
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getSessionsList,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Game",
      render: (data) => data?.game,
    },
    {
      className: "text-center",
      key: "wager",
      name: "Wager",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "award",
      name: "Awards",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "revenue",
      name: "Revenue",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "margin",
      name: "Margin",
      render: (data) => data || " - ",
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [
    game,
    page,
    limit,
    organizationId,
    host,
    is_demo,
    selectedOperator,
    selectedIntegrator,
  ]);

  return (
    <Card>
      <Card.Body className="px-3 py-3 example1-table">
        <h5 className="tx-18">Game Performance</h5>
        <form
          className="w-100"
          id="filters"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="row mb-3">
            <div className="form-group mb-0 col-2">
              <label className="form-label">GAME</label>
              <Select
                className="form-control"
                showSearch
                placeholder="Select Game..."
                allowClear
                optionFilterProp="children"
                value={game || null}
                onChange={(game) => setGame(game)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  games?.map((item) => ({
                    value: item?.name,
                    label: item?.name?.toUpperCase(),
                  })) || []
                }
              />
            </div>
            <div className="form-group mb-0 col-2">
              <label className="form-label">Host</label>
              <Select
                className="form-control"
                showSearch
                placeholder="Select Host..."
                allowClear
                optionFilterProp="children"
                value={host || null}
                onChange={(host) => setHost(host)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  hosts?.map((item) => ({
                    value: item,
                    label: item,
                  })) || []
                }
              />
            </div>
          </div>
        </form>
        <Row>
          <Col xl={12} lg={12} md={12} xs={12}>
            <Table
              loading={isLoading || isRefetching}
              data={data ? data.items : []}
              columns={columns}
              sizes={[10, 25, 50]}
              paginate={paginate}
              total={data ? data?.total : 0}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
