import httpClient from "./httpClient";

export const createGame = async (formData) => {
  const { data } = await httpClient.post("/game", { ...formData });
  if (data) {
    return data.data;
  }
};

export const updateGame = async (formData) => {
  const { data } = await httpClient.put(`/game/${formData?.id}`, {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const getGamesInfo = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/game?${params.toString()}`);
  if (data) {
    return data;
  }
};

export const getGameInfo = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const { data } = await httpClient.get(`/game/${id}`);
  if (data) {
    return data.data;
  }
};

export const deleteGame = async (id) => {
  const { data } = await httpClient.delete(`/game/${id}`);
  if (data) {
    return data.data;
  }
};
