import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {addOperatorPair, addOrganizationPair} from "../../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

export const AddPairModal = ({ setShowModal, onSettled, integrators, providers, operators, type, error }) => {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedOperator, setSelectedOperator] = useState(null);

    const {
        handleSubmit,
    } = useForm({
        defaultValues: {
            integrator_id: "",
            provider_id: "",
            operator_id: "",
        },
    });

    const { mutate } = useMutation(addOrganizationPair, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setShowModal(false);
        },
        onError: () => {
            toastAlert("error");
            setShowModal(false);
        },
    });

    const { mutate : mutateOperator } = useMutation(addOperatorPair, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setShowModal(false);
        },
        onError: () => {
            toastAlert("error");
            setShowModal(false);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Integration was modified successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        formData.integrator_id = selectedOrganization;
        formData.provider_id = selectedProvider;
        formData.operator_id = selectedOperator;
        setLoading(true);
        if (type === "operator") {
            mutateOperator(formData);
        } else {
            mutate(formData);
        }
        // mutate(formData);
    });

    return (
        <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
            <Modal.Header>
                <h6 className="modal-title">{type === "operator" ? "Add Integrator/Operator Pair" : "Add Integrator/Provider Pair"}</h6>
                <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form
                    className={`needs-validation${isSubmitted ? "" : ""}`}
                    onSubmit={e => {
                        setIsSubmitted(true);
                        onSubmit(e);
                    }}
                >
                    <Form.Group className="form-group">
                        <Form.Label className="">Integrator</Form.Label>
                        <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select integrator..."}
                            value={selectedOrganization}
                            onChange={(org) => setSelectedOrganization(org)}
                            dropdownStyle={{
                                zIndex: '9999999999'
                            }}
                            options={
                                integrators?.map(item => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || []
                            }
                        />

                        {type === "operator" ? (
                            <>
                                <Form.Label>Operator</Form.Label>
                                <Select
                                    className="form-control"
                                    optionFilterProp="children"
                                    placeholder={"Select operator..."}
                                    value={selectedOperator}
                                    onChange={(game) => setSelectedOperator(game)}
                                    dropdownStyle={{
                                        zIndex: '9999999999'
                                    }}
                                    options={
                                        operators?.map(item => ({
                                            value: item?.id,
                                            label: item?.name,
                                        })) || []
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Form.Label>Provider</Form.Label>
                                <Select
                                    className="form-control"
                                    optionFilterProp="children"
                                    placeholder={"Select provider..."}
                                    value={selectedProvider}
                                    onChange={(game) => setSelectedProvider(game)}
                                    dropdownStyle={{
                                        zIndex: '9999999999'
                                    }}
                                    options={
                                        providers?.map(item => ({
                                            value: item?.id,
                                            label: item?.name,
                                        })) || []
                                    }
                                />
                            </>
                        ) }    

                        {/* <Form.Label>Provider</Form.Label>
                        <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select provider..."}
                            value={selectedProvider}
                            onChange={(game) => setSelectedProvider(game)}
                            dropdownStyle={{
                                zIndex: '9999999999'
                            }}
                            options={
                                providers?.map(item => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || []
                            }
                        /> */}
                        {/* // <Form.Label>Operator</Form.Label>
                        // <Select
                        //     className="form-control"
                        //     optionFilterProp="children"
                        //     placeholder={"Select operator..."}
                        //     value={selectedOperator}
                        //     onChange={(game) => setSelectedOperator(game)}
                        //     dropdownStyle={{
                        //         zIndex: '9999999999'
                        //     }}
                        //     options={
                        //         operators?.map(item => ({
                        //             value: item?.id,
                        //             label: item?.name,
                        //         })) || []
                        //     }
                        // /> */}
                    </Form.Group>
                    <Button
                        disabled={loading}
                        variant=""
                        type="submit"
                        style={{ minHeight: 51 }}
                        className="btn btn-primary btn-block tx-16"
                    >
                        {loading ? (
                            <span className="text-white">
                  Loading...
                </span>
                        ) : (
                            "Create"
                        )}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
