import { gambles } from "../helpers/gambles/gambles";
import React from "react";
import { convertToCurrency } from "../../../utilities";

export const GamblesTable = ({ data }) => {
  return (
    gambles(data)?.length > 0 && (
      <>
        <p className="tx-16 tx-bold mt-4 mb-2">Gambles</p>
        <table
          className="game-stats-info__stats-class mt-0 mb-4"
          id="win_lines_table"
        >
          {gambles(data)?.map((gamble, key) => {
            return (
              <React.Fragment key={key}>
                <thead>
                  <tr>
                    <td colSpan="4">Gamble - {key + 1}</td>
                  </tr>
                  <tr>
                    <td>Expected color</td>
                    <td>User pick color</td>
                    <td>Wager</td>
                    <td>Award</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{gamble?.expected_pick === 0 ? "Black" : "Red"}</td>
                    <td>{gamble?.user_pick === 0 ? "Black" : "Red"}</td>
                    <td>
                      {convertToCurrency(
                        Number(gamble?.["wager"]) / 1000,
                        data?.currency
                      )}
                    </td>
                    <td>
                      {convertToCurrency(
                        Number(gamble?.["award"]) / 1000,
                        data?.currency
                      )}
                    </td>
                  </tr>
                </tbody>
              </React.Fragment>
            );
          })}
        </table>
      </>
    )
  );
};
