import React, {useState} from 'react';
import {Button, Modal, Row} from "react-bootstrap";
import {DatePicker} from "antd";
import moment from "moment/moment";

const CustomFilterModal = ({ openCustomFilter, closeModal, params, setParams, setRange, setActiveRange }) => {

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const onSubmit = () => {
        setParams({...params,
            starting_from: startDate
                ? moment(startDate?.$d).format("YYYY-MM-DD HH:mm:00Z")
                : "",
            ending_at: endDate
                ? moment(endDate?.$d).format("YYYY-MM-DD HH:mm:59Z")
                : "",
        })
    }

    return (
        <Modal
            size="md"
            show={openCustomFilter}
            aria-labelledby="example-modal-sizes-title-sm"
            style={{
                zIndex: '1100',
            }}
            centered>
            <Modal.Header>
                <Modal.Title>Custom date</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        setParams( {...params,
                            starting_from: moment().format("YYYY-MM-DD 00:00:01Z"),
                            ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ") })
                        setRange("today");
                        setActiveRange("today");
                        closeModal()
                    }}>
                    x
                </Button>
            </Modal.Header>
            <Modal.Body
                style={{
                    zIndex: '1100',
                    height: '310px',
                }}>
                <div className='wager-sets-modal'>
                    <Row className="row-sm">
                        <div className="d-flex align-items-center">
                            <DatePicker
                                placeholder="Select Start Date..."
                                className="form-control me-4"
                                clearIcon={null}
                                suffixIcon={null}
                                popupClassName={'timepicker'}
                                minuteStep={5}
                                showTime={{
                                    format: "HH:mm"
                                }}
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                                disabledDate={current => {
                                    if (endDate) {
                                        let customDate = moment(endDate.$d).format(
                                            "YYYY-MM-DD HH:mm"
                                        );
                                        return (
                                            current && current > moment(customDate, "YYYY-MM-DD HH:mm")
                                        );
                                    }
                                    return false;
                                }}
                            />
                            <DatePicker
                                placeholder="Select End Date..."
                                className="form-control mr-0"
                                clearIcon={null}
                                suffixIcon={null}
                                minuteStep={5}
                                showTime={{
                                    format: "HH:mm"
                                }}
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                                disabledDate={current => {
                                    if (startDate) {
                                        let customDate = moment(startDate.$d).format(
                                            "YYYY-MM-DD HH:mm"
                                        );
                                        return (
                                            current && current < moment(customDate, "YYYY-MM-DD HH:mm")
                                        );
                                    }
                                    return false;
                                }}
                            />
                        </div>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button className='wd-45p'
                        variant="primary"
                        disabled={!startDate || !endDate}
                        onClick={() => {
                    onSubmit();
                    closeModal()
                }}>
                    Submit
                </Button>
                <Button className='wd-45p' variant="secondary"
                        onClick={() => {
                    setParams( {...params,
                        starting_from: moment().format("YYYY-MM-DD 00:00:01Z"),
                        ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ") })
                    setRange("today");
                    setActiveRange("today");
                    closeModal()
                }}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomFilterModal;