import httpClient from "./httpClient";

export const getOrganizationOperators = async ({ queryKey }) => {
  const { organizationId } = queryKey[1];
  const { data } = await httpClient.get(
    `/organizations/${organizationId}/operators`
  );
  if (data) {
    return data.data;
  }
};

export const getOrganizationGames = async ({ queryKey }) => {
  const { selectedOrganization } = queryKey[1];
  const { data } = await httpClient.get(
    `/organizations/${selectedOrganization}/game`
  );
  if (data) {
    return data.data;
  }
};

export const integrateGame = async (formData) => {
  const { data } = await httpClient.post(
    `/organizations/${formData.organizationId}/game`,
    { ...formData }
  );
  if (data) {
    return data.data;
  }
};

export const getAllGames = async () => {
  const { data } = await httpClient.post("/game/search", {
    data: {},
  });
  if (data) {
    return data.data;
  }
};

export const removeIntegratedGame = async (formData) => {
  const { organizationId, game_id } = formData;
  const { data } = await httpClient.delete(
    `/organizations/${organizationId}/game`,
    {
      data: {
        game_id,
      },
    }
  );
  if (data) {
    return data.data;
  }
};

export const editIntegratedGame = async (formData) => {
  const {
    organization_id,
    game_id,
    rtp,
    volatility,
    wager_set_id,
    short_link,
  } = formData;
  const { data } = await httpClient.put(
    `/organizations/${organization_id}/game`,
    {
      game_id,
      rtp,
      volatility,
      wager_set_id,
      short_link,
    }
  );
  if (data) {
    return data.data;
  }
};

export const getOperatorPair = async ({ queryKey }) => {
  const { integrator_id, operator_id } = queryKey[1];
  const { data } = await httpClient.post(`/organizations/get_operator_pair`, {
    operator_id,
    integrator_id,
  });
  if (data) {
    return data.data;
  }
};

export const addOperatorPair = async (formData) => {
  const { integrator_id, operator_id } = formData;
  const { data } = await httpClient.post(`/organizations/add_operator_pair`, {
    integrator_id,
    operator_id,
  });
  if (data) {
    return data.data;
  }
};

export const deleteOperatorPair = async (pair) => {
  const { integrator_id, operator_id } = pair;
  const { data } = await httpClient.delete(
    `/organizations/delete_operator_pair`,
    {
      data: {
        integrator_id,
        operator_id,
      },
    }
  );
  if (data) {
    return data.data;
  }
};

export const getOrganizationPair = async ({ queryKey }) => {
  const { integrator_id, provider_id } = queryKey[1];
  const { data } = await httpClient.post(
    `/organizations/get_organization_pair`,
    {
      integrator_id,
      provider_id,
    }
  );
  if (data) {
    return data.data;
  }
};

export const addOrganizationPair = async (formData) => {
  const { integrator_id, provider_id } = formData;
  const { data } = await httpClient.post(
    `/organizations/add_organization_pair`,
    {
      integrator_id,
      provider_id,
    }
  );
  if (data) {
    return data.data;
  }
};

export const removeOrganizationPair = async (pair) => {
  console.log(pair);
  const { integrator_id, provider_id } = pair;
  const { data } = await httpClient.delete(
    `/organizations/delete_organization_pair`,
    {
      data: {
        integrator_id,
        provider_id,
      },
    }
  );
  if (data) {
    return data.data;
  }
};

export const getOrganizationWagerSet = async ({ queryKey }) => {
  const { selectedOrganization } = queryKey[1];
  const { data } = await httpClient.get(
    `/organizations/${selectedOrganization}/wager_set`
  );

  if (data) {
    return data.data;
  }
};

export const addOrganizationWagerSet = async ({ queryKey }, formData) => {
  const { selectedOrganization } = queryKey[0];
  const { currency, game_id, wager_set_id } = formData;
  const { data } = await httpClient.post(
    `/organizations/${selectedOrganization}/wager_set`,
    {
      currency,
      game_id,
      wager_set_id,
    }
  );
  if (data) {
    return data.data;
  }
};

export const removeWagerSet = async ({ queryKey }, formData) => {
  const { selectedOrganization } = queryKey[0];
  const { currency, game_id, wager_set_id } = formData;
  console.log(game_id, "log");
  const { data } = await httpClient.delete(
    `/organizations/${selectedOrganization}/wager_set`,
    {
      data: {
        currency,
        game_id: [game_id],
        wager_set_id,
      },
    }
  );
  if (data) {
    return data.data;
  }
};

export const editWagerSetGame = async ({ queryKey }, formData) => {
  const { selectedOrganization } = queryKey[0];
  const {
    currency,
    game_id,
    new_currency,
    new_wager_set_id,
    wager_set_id,
    short_link,
  } = formData;
  const { data } = await httpClient.put(
    `/organizations/${selectedOrganization}/wager_set`,
    {
      currency,
      game_id,
      new_currency,
      new_wager_set_id,
      wager_set_id,
      short_link,
    }
  );
  if (data) {
    return data.data;
  }
};
