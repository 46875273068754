import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { deleteCurrency } from "../../../../services";
import { toast, Slide } from "react-toastify";

export const DeleteCurrency = ({
  setShowModal,
  selectedData,
  setSelectedData,
  data: { organization_pair_id, title, multiplier },
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState(false);

  const { handleSubmit } = useForm({
    defaultValues: {
      organization_pair_id,
      title,
    },
  });

  const { mutate } = useMutation(deleteCurrency, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-currencies");
      toastAlert("success");
      setShowModal(false);
    },
    onError: () => {
      toastAlert("error");
      setShowModal(false);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Deleted currency successfully`
          : `Couldn't delete currency`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    console.log(selectedData);
    organization_pair_id = selectedData?.organization_pair_id;
    title = selectedData?.title;
    console.log(formData);
    setLoading(true);
    mutate(formData);
  });

  React.useEffect(() => {
    return () => {
      setSelectedData(null);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="sm">
      <Modal.Header>
        <h6 className="modal-title">Delete Currency</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="wager-sets-modal">
          <Row>
            <div>
              <h3 className="font-weight-bold text-center mg-45">
                Are you sure?
              </h3>
            </div>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          className="wd-45p"
          variant="primary"
          onClick={() => {
            onSubmit();
          }}
        >
          Yes
        </Button>
        <Button
          className="wd-45p"
          variant="secondary"
          onClick={() => {
            setShowModal(false);
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
