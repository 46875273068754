import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { RequestBlock } from "./requestBlock";
import { ResultBlock } from "./resultBlock";
import { ResultTable } from "../tables/ResultTable";
import { GeneralInfoBlock } from "./generalInformationBlock";
import { GamblesTable } from "../tables/GamblesTable";
import "../styles/spin_detail.scss";

export const GameStateCollapse = ({ data, isPublic }) => {
  return (
    <Card className="my-2 radius-10 bd px-4">
      <Card.Body className="spin-time-line transparent py-3">
        <Row className="px-0">
          <Col
            className="d-flex align-items-center"
            xl={12}
            lg={12}
            md={12}
            xs={12}
          >
            <i className="fa fa-gamepad tx-20" />
            <div className="tx-17 text-muted ms-2 tx-bold flex-1 text-truncate">
              {"Game Round ID: " + data?.id}
            </div>
          </Col>
        </Row>
        <div>
          {!isPublic && <GeneralInfoBlock data={data} />}
          {!isPublic && <RequestBlock data={data} />}
          {!isPublic && <ResultBlock data={data} isPublick={isPublic} />}
          {isPublic && (
            <ResultTable
              gameName={data?.game}
              data={data}
              isPublic={isPublic}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
