import React from "react";
import { ChangePassword } from "./components/ChangePassword";

const Settings = () => {
  return (
    <>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Settings</span>
        </div>
      </div>
      <ChangePassword />
    </>
  );
};

export default Settings;
