import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

interface Props {
    children: JSX.Element;
}

interface State {
    hasError: boolean;
    errorMessage: string;
}

class ErrorBoundaries extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
            errorMessage: "",
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo);
        this.setState({
            hasError: true,
            errorMessage: error.message,
        });
    }

    render() {
        const { hasError, errorMessage } = this.state;

        if (hasError) {
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundaries };
