export const stops = (gameName, data) => {
    if (gameName === "stones-of-magic" || gameName === "secret-totems" || gameName === 'olympus-quest') {
        return data?.spin?.spins?.[0]?.reel_stops;
    }

    if (gameName === "cyber-town" || gameName === "precious-bugs" || gameName === "toreadors-fortune" || gameName?.includes("book-of-jones")) {
        return data?.spin?.reels?.stops;
    }

    return data?.spin?.stops || [];
};