import { v4 as uuidv4 } from "uuid";

const putFreeSpins = (freeSpinURL: string, gameID: string, playerID: string, currency: string, betValue: number) => {
    fetch(freeSpinURL, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify({
            player_id: playerID,
            bet_value: betValue,
            free_bets: 2,
            currency: currency.toLowerCase(),
            end_date: getEndDate(),
            freebet_id: uuidv4(),
            game_id: gameID,
            provider: "Heronbyte",
            operator_key: "mock",
            secret_token: "secret",
        }),
    });
};

const prepend_zero = (num: number) => {
    let str = num.toString();
    if (str.length === 1) {
        str = "0" + str;
    }

    return str;
};

const getEndDate = (): string => {
    const t = new Date();
    t.setDate(new Date().getDate() + 14);

    const date = `${t.getFullYear()}-${prepend_zero(t.getMonth() + 1)}-${prepend_zero(t.getDate())}`;
    const time = `${prepend_zero(t.getHours())}:${prepend_zero(t.getMinutes())}:${prepend_zero(t.getSeconds())}`;
    return `${date} ${time}+00:00`;
};

export { putFreeSpins };
