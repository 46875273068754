import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Select } from "antd";
import { toast, Slide } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createCurrency } from "../../../services";

const currenciesType = ["main", "common", "crypto", "fake", "other"];

export const CreateCurrencyModal = ({ setShowModal, currencies }) => {
  const queryClient = useQueryClient();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState(
    currenciesType[0]
  );
  const [selectedBaseCurrency, setSelectedBaseCurrency] = React.useState("");
  const [baseCurrencyError, setBaseCurrencyError] = React.useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      alias: "",
      type: "",
      additional_alias_rate: 1,
      base_currency: "",
    },
  });

  const { mutate } = useMutation(createCurrency, {
    onSuccess: () => {
      toastAlert("success");
      queryClient.invalidateQueries(["currencies"]);
      setShowModal(false);
    },
    onError: (error) => {
      toastAlert("error");
      setShowModal(false);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Created currency successfully`
          : `Couldn't create currency`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    if (formData.additional_alias_rate) {
      formData.additional_alias_rate = parseFloat(
        formData.additional_alias_rate
      );
    }

    setLoading(true);
    mutate({ ...formData });
  });

  useEffect(() => {
    setValue("type", selectedCurrency);
  }, [selectedCurrency]);

  useEffect(() => {
    if (errors?.base_currency) {
      setBaseCurrencyError(true);
    } else {
      setBaseCurrencyError(false);
    }
  }, [errors]);

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Create currency</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={(e) => {
            e.preventDefault();
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label>Title</Form.Label>
            <Form.Control
              placeholder="Enter currency title..."
              type="text"
              {...register("title", {
                required: true,
                validate: (value) => value.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.title}
              isValid={isSubmitted && !errors?.title}
            />
            {errors?.title && <div className="invalid-feedback">Required</div>}
            <Form.Label>Alias</Form.Label>
            <Form.Control
              placeholder="Enter currency alias..."
              type="text"
              {...register("alias", {
                required: false,
                // validate: value => /^[A-Za-z]+$/.test(value),
              })}
              isInvalid={isSubmitted && errors?.alias}
              isValid={isSubmitted && !errors?.alias}
            />
            {errors?.alias && <div className="invalid-feedback">Required</div>}
            <Form.Label>Type</Form.Label>
            <Select
              className="form-control"
              optionFilterProp="children"
              placeholder={"Select type..."}
              value={selectedCurrency}
              popupClassName={"custom-dropdown"}
              {...register("type", {
                required: false,
                // validate: value => value >= 0,
              })}
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              onChange={(currencies) => {
                setSelectedCurrency(currencies);
                setValue("type", currencies);
                if (currencies !== "fake") {
                  setSelectedBaseCurrency("");
                  setValue("base_currency", "", { shouldValidate: false });
                }
              }}
              options={
                currenciesType?.map((item) => ({
                  value: item,
                  label: item.charAt(0).toUpperCase() + item.slice(1),
                })) || []
              }
              isInvalid={isSubmitted && errors?.type}
              isValid={isSubmitted && !errors?.type}
            />
            {errors?.type && <div className="invalid-feedback">Required</div>}
            <Form.Label>Additional alias rate</Form.Label>
            <Form.Control
              placeholder="Enter additional alias rate"
              step="0.1"
              defaultValue={1}
              type="number"
              {...register("additional_alias_rate", {
                required: false,
                validate: (value) => value >= 1,
              })}
              isInvalid={isSubmitted && errors?.additional_alias_rate}
              isValid={isSubmitted && !errors?.additional_alias_rate}
            />
            {errors?.additional_alias_rate && (
              <div className="invalid-feedback">Value must be at least 1</div>
            )}

            {selectedCurrency === "fake" && (
              <>
                <Form.Label>Base currency</Form.Label>
                <Select
                  className="form-control"
                  optionFilterProp="children"
                  placeholder={"Select base currency..."}
                  value={selectedBaseCurrency}
                  popupClassName={"custom-dropdown"}
                  dropdownStyle={{
                    zIndex: "9999999999",
                  }}
                  {...register("base_currency", {
                    required: selectedCurrency === "fake",
                    validate: (value) => value && value.trim() !== "",
                  })}
                  options={
                    currencies
                      ?.filter((item) => item.type !== "fake")
                      .map((item) => ({
                        value: item.title,
                        label:
                          item.title.charAt(0).toUpperCase() +
                          item.title.slice(1),
                      })) || []
                  }
                  onChange={(currencies) => {
                    setSelectedBaseCurrency(currencies);
                    setValue("base_currency", currencies, {
                      shouldValidate: true,
                    });
                  }}
                />
                {errors?.base_currency && (
                  <div className="invalid-feedback-show">Required</div>
                )}
              </>
            )}
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
                className="spinner-border me-2 text-light"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Update"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
