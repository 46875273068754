import React from 'react';
import {Button, Modal, Row} from "react-bootstrap";

const ConfirmModal = ({ confirmModal, closeModal, onSubmit }) => {

    return (
        <Modal
            size="sm"
            show={confirmModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        closeModal("confirmModal")
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className='wager-sets-modal'>
                    <Row className="row-sm">
                        <div>
                            <h4 className='font-weight-bold text-center mg-45'>Do you really want to delete this game?</h4>
                        </div>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button className='wd-45p' variant="primary" onClick={() => {
                    onSubmit();
                    closeModal("confirmModal")
                }}>
                    Yes
                </Button>
                <Button className='wd-45p' variant="secondary" onClick={() => {
                    closeModal("confirmModal")
                }}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;