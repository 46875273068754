import React from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { getRevenueReports } from "../../../services";
import moment from "moment";
import { useQuery } from "react-query";
import { useCurrency } from "../../../contexts/currencyContext";
import { useDemo } from "../../../contexts/demoContext";

export const RevenueBlock = () => {
  const [params, setParams] = React.useState({
    starting_from: moment().format("YYYY-MM-DD 00:00:01Z"),
    ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
  });
  const [range, setRange] = React.useState("today");
  const { currency } = useCurrency();
  const { is_demo } = useDemo();

  const { data } = useQuery(
    [
      "revenue-reports-dashboard",
      {
        is_demo,
        currency,
        ...params,
      },
    ],
    getRevenueReports,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    }
  );

  React.useEffect(() => {
    if (range === "today") {
      setParams({
        starting_from: moment().format("YYYY-MM-DD 00:00:01Z"),
        ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
      });
    }
    if (range === "yesterday") {
      setParams({
        starting_from: moment()
          .subtract(1, "day")
          .format("YYYY-MM-DD 00:00:01Z"),
        ending_at: moment().subtract(1, "day").format("YYYY-MM-DD 23:59:59Z"),
      });
    }
    if (range === "month-to-date") {
      setParams({
        starting_from: moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD 00:00:01Z"),
        ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
      });
    }
    if (range === "last-month") {
      setParams({
        starting_from: moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:01Z"),
        ending_at: moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59Z"),
      });
    }
    if (range === "all-time") {
      setParams({
        starting_from: "",
        ending_at: "",
      });
    }
  }, [range]);

  return (
    <Card>
      <Card.Body className="px-3 py-3 example1-table">
        <h5 className="tx-18">Revenue</h5>
        <div className="dashboard-dates d-flex align-items justify-content-between bd-b pt-3">
          <div
            className={`cursor-pointer w-100 text-center tx-18 px-3 py-2${
              range === "today" ? " active bd-x" : ""
            }`}
            onClick={() => setRange("today")}
          >
            Today
          </div>
          <div
            className={`cursor-pointer w-100 text-center tx-18 px-3 py-2${
              range === "yesterday" ? " active bd-x" : ""
            }`}
            onClick={() => setRange("yesterday")}
          >
            Yesterday
          </div>
          <div
            className={`cursor-pointer w-100 text-center tx-18 px-3 py-2${
              range === "month-to-date" ? " active bd-x" : ""
            }`}
            onClick={() => setRange("month-to-date")}
          >
            Month To Date
          </div>
          <div
            className={`cursor-pointer w-100 text-center tx-18 px-3 py-2${
              range === "last-month" ? " active bd-x" : ""
            }`}
            onClick={() => setRange("last-month")}
          >
            Last Month
          </div>
          <div
            className={`cursor-pointer w-100 text-center tx-18 px-3 py-2${
              range === "all-time" ? " active bd-x" : ""
            }`}
            onClick={() => setRange("all-time")}
          >
            All Time
          </div>
        </div>
        <Row className="pt-3">
          <Col xl={9} lg={9} md={9} xs={9} className="ps-4">
            <Row>
              <Col
                xl={4}
                lg={12}
                md={12}
                xs={12}
                className="tx-16 ps-5 mb-2 pe-0 bd-b pb-2"
              >
                Wager With PFR
                <span className="tx-14 mt-1 text-muted d-block">
                  {data ? (
                    data?.wager_with_pfr
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </span>
              </Col>
              <Col
                xl={4}
                lg={12}
                md={12}
                xs={12}
                className="tx-16 ps-5 mb-2 ps-0 bd-b pb-2"
              >
                Wager Without PFR
                <span className="tx-14 mt-1 text-muted d-block">
                  {data ? (
                    data?.wager_without_pfr
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </span>
              </Col>
              <Col
                xl={4}
                lg={12}
                md={12}
                xs={12}
                className="tx-16 ps-5 mb-2 ps-0 bd-b pb-2"
              >
                Total Wager
                <span className="tx-14 mt-1 text-muted d-block">
                  {data ? (
                    data?.wager
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </span>
              </Col>
              <Col xl={4} lg={12} md={12} xs={12} className="tx-16 ps-5">
                Award With PFR
                <span className="tx-14 mt-1 text-muted d-block">
                  {data ? (
                    data?.award_with_pfr
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </span>
              </Col>
              <Col xl={4} lg={12} md={12} xs={12} className="tx-16 ps-5">
                Award Without PFR
                <span className="tx-14 mt-1 text-muted d-block">
                  {data ? (
                    data?.award_without_pfr
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </span>
              </Col>
              <Col xl={4} lg={12} md={12} xs={12} className="tx-16 ps-5">
                Total Awards
                <span className="tx-14 mt-1 text-muted d-block">
                  {data ? (
                    data?.award
                  ) : (
                    <Spinner
                      animation="border"
                      variant="primary"
                      size="sm"
                      className="spinner-border"
                      role="status"
                    />
                  )}
                </span>
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={3} md={3} xs={3} className="ps-0 bd-s">
            <Col xl={12} lg={12} md={12} xs={12} className="tx-16 mb-2 pb-2">
              Revenue
              <span className="tx-14 mt-1 text-muted d-block">
                {data ? (
                  data?.revenue
                ) : (
                  <Spinner
                    animation="border"
                    variant="primary"
                    size="sm"
                    className="spinner-border"
                    role="status"
                  />
                )}
              </span>
            </Col>
            <Col xl={12} lg={12} md={12} xs={12} className="tx-16">
              Margin
              <span className="tx-14 mt-1 text-muted d-block">
                {data ? (
                  data?.margin
                ) : (
                  <Spinner
                    animation="border"
                    variant="primary"
                    size="sm"
                    className="spinner-border"
                    role="status"
                  />
                )}
              </span>
            </Col>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
