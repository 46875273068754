import React, {useEffect, useState} from 'react';
import {Form, Col, Row, Card, Breadcrumb, Tooltip, OverlayTrigger} from "react-bootstrap";
import {Table} from '../../../components/Tables'
import ConfigModal from "./components/ConfigModal";
import CreateModal from "./components/CreateModal";
import ConfirmModal from "./components/ConfirmModal";
import {useQuery} from "react-query";
import {getWagerSets} from "../../../services";
import {DeleteWagerSetModal} from "./components/DeleteModal";
import {copyToClipboard} from "../../../utilities";
import {Slide, toast} from "react-toastify";

const WagerSets = () => {
    const [data, setData] = useState();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [configModal, setConfigModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);

    const {isLoading, refetch, isRefetching} = useQuery(
        [
            "wager-set",
            {
                limit,
                page,
            },
        ],
        getWagerSets,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            onSuccess: data => {
                setData(data);
            },
        }
    );

    const paginate = {
        page,
        limit,
        setPage,
        setLimit,
    };

    const columns = [
        {
            className: "text-center",
            key: "all",
            name: "ID",
            render: data => (
                <div className="d-flex align-items-center justify-content-center">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className="tooltip tooltip-primary1">Edit wager set</Tooltip>}
                    >
                <span
                    className="d-block text-truncate text-primary text-underline mx-auto cursor-pointer"
                    style={{
                        maxWidth: 90,
                        textDecoration: "underline",
                    }}
                    onClick={() => handleItemClick(data.id)}
                >
          {data.id}
        </span>
                    </OverlayTrigger>
                    <button
                        className="btn tx-10 p-1"
                        onClick={() => copyToClipboard(data.id, copyToastAlert)}
                    >
                        <i className='mdi mdi-content-copy'></i>
                    </button>
                </div>
            ),
        },
        {
            className: "text-center",
            key: "all",
            name: "Name",
            render: data => (
                <span className="d-block text-truncate mx-auto">
          {data.name}
        </span>
            ),
        },
        {
            className: "text-center",
            key: "all",
            name: "Values",
            render: data => (
                <div className='d-flex align-items-baseline justify-content-center'>
                    {data.wager_levels.map((wager, index) => (
                        <React.Fragment key={index}>
        <span className="d-inline-block text-truncate mg-0 pd-0" style={{maxWidth: 140}}>
          {wager}
        </span>
                            {index !== data.wager_levels.length - 1 && <span className='mg-0 pd-0'>,</span>}
                        </React.Fragment>
                    ))}
                </div>
            )
        },
        {
            className: "text-center",
            key: "all",
            name: "Default wager",
            render: data => (
                <div>
                <span
                    className="d-block text-truncate mx-auto"
                    style={{maxWidth: 140}}
                >
          {data.default_wager}
        </span>
                </div>
            ),
        },
        {
            className: 'text-center',
            key: 'all',
            name: 'Status',
            render: (data) => {
                return (
                    <div className="d-flex justify-content-around align-items-center">
                        <span
                            className="d-block text-truncate mx-auto"
                            style={{maxWidth: 140}}>
                            {data.is_active === true ? 'on' : 'off'}
                        </span>
                        <Form.Label className="custom-switch ps-0 cursor-pointer">
                            <Form.Control
                                type="checkbox"
                                name={`custom-switch-checkbox`}
                                className="custom-switch-input"
                                checked={data.is_active === true}
                                onChange={() => handleSwitchToggle(data.id)}
                            />
                            <span className="custom-switch-indicator custom-switch-indicator-md"></span>
                        </Form.Label>
                    </div>
                );
            },
        },
        {
            className: "text-center",
            key: "all",
            name: "Manage",
            width: 60,
            render: data =>
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            className='btn btn-outline-danger d-flex align-items-center justify-content-center p-1'
                            onClick={() => {
                                setSelectedItem(data);
                                showModal("deleteModal");
                            }}
                        >
                            <i className="fe fe-trash" />
                        </button>
                    </div>
        },
    ]

    const copyToastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Copied successfully`
                    : `Error: couldn't copy`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: type === "success" ? 2000 : 5000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    let showModal = (modal) => {
        switch (modal) {
            case 'configModal':
                setConfigModal(true);
                break;
            case 'confirmModal':
                setConfirmModal(true);
                break;
            case 'createModal':
                setCreateModal(true);
                break;
            case 'deleteModal' :
                setDeleteModal(true);
                break;
        }
    }

    let closeModal = (modal) => {
        switch (modal) {
            case 'configModal':
                setConfigModal(false);
                break;
            case 'confirmModal':
                setConfirmModal(false);
                break;
            case 'createModal':
                setCreateModal(false);
                break;
            case 'deleteModal' :
                setDeleteModal(false);
                break;
        }
    }

    const handleItemClick = (propertyValue) => {
        const selectedItem = data?.items.find(item => item.id === propertyValue);
        setSelectedItem(selectedItem);
        showModal('configModal');
    };

    const handleSwitchToggle = (propertyValue) => {
        const selectedItem = data?.items.find(item => item.id === propertyValue);
        setSelectedItem(selectedItem);
        showModal('confirmModal');
    }

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="wager-sets-page">

            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
                Wager sets
                </span>
                </div>
                <div className="justify-content-center mg-t-55 mg-r-100">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => showModal("createModal")}>
                            <span>Create</span> <i
                            className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            {/* <!-- /breadcrumb --> */}

            {/* <!-- row --> */}
            <Row className='overflow-hidden wagers-sets'>
                <Col xl={11}>
                    <Card className="mg-l-35">
                        <Card.Body className="pt-4 example1-table">
                            <Table
                                loading={isLoading || isRefetching}
                                data={data ? data?.items : []}
                                columns={columns}
                                paginate={paginate}
                                total={data ? data?.total : 0}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ConfigModal onSettled={refetch} selectedItem={selectedItem} configModal={configModal} closeModal={() => closeModal('configModal')}/>
            <CreateModal onSettled={refetch} createModal={createModal} closeModal={() => closeModal('createModal')}/>
            <ConfirmModal onSettled={refetch} selectedItem={selectedItem} confirmModal={confirmModal} closeModal={() => closeModal('confirmModal')}/>
            <DeleteWagerSetModal onSettled={refetch} selectedItem={selectedItem} deleteModal={deleteModal} closeModal={() => closeModal('deleteModal')}/>
        </div>
    );
};

export default WagerSets;