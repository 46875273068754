import React from "react";

const ExpendedWildTable = ({ data }) => {
  return (
    <>
      <table
        className="game-stats-info__stats-class mt-0 mb-4"
        id="win_lines_table"
      >
        <thead>
          <tr>
            <td>Has expanded wild</td>
            <td>{data?.spin?.is_expended_wild ? "True" : "False"}</td>
          </tr>
        </thead>
      </table>
      <table
        className="game-stats-info__stats-class mt-0 mb-4"
        id="win_lines_table"
      >
        <thead>
          <tr>
            <td colSpan="4">Reeltype</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2">
              {data?.spin?.reels_type
                ? "High RTP Reeelcode"
                : "Low RTP Reelcode"}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ExpendedWildTable;
