import React from "react";
import moment from "moment/moment";
import { bonusGame } from "../helpers/bonusGame/bonusGame";
import { getTimezoneName } from "../helpers/utilities/getTimezone";

export const GameStateTable = ({ data, gameName }) => {
  return (
    <div className="game-state">
      <table>
        <tbody>
          <tr>
            <td>Game Round ID</td>
            <td id="gameStateID">{data?.id}</td>
          </tr>
          <tr>
            <td>Game Type</td>
            <td id="gameStatus">
              {bonusGame(data) ? "Free spin Game" : "Base Game"}
            </td>
          </tr>
          {gameName !== "aviator" && bonusGame && (
            <tr>
              <td>Free spin Triggered</td>
              <td id="isBonus">{bonusGame(data) ? "Yes" : "No"}</td>
            </tr>
          )}
          <tr>
            <td>Date/Time</td>
            <td id="dateTime">
              {moment(data?.created_at).format("ddd MMM DD YYYY HH:mm:ss UTCZ")}{" "}
              ({getTimezoneName()})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
