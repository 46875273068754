import {
    blazingClovers40Paylines, bookOfDynastyPaylines,
    bookOfJonesPaylines, burningWinPaylines,
    majesticCrownPaylines,
    preciousBugsPaylines, smashingHot20Paylines,
    smashingHotPaylines, stonesOfMagicPaylines,
    wildWestJohnPaylines
} from "./gamesPaylines";

export const payLines = (gameName => {
    let payLinesArray;

    switch (gameName) {
        case "wild-west-john-93":
        case "wild-west-john-95":
            payLinesArray = wildWestJohnPaylines;
            break;
        case "book-of-jones":
            payLinesArray = bookOfJonesPaylines;
            break;
        case "smashing-hot":
        case "smashing-hot-94":
        case "smashing-hot-96":
        case "blazing-clovers":
        case "blazing-clovers-94":
        case "blazing-clovers-96":
            payLinesArray = smashingHotPaylines;
            break;
        case "precious-bugs":
        case "cyber-town":
        case "toreadors-fortune":
            payLinesArray = preciousBugsPaylines;
            break;
        case "majestic-crown-94":
        case "majestic-crown-96":
        case "crystal-ways":
            payLinesArray = majesticCrownPaylines;
            break;
        case "smashing-hot-20-94":
        case "smashing-hot-20-96":
        case "blazing-clovers-20-94":
        case "blazing-clovers-20-96":
            payLinesArray = smashingHot20Paylines;
            break;
        case "book-of-sacred-94":
        case "book-of-sacred-96":
        case "book-of-dynasty-94":
        case "book-of-dynasty-96":
        case "lucky-pint":
        case "undead-riches":
        case "egyptians-book":
            payLinesArray = bookOfDynastyPaylines;
            break;
        case "burning-20-wins":
        case "retro-royale":
            payLinesArray = burningWinPaylines;
            break;
        case "green-gold":
        case "blazing-clovers-40-94":
        case "blazing-clovers-40-96":
            payLinesArray = blazingClovers40Paylines;
            break;
        default:
            payLinesArray = stonesOfMagicPaylines;
            break;
    }

    return payLinesArray;
});