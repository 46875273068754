import React, {useEffect, useState} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { createUser, getOrganizationOperators, getRoles } from "../../../services";
import { toast, Slide } from "react-toastify";
import { validateEmail } from "../../../utilities";
import { Select } from "antd";
import { useProfile } from "../../../contexts";

export const UsersModal = ({ setShowModal, onSettled }) => {
  const {organizationId} = useProfile();
  const [operators, setOperators] = React.useState(null);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      id: "",
      role_id: "",
      token: "",
      operators: "",
    },
  });

  const { data: roles } = useQuery(["roles"], getRoles, {
    refetchOnWindowFocus: false,
  });

  const { mutate } = useMutation(createUser, {
    onSuccess: () => {
      toastAlert("success");
      onSettled();
      setShowModal(false);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setShowModal(false);
    },
  });

  const { refetch: refetchOperators, isFetching} = useQuery(
    [
        "operators-info",
        {
          organizationId
        },
    ],
    getOrganizationOperators,
    {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: organizationId !== null,
        onSuccess: data => {
          setOperators(data);
        },
    }
);

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `User was created successfully`
            : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit(formData => {
    setLoading(true);
    mutate(formData);
  });

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  useEffect(() => {
    refetchOperators();
  }, []);

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Add User</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={e => {
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label>Email</Form.Label>{" "}
            <Form.Control
              placeholder="Enter email"
              type="text"
              {...register("id", {
                required: true,
                validate: value => value?.trim() !== "" && validateEmail(value),
              })}
              isInvalid={isSubmitted && errors?.id}
              isValid={isSubmitted && !errors?.id}
            />
            {errors?.id && <div className="invalid-feedback">Required</div>}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Role</Form.Label>{" "}
            <Controller
              control={control}
              name="role_id"
              rules={{ required: true }}
              render={({
                field: { onChange, value },
                fieldState: { invalid },
              }) => (
                <>
                  <Select
                    className={`form-control${
                      isSubmitted && invalid ? " is-invalid" : ""
                    }${isSubmitted && !invalid ? " is-valid" : ""}`}
                    showSearch
                    value={value || null}
                    onChange={onChange}
                    placeholder="Select Role..."
                    popupClassName="modal-select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    allowClear
                    options={
                      roles?.map(item => ({
                        value: item?.id,
                        label: item?.name?.toUpperCase(),
                      })) || []
                    }
                  />
                  {invalid && <div className="invalid-feedback">Required</div>}
                </>
              )}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label className="">First Name</Form.Label>{" "}
            <Form.Control
              placeholder="Enter first name"
              type="text"
              {...register("first_name", {
                required: true,
                validate: value => value?.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.first_name}
              isValid={isSubmitted && !errors?.first_name}
            />
            {errors?.first_name && (
              <div className="invalid-feedback">Required</div>
            )}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Last Name</Form.Label>{" "}
            <Form.Control
              placeholder="Enter last name"
              type="text"
              {...register("last_name", {
                required: true,
                validate: value => value?.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.last_name}
              isValid={isSubmitted && !errors?.last_name}
            />
            {errors?.last_name && (
              <div className="invalid-feedback">Required</div>
            )}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Operators</Form.Label>{" "}
            <Controller
              control={control}
              name="operators"
              rules={{ required: false }}
              render={({
                field: { onChange, value },
                fieldState: { invalid },
              }) => (
                <>
                  <Select
                    className={`form-control${
                      isSubmitted && invalid ? " is-invalid" : ""
                    }${isSubmitted && !invalid ? " is-valid" : ""}`}
                    showSearch
                    value={value || null}
                    onChange={onChange}
                    placeholder="Select Operators..."
                    popupClassName="modal-select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    allowClear
                    options={
                      operators?.map(item => ({
                        value: item?.id,
                        label: item?.name?.toUpperCase(),
                      })) || []
                    }
                  />
                  {invalid && <div className="invalid-feedback">Required</div>}
                </>
              )}
            />
            {errors?.token && <div className="invalid-feedback">Required</div>}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Password</Form.Label>{" "}
            <Form.Control
              placeholder="Enter password"
              type="text"
              {...register("token", {
                required: true,
                validate: value => value?.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.token}
              isValid={isSubmitted && !errors?.token}
            />
            {errors?.token && <div className="invalid-feedback">Required</div>}
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? (
                <span className="text-white">
                  Loading...
                </span>
            ) : (
                "Create"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
