import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCurrency } from "../../contexts";
import { useQuery } from "react-query";
import { getPublicGameRound } from "../../services";
import { GameStateCollapse } from "../SpinDetail/blocks/gameStateCollapse";

const GameRoundHistory = () => {
  const { round_id, base_game } = useParams();
  const query = new URLSearchParams(useLocation().search);
  // const id = query.get("round_id");

  const { refetch, data, isLoading } = useQuery(
    ["session-detail", { round_id, base_game }],
    getPublicGameRound,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [data]);

  return (
    <>
      <h2 style={{ padding: "1.25rem" }} className="pb-0 tx-20 mb-0">
        Game Rounds
      </h2>
      {data ? <GameStateCollapse data={data} isPublic={true} /> : null}
    </>
  );
};

export default GameRoundHistory;
