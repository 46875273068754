import React, { useEffect, useState } from "react";
import { Editor } from "./Editor";
import { FormControl, Select } from "@material-ui/core";
import { CONFIG } from "../config";
import { getCachedGameIndex, getGames } from "../api/init";
import { envLink } from "../helpers";
import { useProfile } from "../../../contexts";

export interface IGame {
  currencies: string[];
  client_url: string;
  stage_url: string;
  demo_url: string;
  prod_url: string;
  id: string;
  jurisdictions: string[];
  languages: string[];
  name: string;
  user_locales: string;
  owner: string;
}

const enum GameEnv {
  dev = "dev",
  stage = "stage",
}

interface State {
  games: IGame[];
  gameIndex: number;
  filteredGames: IGame[];
  isLoading: boolean;
  env: GameEnv;
}

const Lobby: React.FC = () => {
  const [state, setState] = useState<State>({
    games: [],
    gameIndex: -1,
    filteredGames: [],
    isLoading: true,
    env: GameEnv.dev,
  });

  // @ts-ignore
  const { organizationId } = useProfile();

  useEffect(() => {
    getGames(
      (games: IGame[]) => {
        const newState: State = {
          games: games,
          isLoading: false,
          gameIndex: -1,
          filteredGames: games,
          env: GameEnv.dev,
        };

        const index = getCachedGameIndex();
        if (games[index] !== undefined) {
          newState.gameIndex = index;
        }
        setState(newState);
      },
      () => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    );
  }, [organizationId]);

  let listOfGames;

  if (state.filteredGames.length) {
    listOfGames = state.filteredGames.map((game: IGame, i: number) => (
      <option key={i} value={i}>
        {game.name}
      </option>
    ));
  }

  return (
    <>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Lobby</span>
        </div>
      </div>

      <div className="mg-l-50 d-flex flex-column justify-content-center align-content-center">
        <FormControl className="wd-25p">
          <p className="form-label">Game</p>
          <select
            className="form-control"
            value={state.gameIndex}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              const value = event.target.value as string;
              const index = parseInt(value);
              localStorage.setItem(CONFIG.local_storage_key, value);
              setState((prev) => ({ ...prev, gameIndex: index }));
            }}
          >
            <option aria-label="None" value={-1}>
              {state.isLoading
                ? "Loading..."
                : state.filteredGames.length
                ? "Choose game..."
                : "No games for selected organization"}
            </option>
            {listOfGames}
          </select>
        </FormControl>
        {state.gameIndex !== -1 ? (
          <Editor
            key={state.gameIndex}
            game={state.filteredGames[state.gameIndex]}
            env={state.env}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Lobby;
