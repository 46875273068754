import React from 'react';

const Filters = ({ setRange, setActiveRange, activeRange, setOpenCustomFilter, isPopup }) => {

    return (
        <div className='d-flex' style={{marginLeft: '16px'}}>
            <p className='mg-r-10'>Filters:</p>
            <div
                className={`range-filter ${activeRange === "today" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("today");
                    setActiveRange("today");
                }}
            >Today</div>
            <div
                className={`range-filter ${activeRange === "yesterday" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("yesterday");
                    setActiveRange("yesterday");
                }}
            >Yesterday</div>
            <div
                className={`range-filter ${activeRange === "last-week" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("last-week");
                    setActiveRange("last-week");
                }}
            >Last week</div>
            <div
                className={`range-filter ${activeRange === "last-month" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("last-month");
                    setActiveRange("last-month");
                }}
            >Last month</div>
            <div
                className={`range-filter ${activeRange === "3-months" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("3-months");
                    setActiveRange("3-months");
                }}
            >3 month</div>
            <div
                className={`range-filter ${activeRange === "6-months" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("6-months");
                    setActiveRange("6-months");
                }}
            >6 month</div>
            <div
                className={`range-filter ${activeRange === "1-year" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("1-year");
                    setActiveRange("1-year");
                }}
            >1 year</div>
            <div
                className={`range-filter ${activeRange === "all-time" ? "active-filter" : ""}`}
                onClick={() => {
                    setRange("all-time");
                    setActiveRange("all-time");
                }}
            >All time</div>
            {!isPopup && <div
                className={`range-filter ${activeRange === "custom" ? "active-filter" : ""}`}
                onClick={() => {
                    setOpenCustomFilter(true);
                    setActiveRange("custom");
                }}
            >Custom</div>}
        </div>
    );
};

export default Filters;