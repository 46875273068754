import { payLines } from "../../helpers/paylines/paylines";
import { getRowsForGame } from "../../helpers/rows/rows";
import { symbolImageLink } from "../../helpers/symbols/symbolImageLink";
import React from "react";

export function processWinlinesTable(stage, winningLine, gameName) {
  let bonusIndex = 0;
  let payline =
    winningLine?.payline === undefined
      ? winningLine.indexes
      : winningLine?.payline === -1
      ? winningLine.indexes
      : payLines(gameName)[winningLine?.payline];
  if (winningLine.direction === "right-to-left") {
    payline = [...payline].reverse();
  }

  const highlightIndexes = {};

  stage?.stages?.[0].payouts?.scatter_values?.forEach((scatter) => {
    if (scatter.indexes?.length > 0) {
      scatter.indexes.forEach((index) => {
        highlightIndexes[index] = scatter.symbol;
      });
    }
  });

  stage?.payouts?.scatter_values?.forEach((scatter) => {
    if (scatter.indexes?.length > 0) {
      scatter.indexes.forEach((index) => {
        highlightIndexes[index] = scatter.symbol;
      });
    }
  });

  const renderMultiplierSymbol = (columnKey, row) => {
    if (stage.payouts.multiplier_mask) {
      const reversedColumn = [
        ...stage.payouts.multiplier_mask[columnKey],
      ].reverse();
      const multiplierSymbol = reversedColumn ? reversedColumn[row] : null;
      return multiplierSymbol;
    }
    if (stage?.multiplier_mask) {
      const reversedColumn = [...stage.multiplier_mask[columnKey]].reverse();
      const multiplierSymbol = reversedColumn ? reversedColumn[row] : null;
      return multiplierSymbol;
    }
  };

  const renderGalaMultiplierSymbol = (columnKey, row) => {
    if (stage.wild_bonus_mask) {
      const reversedColumn = [...stage.wild_bonus_mask[columnKey]].reverse();
      const multiplierSymbol = reversedColumn ? reversedColumn[row] : null;
      return multiplierSymbol;
    }
    if (stage.wild_mask) {
      const reversedColumn = [...stage.wild_mask[columnKey]].reverse();
      const multiplierSymbol = reversedColumn ? reversedColumn[row] : null;
      return multiplierSymbol;
    }
  };

  return (
    <tbody>
      {getRowsForGame(gameName)?.map((row, rowKey) => (
        <tr key={rowKey}>
          {stage?.reel_window?.length > 0
            ? stage?.reel_window?.map((column, columnKey) => {
                let bonusSymbols;
                const reversedColumn = [...column].reverse();
                const symbol =
                  gameName === "jelly-joy-bonanza" ||
                  gameName === "rush-moji" ||
                  gameName === "tutti-frutti" ||
                  gameName === "candy-crashout" ||
                  gameName === "cowboy-cashout" ||
                  gameName === "clover-cluster" ||
                  gameName === "rsh-gates-of-olympus" ||
                  gameName === "gamblers-gala"
                    ? reversedColumn[row]
                    : column[row];
                const symbolIndex =
                  columnKey + rowKey * stage?.reel_window.length;
                const partOfPayline = payline?.slice(0, winningLine.count);
                let isSymbolInWinLine = partOfPayline?.includes(symbolIndex);
                const reelType =
                  "reel_type" in stage &&
                  (gameName !== "king-of-vegas-nights" ||
                    gameName === "dino-reels")
                    ? stage.reel_type === null || stage.reel_type === 0
                      ? ""
                      : `${stage.reel_type}_`
                    : "";

                if (stage?.bonus_game !== null) {
                  if (symbol === "S") {
                    isSymbolInWinLine = true;
                  }
                }

                if (highlightIndexes[symbolIndex]) {
                  isSymbolInWinLine = true;
                }

                if (
                  gameName === "lucky-skulls-bonanza" ||
                  gameName === "rsh-gates-of-olympus" ||
                  gameName === "gamblers-gala" ||
                  gameName === "candy-crashout" ||
                  gameName === "cowboy-cashout" ||
                  gameName === "rush-moji" ||
                  gameName === "tutti-frutti" ||
                  gameName === "lucky-santa-bonanza" ||
                  gameName === "carnival-beauty-respin" ||
                  gameName === "irish-riches-bonanza" ||
                  gameName === "clover-cluster" ||
                  (gameName === "jelly-joy-bonanza" &&
                    winningLine.symbol === symbol)
                ) {
                  isSymbolInWinLine = false;
                  stage?.payouts?.values.forEach((payout) => {
                    payout.indexes.forEach((indexArray, reelIndex) => {
                      const reverseIndexArray = indexArray?.map((index) => {
                        const payoutIndex =
                          gameName === "candy-crashout" ||
                          gameName === "cowboy-cashout" ||
                          gameName === "rsh-gates-of-olympus" ||
                          gameName === "gamblers-gala"
                            ? payout.indexes.length - 2 - index
                            : payout.indexes.length - 1 - index;
                        return payoutIndex;
                      });
                      if (
                        reverseIndexArray &&
                        reverseIndexArray.includes(rowKey) &&
                        // indexArray &&
                        // indexArray.includes(rowKey) &&
                        columnKey === reelIndex
                      ) {
                        isSymbolInWinLine = true;
                      }
                    });
                  });
                }

                if (
                  gameName === "king-of-vegas-nights" ||
                  gameName === "dino-reels"
                ) {
                  isSymbolInWinLine =
                    winningLine.indexes?.includes(symbolIndex);
                  bonusSymbols = stage?.bonus_symbols;
                }

                if (gameName === "candy-crashout") {
                  bonusSymbols = stage?.multipliers;
                }

                if (gameName === "cowboy-cashout") {
                  bonusSymbols = stage?.multiplier_mask;
                  if (bonusSymbols) {
                    bonusSymbols = bonusSymbols[columnKey].filter(
                      (symbol) => symbol !== 0
                    );
                    console.log(bonusSymbols, "bonusSymbols");
                  }
                }

                if (gameName === "clover-cluster") {
                  bonusSymbols = stage?.payouts?.wilds;
                }

                if (
                  gameName === "rsh-gates-of-olympus" ||
                  gameName === "gamblers-gala"
                ) {
                  bonusSymbols = stage?.payouts?.total_multiplier;
                }

                const renderSymbol = (symbol, symbolIndex) => {
                  // console.log(bonusSymbols, "bonusSymbols");
                  if (symbol === symbolIndex && bonusSymbols) {
                    let displayText;
                    const bonusSymbol =
                      gameName === "rsh-gates-of-olympus" ||
                      gameName === "gamblers-gala"
                        ? bonusSymbols
                        : bonusSymbols[bonusIndex];
                    if (gameName === "clover-cluster") {
                      displayText = bonusSymbol === 1 ? "" : `X${bonusSymbol}`;
                    } else {
                      displayText =
                        bonusSymbol === 1 ? "WILD" : `X${bonusSymbol}`;
                    }

                    const style = bonusSymbol === 1 ? { fontSize: 34 } : null;

                    if (bonusSymbols) {
                      if (bonusIndex < bonusSymbols.length - 1) {
                        bonusIndex++;
                      }
                    }

                    return (
                      <span
                        style={
                          (style,
                          {
                            fontSize:
                              gameName === "clover-cluster" ? "15px" : "20px",
                            display: "flex",
                            alignItems: "center",
                            height: "20px",
                            paddingBottom:
                              gameName === "clover-cluster" ? "10px" : null,
                            justifyContent: "center",
                          })
                        }
                        className="winNumber"
                      >
                        {displayText}
                      </span>
                    );
                  }
                  return null;
                };

                const renderSymbolCowboyCashout = (symbol, symbolIndex) => {
                  if (symbol === symbolIndex && bonusSymbols) {
                    let displayText;
                    const bonusSymbol = bonusSymbols[0];

                    displayText =
                      bonusSymbol === 1 ? "WILD" : `X${bonusSymbol}`;

                    const style = bonusSymbol === 1 ? { fontSize: 34 } : null;

                    if (bonusSymbols) {
                      if (bonusIndex < bonusSymbols.length - 1) {
                        bonusIndex++;
                      }
                    }

                    return (
                      <span
                        style={
                          (style,
                          {
                            fontSize:
                              gameName === "clover-cluster" ? "15px" : "20px",
                            display: "flex",
                            alignItems: "center",
                            height: "20px",
                            paddingBottom:
                              gameName === "clover-cluster" ? "10px" : null,
                            justifyContent: "center",
                          })
                        }
                        className="winNumber"
                      >
                        {displayText}
                      </span>
                    );
                  }
                  return null;
                };

                const resultsSymbol = column.map((value) => {
                  if ((value === 30 || value === 10) && bonusSymbols) {
                    const bonusSymbol = bonusSymbols[bonusIndex];

                    bonusIndex = (bonusIndex + 1) % bonusSymbols.length;

                    return bonusSymbol / 1000;
                  }
                  return;
                });

                if (
                  gameName === "dino-reels" &&
                  stage?.free_game_spin &&
                  symbol === 10
                ) {
                  isSymbolInWinLine = true;
                }

                const brightness = isSymbolInWinLine ? 1 : 0.4;
                return (
                  <td
                    key={columnKey}
                    style={{
                      width: "90px",
                      filter: `brightness(${brightness})`,
                      backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                        gameName
                      )}/${reelType}${String(symbol)?.toLowerCase()}.png)`,
                      position: "relative",
                    }}
                  >
                    {(gameName === "rsh-gates-of-olympus" ||
                      gameName === "gamblers-gala") &&
                    symbol === 11 ? (
                      <span className="winNumberGalaAvalanche">
                        {renderGalaMultiplierSymbol(columnKey, row) > 1
                          ? `Х${renderGalaMultiplierSymbol(columnKey, row)}`
                          : " "}
                      </span>
                    ) : null}
                    {gameName === "cowboy-cashout"
                      ? renderSymbolCowboyCashout(symbol, 11)
                      : null}
                    {gameName === "candy-crashout"
                      ? renderSymbol(symbol, 11)
                      : null}
                    {gameName === "clover-cluster"
                      ? renderSymbol(symbol, 9)
                      : null}
                    {gameName === "rush-moji" || gameName === "tutti-frutti" ? (
                      <span className="winNumberAvalanche">
                        {renderMultiplierSymbol(columnKey, row) > 1
                          ? `Х${renderMultiplierSymbol(columnKey, row)}`
                          : " "}
                      </span>
                    ) : null}
                    {symbol === 30 && gameName === "king-of-vegas-nights" ? (
                      <div className="symbol-multiplier">
                        {resultsSymbol[row]}
                      </div>
                    ) : null}
                    {symbol === 10 && gameName === "dino-reels" ? (
                      <div
                        className="symbol-multiplier"
                        style={{ border: "none", fontSize: "20px" }}
                      >
                        {resultsSymbol[row]}
                      </div>
                    ) : null}
                    {!symbolImageLink(gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </td>
                );
              })
            : stage?.window?.length > 0
            ? stage?.window?.map((column, columnKey) => {
                const symbol = column[row];
                const symbolIndex = columnKey + rowKey * stage?.window.length;
                const partOfPayline = payline.slice(0, winningLine.count);
                let isSymbolInWinLine = partOfPayline.includes(symbolIndex);

                if (highlightIndexes[symbolIndex]) {
                  isSymbolInWinLine = true;
                }

                const brightness = isSymbolInWinLine ? 1 : 0.4;

                return (
                  <td
                    key={columnKey}
                    style={{
                      filter: `brightness(${brightness})`,
                      backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                        gameName
                      )}/${String(symbol)?.toLowerCase()}.png)`,
                      position: "relative",
                    }}
                  >
                    {!symbolImageLink(gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </td>
                );
              })
            : stage?.stages?.[0].reel_window?.map((column, columnKey) => {
                const symbol = column[row];
                const symbolIndex =
                  columnKey + rowKey * stage?.stages?.[0].reel_window.length;
                const partOfPayline = payline?.slice(0, winningLine.count);
                let isSymbolInWinLine = partOfPayline?.includes(symbolIndex);

                if (highlightIndexes[symbolIndex]) {
                  isSymbolInWinLine = true;
                }

                const brightness = isSymbolInWinLine ? 1 : 0.4;

                return (
                  <td
                    key={columnKey}
                    style={{
                      filter: `brightness(${brightness})`,
                      backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                        gameName
                      )}/${String(symbol).toLowerCase()}.png)`,
                      position: "relative",
                    }}
                  >
                    {!symbolImageLink(gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </td>
                );
              })}
        </tr>
      ))}
    </tbody>
  );
}
