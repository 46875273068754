import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from "react";

interface Props {
    name: string;
    value: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox = (props: Props) => {
    const { name, value, onChange } = props;

    return (
        <div className="checkbox-container">
            <FormControlLabel
                className="custom-form"
                control={<Checkbox name={name} value={value} onChange={e => onChange(e)} />}
                label={props.name}
            />
        </div>
    );
};

export { CustomCheckbox };
