export const bonusSpinCounter = (gameName, data) => {
  let bonusSpinCount = data?.spin?.pay_lines_to_show?.length || "-";

  if (
    gameName === "stones-of-magic" ||
    gameName === "secret-totems" ||
    gameName === "olympus-quest"
  ) {
    bonusSpinCount = data?.spin?.spins?.[0]?.stages.reduce(
      (accumulator, currentValue) => {
        if (currentValue?.bonus_game) {
          return accumulator + currentValue?.bonus_game?.spins?.length;
        }
        return accumulator;
      },
      0
    );
  }

  if (
    gameName === "lucky-pint" ||
    gameName === "undead-riches" ||
    gameName === "egyptians-book"
  ) {
    bonusSpinCount = data?.spin?.bonus?.spins?.length;
  }

  if (gameName?.includes("book-of-jones")) {
    bonusSpinCount = data?.spin?.bonus_spins?.length;
  }

  if (
    gameName === "cyber-town" ||
    gameName === "precious-bugs" ||
    gameName === "toreadors-fortune"
  ) {
    bonusSpinCount = data?.spin?.bonus?.spins_results.length;
  }

  if (
    gameName === "ego-draconis" ||
    gameName === "asgard-party" ||
    gameName === "sweet-mystery-flexiways"
  ) {
    bonusSpinCount = data?.spin?.spins?.reduce((total, spin) => {
      const stages = spin?.stages || [];
      const spinBonusCount = stages.reduce((sum, stage) => {
        const bonusGameCount = stage.bonus_game?.spins?.length || 0;
        return sum + bonusGameCount;
      }, 0);
      return total + spinBonusCount;
    }, 0);
  }

  return bonusSpinCount;
};
