import React from "react";
import { GameStateCollapse } from "./gameStateCollapse";
import "../SpinDetail/styles/spin_detail.scss";

export const GameStateCard = ({ data, isPublic = false }) => {
  return (
    <div className="py-3 spin-detail pe-0">
      <GameStateCollapse data={data} isPublic={isPublic} />
    </div>
  );
};
