import React from 'react';

const Tabs = ({ setTab, activeTab, setActiveTab }) => {
    return (
        <div>
            <div className={`filter-tab ${activeTab === "users" ? "active-tab" : ""}`}
                 onClick={() => {
                         setTab('users')
                         setActiveTab('users')
                 }}
            >Users</div>
                <div className={`filter-tab ${activeTab === "rounds" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('rounds')
                             setActiveTab('rounds')
                     }}
                >Game Rounds</div>
                <div className={`filter-tab ${activeTab === "bets" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('bets')
                             setActiveTab('bets')
                     }}
                >Bets</div>
                <div className={`filter-tab ${activeTab === "wins" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('wins')
                             setActiveTab('wins')
                     }}
                >Wins</div>
                <div className={`filter-tab ${activeTab === "revenue" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('revenue')
                             setActiveTab('revenue')
                     }}
                >GGR</div>
                <div className={`filter-tab ${activeTab === "rtp" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('rtp')
                             setActiveTab('rtp')
                     }}
                >RTP</div>
                <div className={`filter-tab ${activeTab === "freeRounds" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('freeRounds')
                             setActiveTab('freeRounds')
                     }}
                >Free rounds</div>
                <div className={`filter-tab ${activeTab === "freeRoundsBets" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('freeRoundsBets')
                             setActiveTab('freeRoundsBets')
                     }}
                >Free rounds bets</div>
                <div className={`filter-tab ${activeTab === "freeRoundsWins" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('freeRoundsWins')
                             setActiveTab('freeRoundsWins')
                     }}
                >Free rounds wins</div>
                <div className={`filter-tab ${activeTab === "freeRoundsRevenue" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('freeRoundsRevenue')
                             setActiveTab('freeRoundsRevenue')
                     }}
                >Free rounds GGR</div>
                <div className={`filter-tab ${activeTab === "roundPerUser" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('roundPerUser')
                             setActiveTab('roundPerUser')
                     }}
                >Game Rounds/User</div>
                <div className={`filter-tab ${activeTab === "betsPerUser" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('betsPerUser')
                             setActiveTab('betsPerUser')
                     }}
                >Bets/User</div>
                <div className={`filter-tab ${activeTab === "revenuePerUser" ? "active-tab" : ""}`}
                     onClick={() => {
                             setTab('revenuePerUser')
                             setActiveTab('revenuePerUser')
                     }}
                >GGR/User</div>
        </div>
    );
};

export default Tabs;