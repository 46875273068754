import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Table } from "../../../components/Tables";
import { getSessionsList } from "../../../services";
import { Select } from "antd";
import moment from "moment";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useCurrency, useProfile } from "../../../contexts";
import { convertToCurrency } from "../../../utilities";
import { useDemo } from "../../../contexts/demoContext";

export const PerformanceBlock = ({ games, hosts, type = null }) => {
  const params = {
    starting_from: moment()
      .subtract(1, type ? "day" : "hour")
      .format("YYYY-MM-DD HH:mm:ssZ"),
    ending_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
  };
  const [game, setGame] = useState("");
  const [host, setHost] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [limit, setLimit] = useState(3);

  const { currency } = useCurrency();
  const { organizationId } = useProfile();
  const { is_demo } = useDemo();

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );

  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const { isLoading, refetch, isRefetching } = useQuery(
    [
      `session-spins${type ? "-daily" : ""}`,
      {
        is_demo,
        currency,
        limit,
        page,
        game,
        host,
        ...params,
        order: "created_at desc",
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getSessionsList,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  const columns = [
    {
      className: "text-center",
      key: "wager",
      name: "Wager",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "revenue",
      name: "Revenue",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "session_token",
      name: "Session ID",
      highlight: true,
      width: 450,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/sessions/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 300,
            }}
          >
            {data}
          </Link>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "created_at",
      name: type === "daily" ? "Date" : "Time",
      width: 450,
      render: (data) => (
        <>
          {!data ? (
            "-"
          ) : (
            <>
              <span className="d-block">
                {moment(data).format("DD-MM-YYYY")}
              </span>
              <span className="d-block">
                {moment(data).format("HH:mm UTC")}
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [
    page,
    limit,
    currency,
    organizationId,
    is_demo,
    selectedIntegrator,
    selectedOperator,
  ]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <Card.Body className="px-3 py-3 example1-table">
        <h5 className="tx-18">
          {type ? "Last 24 Hours" : "Hourly"} Performance
        </h5>
        <form
          className="w-100"
          id="filters"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="row mb-3">
            <div className="form-group mb-0 col-2">
              <label className="form-label">Game</label>
              <Select
                allowClear
                className="form-control"
                showSearch
                placeholder="Select Game..."
                optionFilterProp="children"
                value={game || null}
                onChange={(game) => setGame(game)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  games?.map((item) => ({
                    value: item?.name,
                    label: item?.name?.toUpperCase(),
                  })) || []
                }
              />
            </div>
            <div className="form-group mb-0 col-2">
              <label className="form-label">Host</label>
              <Select
                allowClear
                className="form-control"
                showSearch
                placeholder="Select Host..."
                optionFilterProp="children"
                value={host || null}
                onChange={(host) => setHost(host)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  hosts?.map((item) => ({
                    value: item,
                    label: item,
                  })) || []
                }
              />
            </div>
          </div>
        </form>
        <Table
          loading={isLoading || isRefetching}
          data={data ? data.items : []}
          columns={columns}
          height={100}
          sizes={[3, 5, 10]}
          paginate={paginate}
          total={data ? data?.total : 0}
        />
      </Card.Body>
    </Card>
  );
};
