import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { updateCurrency } from "../../../../services";
import { toast, Slide } from "react-toastify";

export const EditCurrency = ({
  setShowModal, selectedData,
  setSelectedData,
  data: { organization_pair_id, title, multiplier, synonym },
}) => {
  const queryClient = useQueryClient();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organization_pair_id,
      title,
      multiplier,
      synonym,
    },
  });

  const { mutate } = useMutation(updateCurrency, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-currencies");
      toastAlert("success");
      setShowModal(false);
    },
    onError: () => {
      toastAlert("error");
      setShowModal(false);
    },
  });

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Updated currency successfully`
          : `Couldn't update currency`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit(formData => {
    console.log(selectedData)
    organization_pair_id = selectedData?.organization_pair_id;
    title = selectedData?.title
    setLoading(true);
    mutate({ ...formData, multiplier: Number(formData?.multiplier), synonym: formData?.synonym });
  });

  React.useEffect(() => {
    return () => {
      setSelectedData(null);
    };
    // eslint-disable-next-line
  }, []);


  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Edit Currency</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
          <Form
            className={`needs-validation${isSubmitted ? "" : ""}`}
            onSubmit={e => {
              e.preventDefault();
              setIsSubmitted(true);
              onSubmit(e);
            }}
          >
            <Form.Group className="form-group">
              <Form.Label>Multiplier</Form.Label>
              <Form.Control
                placeholder="Enter name"
                type="number"
                {...register("multiplier", {
                  required: true,
                  validate: value => value > 0,
                })}
                isInvalid={isSubmitted && errors?.multiplier}
                isValid={isSubmitted && !errors?.multiplier}
              />
              {errors?.multiplier && (
                <div className="invalid-feedback">Required</div>
              )}
              <Form.Label>Synonym</Form.Label>
              <Form.Control
                placeholder="Enter synonym"
                type="text"
                {...register("synonym", {
                  required: true,
                  // validate: value => /^[A-Za-z]+$/.test(value),
                })}
                isInvalid={isSubmitted && errors?.synonym}
                isValid={isSubmitted && !errors?.synonym}
              />
              {errors?.synonym && (
                <div className="invalid-feedback">Required</div>
              )}
            </Form.Group>
            <Button
              disabled={loading}
              variant=""
              type="submit"
              style={{ minHeight: 51 }}
              className="btn btn-primary btn-block tx-16"
            >
              {loading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="spinner-border me-2 text-light"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Update"
              )}
            </Button>
          </Form>

      </Modal.Body>
    </Modal>
  );
};
