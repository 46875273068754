import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Table } from "../../../components/Tables";
import CreateModal from "./components/CreateModal";
import ConfirmModal from "./components/ConfirmModal";
import { useMutation, useQuery } from "react-query";
import {
  deleteGame,
  getCurrenciesData,
  getGamesInfo,
  getJurisdictions,
  getLanguages,
  getLocales,
  getWagerSets,
} from "../../../services";
import { Link } from "react-router-dom";
import { useProfile } from "../../../contexts";
import { Slide, toast } from "react-toastify";
import { copyToClipboard } from "../../../utilities";

const GamesInfo = () => {
  const { organizationId } = useProfile();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [confirmModal, setConfirmModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [isItemCopied, setIsItemCopied] = useState(false);

  const [data, setData] = useState({});
  const [games, setGames] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [locales, setLocales] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [wagerSets, setWagerSets] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  const {
    isLoading,
    refetch: refetchGames,
    isRefetching,
  } = useQuery(
    [
      "games-info",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getGamesInfo,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setData(data);
        setGames(data.data);
      },
    }
  );

  const { refetch: refetchCurrencies } = useQuery(
    ["currencies"],
    getCurrenciesData,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const currencyArray = data.map((currency) => ({
          label: currency.toUpperCase(),
          value: currency,
        }));
        setCurrencies(currencyArray);
      },
    }
  );

  const { refetch: refetchJurisdictions } = useQuery(
    ["jurisdictions"],
    getJurisdictions,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const jurArray = data.map((jurisdiction) => ({
          label: jurisdiction.toUpperCase(),
          value: jurisdiction,
        }));
        setJurisdictions(jurArray);
      },
    }
  );

  const { refetch: refetchLocales } = useQuery(["locales"], getLocales, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      const locArray = data.map((locale) => ({
        label: locale.toUpperCase(),
        value: locale,
      }));
      setLocales(locArray);
    },
  });

  const { refetch: refetchLanguages } = useQuery(["languages"], getLanguages, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      const langArray = data.map((lannguage) => ({
        label: lannguage.toUpperCase(),
        value: lannguage,
      }));
      setLanguages(langArray);
    },
  });

  const { refetch: refetchWagerSets } = useQuery(
    [
      "wager-set",
      {
        limit: 100,
        page: 1,
      },
    ],
    getWagerSets,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const wagersArray = data?.items?.map((wager) => ({
          label: wager.name,
          value: wager.id,
        }));
        setWagerSets(wagersArray);
      },
    }
  );

  const copyToastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success" ? `Copied successfully` : `Error: couldn't copy`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: type === "success" ? 2000 : 5000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Game ID",
      render: (data) => (
        <div
          onClick={() => handleItemClick(data?.id)}
          className="d-flex align-items-baseline justify-content-center"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="tooltip tooltip-primary1">
                Edit game details
              </Tooltip>
            }
          >
            <Link
              to={`/games/info/${data?.id}`}
              className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
              style={{
                textDecoration: "underline",
                maxWidth: 80,
              }}
            >
              {data?.id}
            </Link>
          </OverlayTrigger>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data.id, copyToastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Name",
      render: (data) => (
        <div>
          <span
            className="d-block text-truncate mx-auto"
            style={{ maxWidth: 140 }}
          >
            {data.name}
          </span>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Currencies",
      render: (data) => {
        const { currencies } = data;

        if (!currencies) {
          return null;
        }

        const currenciesPerLine = Math.ceil(currencies.length / 3);

        return (
          <div className="d-flex flex-column align-items-center">
            {[...Array(3)].map((_, lineIndex) => (
              <div key={lineIndex}>
                {currencies
                  .slice(
                    lineIndex * currenciesPerLine,
                    (lineIndex + 1) * currenciesPerLine
                  )
                  .map((currency) => (
                    <span key={currency}>{currency.toUpperCase() + ", "}</span>
                  ))}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      className: "text-center",
      key: "all",
      name: "Languages",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.languages?.join(", ").toUpperCase()}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Locales",
      render: (data) => {
        const { user_locales } = data;

        if (!user_locales) {
          return null;
        }

        const localesPerLine = Math.ceil(user_locales.length / 3);

        return (
          <div className="d-flex flex-column align-items-center">
            {[...Array(3)].map((_, lineIndex) => (
              <div key={lineIndex}>
                {user_locales
                  .slice(
                    lineIndex * localesPerLine,
                    (lineIndex + 1) * localesPerLine
                  )
                  .join(", ")}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      className: "text-center",
      key: "all",
      name: "Jurisdictions",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.jurisdictions?.join(", ")}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "RTP",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.rtp}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Volatility",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.volatility}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Wager set",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.wager_set_id ? getWagerLabelById(data.wager_set_id) : ""}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Demo",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.is_demo ? "On" : "Off"}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Freespins",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.is_freespins ? "On" : "Off"}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Public",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.is_public ? "On" : "Off"}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Statistics",
      render: (data) => (
        <div className="d-flex align-items-baseline justify-content-center">
          {data.is_statistic_shown ? "On" : "Off"}
        </div>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: (data) => (
        <div
          className="d-flex"
          style={{ gap: "10px", justifyContent: "center" }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-outline-danger d-flex align-items-center justify-content-center p-1"
              onClick={() => {
                setSelectedItem(data);
                showModal("confirmModal");
              }}
            >
              <i className="fe fe-trash" />
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-outline-success d-flex align-items-center justify-content-center p-1"
              onClick={() => {
                setSelectedItem(data);
                showModal("createModal");
                setIsItemCopied(true);
              }}
            >
              <i className="fe fe-copy" />
            </button>
          </div>
        </div>
      ),
    },
  ];

  let showModal = (modal) => {
    switch (modal) {
      case "confirmModal":
        setConfirmModal(true);
        break;
      case "createModal":
        setCreateModal(true);
        break;
    }
  };

  let closeModal = (modal) => {
    switch (modal) {
      case "confirmModal":
        setConfirmModal(false);
        break;
      case "createModal":
        setCreateModal(false);
        break;
    }
  };

  const handleItemClick = (propertyValue) => {
    const selectedItem = games?.find((item) => item.id === propertyValue);
    setSelectedItem(selectedItem);
    showModal("configModal");
  };

  const { mutate: deleteGames } = useMutation(deleteGame, {
    onSuccess: () => {
      closeModal("deleteModal");
      toastAlert("success");
      refetchGames();
      selectedItem && setSelectedItem(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      closeModal("deleteModal");
      selectedItem && setSelectedItem(null);
    },
  });

  const getWagerLabelById = (id) => {
    const wagerSet = wagerSets.find((wager) => wager.value === id);
    return wagerSet ? wagerSet.label : "";
  };

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Game was deleted successfully`
          : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  useEffect(() => {
    refetchGames();
    refetchCurrencies();
    refetchJurisdictions();
    refetchLocales();
    refetchLanguages();
    refetchWagerSets();
  }, []);

  useEffect(() => {
    refetchGames();
    refetchCurrencies();
    refetchJurisdictions();
    refetchLocales();
    refetchLanguages();
    refetchWagerSets();
  }, [organizationId]);

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  return (
    <div className="games-page">
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Games info
          </span>
        </div>
        <div className="justify-content-center mg-t-55 mg-r-100">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="breadcrumb-item tx-15"
              onClick={() => showModal("createModal")}
            >
              <span>Create</span>
              <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- row --> */}
      <Row className="overflow-hidden wagers-sets">
        <Col xl={11}>
          <Card className="mg-l-35">
            <Card.Body>
              <div className="pt-4 example1-table">
                <Table
                  loading={isLoading || isRefetching}
                  data={games ? games : []}
                  columns={columns}
                  paginate={paginate}
                  total={data ? data?.meta?.total : 0}
                  handleClick={() => showModal("configModal")}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CreateModal
        organizationId={organizationId}
        onSettled={refetchGames}
        createModal={createModal}
        currencies={currencies}
        isItemCopied={isItemCopied}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setIsItemCopied={setIsItemCopied}
        jurisdictions={jurisdictions}
        locales={locales}
        languages={languages}
        wagers={wagerSets}
        closeModal={() => closeModal("createModal")}
      />
      <ConfirmModal
        selectedItem={selectedItem}
        confirmModal={confirmModal}
        onSubmit={() => {
          deleteGames(selectedItem?.id);
        }}
        closeModal={() => closeModal("confirmModal")}
      />
    </div>
  );
};

export default GamesInfo;
