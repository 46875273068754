import httpClient from "./httpClient";

//Users

export const getOrganizations = async ({ queryKey }) => {
    const params = new URLSearchParams(queryKey[1]);
    const { data } = await httpClient.get(`/organizations?${params.toString()}`);
    if (data) {
        return data.data;
    }
};

export const createOrganization = async formData => {
    const { data } = await httpClient.post("/organizations", { ...formData });
    if (data) {
        return data.data;
    }
};

export const updateOrganization = async formData => {
    const { data } = await httpClient.put(`/organizations/${formData?.id}`, { ...formData });
    if (data) {
        return data.data;
    }
};

export const deleteOrganization = async id => {
    const { data } = await httpClient.delete(`/organizations/${id}`);
    if (data) {
        return data.data;
    }
};