import React from "react";
import moment from "moment";
import { Card, Col, Row, Collapse } from "react-bootstrap";
import { GeneralInfoBlock } from "../SpinDetail/blocks/generalInformationBlock";
import { RequestBlock } from "../SpinDetail/blocks/requestBlock";
import { ResultBlock } from "../SpinDetail/blocks/resultBlock";
import "../SpinDetail/styles/spin_detail.scss";
import { ResultTable } from "../SpinDetail/tables/ResultTable";

export const GameStateCollapse = ({ data, isPublic }) => {
  const [open, setOpen] = React.useState(isPublic);

  return (
    <Card className="my-2 radius-10 bd">
      <Card.Body className="spin-time-line transparent py-3">
        <Row className="px-0 cursor-pointer" onClick={() => setOpen(!open)}>
          <Col
            className="d-flex align-items-center bd-e"
            xl={3}
            lg={3}
            md={12}
            xs={12}
          >
            <div className="icon-holder d-flex align-items-center justify-content-center tx-20 text-white bg-info rounded-circle ms-2">
              <i className="fa fa-gamepad" />
            </div>
            <div className="tx-16 text-muted ms-2 tx-bold flex-1 text-truncate">
              {isPublic ? `Spin ID: ${data?.id}` : data?.id}
            </div>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center bd-e tx-14 text-muted"
            xl={2}
            lg={2}
            md={12}
            xs={12}
          >
            {moment(data?.created_at).format("DD-MM-YYYY HH:mm:ss UTC(Z)")}
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center bd-e tx-14 text-muted"
            xl={2}
            lg={2}
            md={12}
            xs={12}
          >
            Wager{" "}
            <span className="tx-bold ms-1">
              {data?.wager} {data?.currency?.toUpperCase()}
            </span>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center bd-e tx-14 text-muted"
            xl={2}
            lg={2}
            md={12}
            xs={12}
          >
            Award{" "}
            <span className="tx-bold ms-1">
              {data?.base_award + data?.bonus_award}{" "}
              {data?.currency?.toUpperCase()}
            </span>
          </Col>
          <Col
            className="d-flex align-items-center tx-14 text-muted"
            xl={3}
            lg={3}
            md={12}
            xs={12}
          >
            Status{" "}
            <span className="tx-bold ms-1">
              {data?.is_cancelled
                ? "CANCELLED"
                : data?.is_shown
                ? "SHOWN"
                : "NOT SHOWN"}
            </span>
            <i
              className={`ms-auto text-info fe fe-${open ? "minus" : "plus"}`}
            ></i>
          </Col>
        </Row>
        <Collapse in={open} className="spin-detail-collapse">
          <div>
            {!isPublic && <GeneralInfoBlock data={data} />}
            {!isPublic && <RequestBlock data={data} />}
            {!isPublic && <ResultBlock data={data} />}
            {isPublic && (
              <ResultTable
                gameName={data?.game}
                data={data}
                isPublic={isPublic}
              />
            )}
          </div>
        </Collapse>
      </Card.Body>
    </Card>
  );
};
