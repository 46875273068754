import React from "react";

export const DemoContext = React.createContext(null);

export const useDemo = () => {
    const context = React.useContext(DemoContext);

    if (context === undefined) {
        throw new Error(
            "useDemo can only be used inside DemoContextProvider"
        );
    }

    return context;
};

export const DemoContextProvider = ({ children }) => {
    const [is_demo, setDemo] = React.useState(
        localStorage.getItem("demo") === "true"
    );

    return (
        <DemoContext.Provider
            value={{
                is_demo,
                setDemo,
            }}
        >
            {children}
        </DemoContext.Provider>
    );
};
