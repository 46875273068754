import httpClient from "./httpClient";

export const getWagerSets = async ({ queryKey }) => {
    const params = new URLSearchParams(queryKey[1]);
    const { data } = await httpClient.get(`/wager_set?${params.toString()}`);
    if (data) {
        return data.data;
    }
};

export const createWagerSet = async formData => {
    const { data } = await httpClient.post("/wager_set", { ...formData });
    if (data) {
        return data.data;
    }
};

export const updateWagerSet = async formData => {
    const { data } = await httpClient.put(`/wager_set/${formData?.id}`, { ...formData });
    if (data) {
        return data.data;
    }
};

export const deleteWagerSet = async id => {
    const { data } = await httpClient.delete(`/wager_set/${id}`);
    if (data) {
        return data.data;
    }
}