import { Iframe } from "./Iframe";
import React, { Fragment, useEffect, useState } from "react";
import { getHistory, putHistory } from "../api/history";

interface Props {
    url: string;
    session: string;
}

interface History {
    url: string;
    date: string;
}

const URLControl = (props: Props) => {
    const sessionURL = `https://${props.session}`;
    const [history, setHistory] = useState<History[]>([]);
    const [lastSavedLink, setLastSavedLink] = useState("");

    const updateHistory = () => {
        if (props.session !== lastSavedLink) {
        // if (props.url !== lastSavedLink) {
            const date = new Date().toLocaleString();
            saveLinkToServer(sessionURL, date);
            // saveLinkToServer(props.url, date);

            const newHistory = [...history];

            newHistory.unshift({
                url: sessionURL,
                // url: props.url,
                date,
            });

            setLastSavedLink(sessionURL);
            // setLastSavedLink(props.url);
            setHistory(newHistory);
        }
    };


    if (props.url === "" || props.session === "") {
        return null;
    }

    return (
        <Fragment>
            <div className="link-container">
                <a className="link-container__link" target="_blank" rel="noreferrer" href={sessionURL} onClick={updateHistory}>
                {/* <a className="link-container__link" target="_blank" rel="noreferrer" href={props.url} onClick={updateHistory}> */}
                    {sessionURL}
                    {/* <hr></hr>
                    {props.url} */}
                </a>
            </div>
            <div className='my-4'>
                <Iframe stateURL={sessionURL} updateHistory={updateHistory} />
                {/* <Iframe stateURL={props.url} updateHistory={updateHistory} /> */}
            </div>
        </Fragment>
    );
};

export default URLControl;

const saveLinkToServer = (url: string, date: string) => {
    putHistory(
        JSON.stringify({
            url,
            date,
        }),
    );
};
