import React, {useState} from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {addCurrency, getOrganizationPair} from "../../../../services";
import { toast, Slide } from "react-toastify";
import {Select} from "antd";

export const AddCurrency = ({ setShowModal, integrators, providers, setError, setHasError}) => {
    const queryClient = useQueryClient();
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);

    const [pair, setPair] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            multiplier: 0,
            organization_pair_id: "",
            title: "",
            synonym: "",
        },
    });

    const {refetch: refetchPair} = useQuery(
        [
            "organizations",
            {
                integrator_id: selectedOrganization,
                provider_id: selectedProvider
            },
        ],
        getOrganizationPair,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: selectedOrganization !== null && selectedProvider !== null,
            onSuccess: data => {
                console.log(data.id)
                setPair(data.id);
            },
            onError: (error) => {
                setError(error.response?.data?.data || "An unknown error occurred");
                if (error.response?.data?.data === "not found") {
                    setHasError(false);
                    setPair("");
                } else {
                    setHasError(true);
                }
            },
        }
    );

    const { mutate } = useMutation(addCurrency, {
        onSuccess: () => {
            queryClient.invalidateQueries("get-currencies");
            toastAlert("success");
            setShowModal(false);
        },
        onError: () => {
            toastAlert("error");
            setShowModal(false);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Created currency successfully`
                    : `Couldn't create currency`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        if (!formData.synonym.trim()) {
            formData.synonym = formData.title;
        }
        setLoading(true);
        mutate({ ...formData,
            multiplier: Number(formData?.multiplier),
            organization_pair_id: pair,
        });
    });

    return (
        <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
            <Modal.Header>
                <h6 className="modal-title">Add Currency</h6>
                <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form
                    className={`needs-validation${isSubmitted ? "" : ""}`}
                    onSubmit={e => {
                        e.preventDefault();
                        setIsSubmitted(true);
                        onSubmit(e);
                    }}
                >
                    <Form.Group className="form-group">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            placeholder="Enter currency title..."
                            type="text"
                            {...register("title", {
                                required: true,
                                validate: value => value.trim() !== "",
                            })}
                            isInvalid={isSubmitted && errors?.title}
                            isValid={isSubmitted && !errors?.title}
                        />
                        {errors?.title && (
                            <div className="invalid-feedback">Required</div>
                        )}
                        <Form.Label>Synonym</Form.Label>
                        <Form.Control
                            placeholder="Enter currency synonym..."
                            type="text"
                            {...register("synonym", {
                                required: false,
                                // validate: value => /^[A-Za-z]+$/.test(value),
                              })}
                            isInvalid={isSubmitted && errors?.synonym}
                            isValid={isSubmitted && !errors?.synonym}
                        />
                        {errors?.synonym && (
                            <div className="invalid-feedback">Only letters are allowed</div>
                        )}
                        <Form.Label>Multiplier</Form.Label>
                        <Form.Control
                            placeholder="Enter multiplier"
                            type="number"
                            {...register("multiplier", {
                                required: true,
                                validate: value => value > 0,
                            })}
                            isInvalid={isSubmitted && errors?.multiplier}
                            isValid={isSubmitted && !errors?.multiplier}
                        />
                        {errors?.multiplier && (
                            <div className="invalid-feedback">Required</div>
                        )}
                        <Form.Label>Integrator</Form.Label>
                        <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select integrator..."}
                            value={selectedOrganization}
                            popupClassName={'custom-dropdown'}
                            dropdownStyle={{
                                zIndex: '9999999999',
                            }}
                            onChange={(org) => setSelectedOrganization(org)}
                            options={
                                integrators?.map(item => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || []
                            }
                        />

                        <Form.Label>Provider</Form.Label>
                        <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select provider..."}
                            value={selectedProvider}
                            popupClassName={'custom-dropdown'}
                            dropdownStyle={{
                                zIndex: '9999999999',
                            }}
                            onChange={(org) => setSelectedProvider(org)}
                            options={
                                providers?.map(item => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || []
                            }
                        />
                        {errors?.organization_pair_id && (
                            <div className="invalid-feedback">Required</div>
                        )}
                    </Form.Group>
                    <Button
                        disabled={loading}
                        variant=""
                        type="submit"
                        style={{ minHeight: 51 }}
                        className="btn btn-primary btn-block tx-16"
                    >
                        {loading ? (
                            <Spinner
                                animation="border"
                                variant="primary"
                                className="spinner-border me-2 text-light"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        ) : (
                            "Update"
                        )}
                    </Button>
                </Form>

            </Modal.Body>
        </Modal>
    );
};
