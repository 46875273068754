import React, {useState, useEffect} from 'react';
import moment from "moment";
import {Card, Col, Row, Spinner} from "react-bootstrap";
import {useQuery} from "react-query";
import {getFiles} from "../../services";
import {useProfile} from "../../contexts";
import {getIdToken} from "../../services/httpClient";
import {useNotificationContext} from "../../contexts/notificationContext";
import {useDownloadContext} from "../../contexts/downloadContext";

const Downloads = () => {
    const [files, setFiles] = useState();

    const { downloadUrl } = useDownloadContext();
    const { organizationId } = useProfile();
    const { markUserVisitedDownloads } = useNotificationContext()

    const { isLoading, refetch } = useQuery(
        ["get-files", { id: organizationId }],
        getFiles,
        {
            refetchOnWindowFocus: false,
            onSuccess: data => {
                const filteredFiles = data.filter(file => file.status !== 'error');
                setFiles(filteredFiles);
            },
        }
    );

    const handleDownload = (id) => {
        const authToken = getIdToken();
        const fileUrl = `${downloadUrl}${id}?token=${authToken}`;
        window.open(fileUrl, '_blank');
    };

    useEffect(() => {
        refetch();
    }, [])

    useEffect(() => {
        markUserVisitedDownloads();
    }, []);

    return (
        <>
        <div className="breadcrumb-header justify-content-between">
            <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Downloads
          </span>
            </div>
        </div>
            {files?.length === 0 && (
                <div
                    className="d-flex align-items-center justify-content-center text-muted tx-18"
                    style={{ height: "50vh" }}
                >
                    {isLoading ? <Spinner variant="primary" /> : " No data do display"}
                </div>
            )}
            <div className="files-container">
                {files?.map((file, index) => {
                  return  <div key={index}>
                        <Card className="file-card">
                            <Card.Body className="file-card-body py-2">
                                <Row>
                                    <Col
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        xs={3}
                                        className="bd-e py-1 tx-bold tx-16 d-flex align-items-center"
                                    >
                                        {file?.name}
                                    </Col>
                                    <Col xl={3} lg={3} md={3} xs={3} className="bd-e py-1">
                                        <div className="d-flex flex-column">
                                            <span className="d-block text-muted">Created at</span>
                                            <span className="d-block tx-bold tx-15">
                                        {moment(file?.createdAt).format("DD-MM-YYYY HH:mm UTC")}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        xs={3}
                                        className="bd-e py-1 tx-bold tx-16 d-flex align-items-center"
                                    >
                                        <div className="d-flex flex-column">
                                            <span className="d-block text-muted">Status</span>
                                            <span className="d-block tx-bold tx-15">
                                        {file?.status}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        xs={3}
                                        className="py-1 d-flex align-items-center justify-content-center"
                                    >
                                        <button
                                            id="downloadButton"
                                            className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 px-2"
                                            onClick={() => handleDownload(file.id)}
                                            disabled={file.status === "inProgress"}
                                        >
                                            Download <i className="fe fe-arrow-down ms-1" />
                                        </button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                })}
            </div>
        </>
    );
};

export default Downloads;