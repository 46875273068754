import React, {useEffect, useState} from "react";
import moment from "moment";
import {Breadcrumb, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import {deleteUser, getOrganizations, getUsers} from "../../services";
import { Table } from "../../components/Tables";
import { useProfile } from "../../contexts";
import { UsersModal } from "./components";
import { toast, Slide } from "react-toastify";
import { DeleteModal } from "./components/deleteModal";
import {EditUserModal} from "./components/editUserModal";
import {EditUserOrganizationsModal} from "./components/editUserOrgsModal";

const Users = () => {
  const { organizationId, profileData } = useProfile();

  const [data, setData] = useState();
  const [limit] = useState(100);
  const [page] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showEditOrgsModal, setShowEditOrgsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [userOrgs, setUserOrgs] = useState([]);
  const [orgsList, setOrgsList] = useState(null);
  const [addOrgs, setAddOrgs] = useState([])

  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);

  const { isLoading, isRefetching, refetch } = useQuery(
    [
      "users-request",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getUsers,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: data => {
        setData(data);
      },
    }
  );

  const { refetch: refetchOrgs } = useQuery(
      [
        "organizations",
        {
          limit,
          offset: (page - 1) * limit,
        },
      ],
      getOrganizations,
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: false,
        onSuccess: data => {
          setOrgsList(data);
        },
      }
  );

  const { mutate: deleteAccount } = useMutation(deleteUser, {
    onSuccess: () => {
      setShowDeleteModal(false);
      toastAlert("success");
      refetch();
      selectedData && setSelectedData(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setShowDeleteModal(false);
      selectedData && setSelectedData(null);
    },
  });

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Account was modified successfully`
            : `Error: ${error}`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-info" : "bg-danger",
        type,
      }
    );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Full Name",
      render: data => (
          <div>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="tooltip tooltip-primary1">Edit user data</Tooltip>}
            >
              <span
                  onClick={() => {handleItemClick(data)}}
                  className="text-primary text-underline cursor-pointer d-block text-truncate mx-auto"
                  style={{
                    textDecoration: "underline",
                    maxWidth: 120,
                  }}
              >
                {data.first_name + " " + data.last_name}
              </span>
            </OverlayTrigger>
          </div>
      ),
    },
    { className: "text-center", key: "email", name: "Email" },
    {
      className: "text-center",
      key: "roles",
      name: "Roles",
      render: data => {
        return (
          <>
            {data?.map((role, key) => (
              <span key={key} className="badge bg-primary me-1 my-2 tx-12">
                {role?.name}
              </span>
            ))}
          </>
        );
      },
    },
    {
      className: "text-center",
      key: "operators",
      name: "Operators",
      render: data => {
        return (
            <>
              {data?.map((operators, key) => (
                  <span key={key} className="badge bg-primary me-1 my-2 tx-12">
                {operators?.name}
              </span>
              ))}
            </>
        );
      },
    },
    {
      className: "text-center",
      key: "organizations",
      name: "Organizations",
      render: data => {
        return (
            <>
              {data?.map((organization, key) => (
                  <span key={key} className="badge bg-primary me-1 my-2 tx-12">
                {organization?.name}
              </span>
              ))}
            </>
        );
      },
    },
    {
      className: "text-center",
      key: "created_at",
      width: 120,
      name: "Created at",
      render: data => (
        <>
          {!data ? (
            "-"
          ) : (
            <>
              <span className="d-block">
                {moment(data).format("DD-MM-YYYY")}
              </span>
              <span className="d-block">
                {moment(data).format("HH:mm UTC")}
              </span>
            </>
          )}
        </>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Actions",
      width: 60,
      render: data =>
        data?.roles?.[0]?.type === "admin" ||
        data?.roles?.[0]?.type === "root" ? null : (
          <div className="d-flex align-items-center justify-content-center">
            <button
                className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 mg-r-10"
                onClick={() => handleEditButtonClick(data?.id)}
                disabled={data?.id === profileData?.id}
            >
              <i className="fe fe-edit-3" />
            </button>
            <button
              className={`btn ${
                data?.id === profileData?.id
                  ? "btn-dark-light"
                  : "btn-outline-danger"
              } d-flex align-items-center justify-content-center p-1`}
              disabled={data?.id === profileData?.id}
              onClick={() => {
                if (data?.id === profileData?.id) {
                  return;
                }
                setSelectedData(data);
                setShowDeleteModal(true);
              }}
            >
              <i className="fe fe-trash" />
            </button>
          </div>
        ),
    },
  ];

  const handleItemClick = (propertyValue) => {
    const selectedItem = data?.find(item => item.id === propertyValue.id);
    setSelectedData(selectedItem);
    console.log(propertyValue)
    setShowEditUserModal(true);
  };

  const handleEditButtonClick = (propertyValue) => {
    const selectedItem = data?.find(item => item.id === propertyValue);
    setSelectedData(selectedItem);
    console.log(selectedItem)
    const selectedUserOrganizations = selectedItem?.organizations ? selectedItem?.organizations?.map(organization => ({
      label: organization.name,
      value: organization.id
    })) : [];
    console.log(selectedUserOrganizations)
    const modifiedList = orgsList?.filter(organization => !selectedUserOrganizations.some(userRoles => userRoles.value === organization.id))
        .map(organization => ({ label: organization.name, value: organization.id }));
    console.log(modifiedList)
    setAddOrgs(modifiedList);
    setUserOrgs(selectedUserOrganizations);
    setShowEditOrgsModal(true);
  };

  useEffect(() => {
    refetch();
    refetchOrgs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
    refetchOrgs();
  }, [organizationId]);

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  return (
    <>
      {showModal && (
        <UsersModal setShowModal={setShowModal} onSettled={refetch} />
      )}
      {showEditUserModal && (
          <EditUserModal
              setShowModal={setShowEditUserModal}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              refetch={refetch}
          />
      )}
      {showEditOrgsModal && (
          <EditUserOrganizationsModal
              error={error}
              hasError={hasError}
              setError={setError}
              setHasError={setHasError}
              userOrgs={userOrgs}
              orgs={addOrgs}
              setShowModal={setShowEditOrgsModal}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              refetch={refetch}
          />
      )}
      {showDeleteModal && (
        <DeleteModal
          title="User"
          onAccept={() => deleteAccount(selectedData?.id)}
          setShowModal={() => {
            setShowDeleteModal();
            setSelectedData(null);
          }}
          additionalInfo={`Fullname: ${selectedData?.first_name} ${selectedData?.last_name}`}
        />
      )}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Accounts</span>
        </div>
        <div className="right-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => setShowModal(true)}>
              <span>Create</span>
              <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card>
        <Card.Body className="pt-4 example1-table">
          <Table
            loading={isLoading || isRefetching}
            data={data ? data : []}
            columns={columns}
            total={data ? data?.length : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Users;
