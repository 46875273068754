import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { editIntegratedGame, editWagerSetGame } from "../../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";
import { useQuery } from "react-query";
import { getCurrenciesByPair } from "../../../../services";

export const EditWagerSetModal = ({
  setShowModal,
  selectedEditItemWagerSet,
  refetchGames,
  pairCurrency,
  selectedOrganization,
  refetchOrganizationWager,
  wagerSets,
}) => {
  const { game_id, wager_set, organization_id, short_link, currency } =
    selectedEditItemWagerSet;
  //   const { available_rtp, available_volatility, available_wager_sets } = Game;

  //   const wagersArray = available_wager_sets?.map((wager) => ({
  //     label: wager.name,
  //     value: wager.id,
  //   }));

  const [selectedOrganizationWagerSet, setSelectedOrganizationWagerSet] =
    useState([]);

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [shortLink, setShortLink] = useState(short_link);
  const [selectedWagerSet, setSelectedWagerSet] = useState({
    label: wager_set?.name || "",
    value: wager_set?.id || "",
  });

  const {
    refetch,
    isLoading,
    data: multipliers,
  } = useQuery(
    ["get-currencies", { organization_pair_id: pairCurrency }],
    getCurrenciesByPair,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: pairCurrency !== null,
      onSuccess: (data) => {
        setSelectedOrganizationWagerSet(data);
      },
      onError: () => {
        setSelectedOrganizationWagerSet(null);
      },
    }
  );

  const { handleSubmit } = useForm({
    defaultValues: {
      game_id: "",
      rtp: "",
      volatility: "",
      wager_set_id: "",
      organization_id: "",
      short_link: "",
    },
  });

  const { mutate } = useMutation(
    (formData) =>
      editWagerSetGame({ queryKey: [{ selectedOrganization }] }, formData),
    {
      onSuccess: () => {
        toastAlert("success");
        refetchGames();
        refetchOrganizationWager();
        setShowModal(false);
      },
      onError: (error) => {
        toastAlert("error");
        setShowModal(false);
        refetch();
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Integration was modified successfully`
          : `Error: Oops, something went wrong`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    console.log(selectedWagerSet, "selectedWagerSet");
    formData.game_id = game_id;
    formData.currency = currency;
    formData.new_currency = selectedCurrency;
    // formData.new_wager_set_id =
    //   selectedWagerSet.value || "00000000-0000-0000-0000-000000000000";
    formData.wager_set_id = wager_set.id;
    formData.short_link = shortLink;
    setLoading(true);
    mutate(formData);
  });

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Edit game integration</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={(e) => {
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label>New currency</Form.Label>
            <Select
              className="form-control"
              optionFilterProp="children"
              placeholder={"Select currency..."}
              value={selectedCurrency}
              onChange={(currency) => {
                setSelectedCurrency(currency);
              }}
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              options={
                selectedOrganizationWagerSet?.map((item) => {
                  return {
                    value: item?.synonym,
                    label: item?.title,
                  };
                }) || []
              }
            />
            {/* <Form.Label>New wager set</Form.Label>
            <Select
              className="form-control"
              optionFilterProp="children"
              placeholder={"Select wager set..."}
              value={selectedWagerSet.label}
              onChange={(_, wager) =>
                setSelectedWagerSet({
                  label: wager.label,
                  value: wager.value,
                })
              }
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              options={
                wagerSets?.map((item) => {
                  return {
                    value: item.value,
                    label: item.label,
                  };
                }) || []
              }
            /> */}
            <Form.Label className="custom-control custom-checkbox custom-control-md mg-t-20">
              <Form.Control
                type="checkbox"
                className="custom-control-input"
                name="example-checkbox2"
                value="option2"
                checked={shortLink}
                onChange={() => setShortLink(!shortLink)}
              />
              <span className="mg-r-30 custom-control-label custom-control-label-md">
                Short Link
              </span>
            </Form.Label>
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? <span className="text-white">Loading...</span> : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
