import React, { useState } from "react";
import { IframeControl } from "./IframeControl";

interface Props {
    stateURL: string;
    updateHistory: () => void;
}

const Iframe = (props: Props) => {
    const { stateURL, updateHistory } = props;

    const [isVisible, setIsVisible] = useState(false);
    const [currentLink, setCurrentLink] = useState(stateURL);
    const [counter, setCounter] = useState(0);

    const toggleVisible = () => {
        setIsVisible(!isVisible);
        updateIframe();
        updateHistory();
    };

    const inc = () => {
        setCounter(counter + 1);
    };

    const updateIframe = () => {
        inc();
        setCurrentLink(stateURL);
    };

    return (
        <div>
            <IframeControl isVisibleIframe={isVisible} toggle={toggleVisible} reload={updateIframe} />
            {isVisible && (
                <iframe key={counter} style={{ border: "none", minHeight: "640px" }} src={currentLink} width="100%" height="100%">
                    iframe is not supported!
                </iframe>
            )}
        </div>
    );
};

export { Iframe };
