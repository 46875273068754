import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {updateWagerSet} from "../../../../services";
import {Slide, toast} from "react-toastify";

const ConfigModal = ({ selectedItem, configModal, closeModal, onSettled }) => {

    const [wagerMeanings, setWagerMeanings] = useState([]);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const [minWager, setMinWager] = useState(0);
    const [maxWager, setMaxWager] = useState(0);
    const [defaultWagerError, setDefaultWagerError] = useState("");

    const {
        getValues,
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm();

    const {mutate} = useMutation(updateWagerSet, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setLoading(false);
            closeModal("configModal");
        },
        onError: (error) => {
            setError(error.response.data.data);
            setHasError(true);
            closeModal("configModal");
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Wager set was updated successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        formData.wager_levels = wagerMeanings;
        formData.wager_levels = formData.wager_levels.map(value => parseInt(value));
        formData.default_wager = parseInt(formData.default_wager);
        formData.id = selectedItem?.id
        formData.is_active = selectedItem?.is_active
        if(!formData.wager_levels.includes(formData.default_wager)) {
            setDefaultWagerError("Invalid default wager");
            return;
        }
        setLoading(true);
        console.log(formData)
        mutate(formData);
        reset();
        setMaxWager(0);
        setMinWager(0);
        setDefaultWagerError('')
    });

    const handleValuesChange = e => {
        const values = e.target.value.split(",").map(val => val.trim());
        setWagerMeanings(values);

        const parsedValues = values.map((value) => parseInt(value))
        setMinWager(Math.min(...parsedValues));
        setMaxWager(Math.max(...parsedValues));
    };

    useEffect(() => {
        if (wagerMeanings.length > 0) {
            setMinWager(Math.min(...wagerMeanings));
            setMaxWager(Math.max(...wagerMeanings));
        } else {
            setMinWager(null);
            setMaxWager(null);
        }
    }, [wagerMeanings]);

    useEffect(() => {
        if (selectedItem?.wager_levels && selectedItem?.wager_levels.length > 0) {
            const parsedValues = selectedItem.wager_levels.map(value => parseInt(value));
            setMinWager(Math.min(...parsedValues));
            setMaxWager(Math.max(...parsedValues));
        } else {
            setMinWager(0);
            setMaxWager(0);
        }
    }, [selectedItem]);

    useEffect(() => {
        if (selectedItem) {
            reset({
                name: selectedItem.name,
                default_wager: selectedItem.default_wager,
                wager_levels: selectedItem.wager_levels.join(', '),
            });
        }
    }, [selectedItem, reset]);

    useEffect(() => {
        if (hasError) {
            toastAlert("error");
            setHasError(false);
        }
    }, [error, hasError]);

    return (
        <Modal
            size="lg"
            show={configModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Wager sets configuration</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        closeModal("configModal");
                        reset();
                        setDefaultWagerError('')
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Form
                        className={`wd-100p needs-validation${isSubmitted ? "" : ""}`}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter name"
                                        defaultValue={selectedItem?.name}
                                        type="text"
                                        {...register("name", {
                                            required: true,
                                            validate: value => value?.trim() !== "",
                                        })}
                                        isInvalid={!!errors?.name}
                                        isValid={isSubmitted && !errors?.name}
                                    />
                                    {errors?.name && (
                                        <Form.Control.Feedback type="invalid">
                                            Name is required
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Default wager</Form.Label>
                                    <Form.Control
                                        placeholder="Enter default wager"
                                        defaultValue={selectedItem?.default_wager}
                                        type="text"
                                        {...register("default_wager", {
                                            required: true,
                                            validate: (value) => {
                                                const wagerLevels = getValues("wager_levels") || [];
                                                return wagerLevels.includes(parseInt(value)) || "Invalid default wager";
                                            }
                                        })}
                                        isInvalid={!!errors?.default_wager || !!defaultWagerError}
                                        isValid={isSubmitted && !errors?.default_wager}
                                    />
                                    {errors?.default_wager && (
                                        <Form.Control.Feedback type="invalid">
                                            Invalid default wager
                                        </Form.Control.Feedback>
                                    )}
                                    {defaultWagerError && (
                                        <Form.Control.Feedback type="invalid">
                                            Wager is out of wager levels
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-group">
                                    <Form.Label className="">Values</Form.Label>
                                    <Form.Control
                                        placeholder="Enter wager values"
                                        defaultValue={selectedItem?.wager_levels}
                                        type="text"
                                        onChange={handleValuesChange}
                                        onInput={handleValuesChange}
                                        {...register("wager_levels", {
                                            required: true,
                                            validate: value => {
                                                const values = value?.trim().split(",");
                                                const isValid = values.every(val => !isNaN(val.trim()));
                                                if (isValid) {
                                                    setWagerMeanings(values.map(val => val.trim()));
                                                }
                                                return isValid;
                                            },
                                        })}
                                        isInvalid={!!errors?.wager_levels}
                                        isValid={isSubmitted && !errors?.wager_levels}
                                    />
                                    {errors?.wager_levels && (
                                        <div className="invalid-feedback">Wager levels are invalid</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Min wager</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Min wager"
                                        type="text"
                                        value={minWager !== null ? minWager : 0}
                                    />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Max wager</Form.Label>
                                    <Form.Control
                                        disabled
                                        placeholder="Max wager"
                                        type="text"
                                        value={maxWager !== null ? maxWager : 0}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Modal.Footer>
                            <Button
                                disabled={loading}
                                variant="primary"
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                        className="spinner-border me-2 text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                ) : (
                                    "Save"
                                )}
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    closeModal("configModal");
                                    reset();
                                    setDefaultWagerError('')
                                }}
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ConfigModal;