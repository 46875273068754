import React, {useEffect, useState} from "react";
import {Breadcrumb, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import {deleteOrganization, getOrganizations } from "../../services";
import { Table } from "../../components/Tables";
import { useProfile } from "../../contexts";
import { OrganizationModal } from "./components/createOrgModal";
import { toast, Slide } from "react-toastify";
import { DeleteModal } from "../Users-Roles/components/deleteModal";
import {copyToClipboard} from "../../utilities";
import {EditOrganizationModal} from "./components/editOrgModal";

const Organizations = () => {
    const { organizationId, profileData } = useProfile();

    const [data, setData] = React.useState();
    const [limit] = React.useState(100);
    const [page] = React.useState(1);

    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [selectedData, setSelectedData] = React.useState(null);

    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const { isLoading, isRefetching, refetch } = useQuery(
        [
            "organizations",
            {
                limit,
                offset: (page - 1) * limit,
            },
        ],
        getOrganizations,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: false,
            onSuccess: data => {
                setData(data);
            },
        }
    );

    const { mutate: deleteOrg } = useMutation(deleteOrganization, {
        onSuccess: () => {
            setShowDeleteModal(false);
            toastAlert("success");
            refetch();
            selectedData && setSelectedData(null);
        },
        onError: (error) => {
            setError(error.response.data.data);
            setHasError(true);
            setShowDeleteModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Organization was deleted successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: type === "success" ? 2000 : 5000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const copyToastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Copied successfully`
                    : `Error: couldn't copy`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: type === "success" ? 2000 : 5000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const handleItemClick = (propertyValue) => {
        const selectedItem = data?.find(item => item.id === propertyValue);
        setSelectedData(selectedItem);
        setShowEditModal(true);
    };

    const columns = [
        {
            className: "text-center",
            key: "id",
            name: "ID",
            highlight: true,
            render: data => (
                <div className="d-flex align-items-center justify-content-center">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip className="tooltip tooltip-primary1">Edit organization</Tooltip>}
                    >
            <span
                onClick={() => handleItemClick(data)}
                className="d-block text-truncate text-primary text-underline cursor-pointer me-2"
                style={{
                    maxWidth: 140,
                    textDecoration: "underline",
                }}
            >
              {data}
            </span>
                    </OverlayTrigger>
                    <button
                        className="btn tx-10 p-1"
                        onClick={() => copyToClipboard(data, copyToastAlert)}
                    >
                        <i className='mdi mdi-content-copy'></i>
                    </button>
                </div>
            ),
        },
        {
            className: "text-center",
            key: "api_key",
            name: "API key",
            render: data => (
                <div className="d-flex align-items-center justify-content-center">
            <span
                className="d-block text-truncate text-underline me-2"
                style={{
                    maxWidth: 120,
                }}
            >
              {data}
            </span>
                    <button
                        className="btn tx-10 p-1"
                        onClick={() => copyToClipboard(data, copyToastAlert)}
                    >
                        <i className='mdi mdi-content-copy'></i>
                    </button>
                </div>
            ),
        },
        {
            className: "text-center",
            key: "name",
            name: "Name",
            width: 160,
        },
        {
            className: "text-center",
            key: "status",
            name: "Status",
        },
        {
            className: "text-center",
            key: "type",
            name: "Type",
        },
        {
            className: "text-center",
            key: "all",
            name: "Manage",
            width: 60,
            render: data => (
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            className={`btn btn-outline-danger d-flex align-items-center justify-content-center p-1`}
                            onClick={() => {
                                if (data?.id === profileData?.id) {
                                    return;
                                }
                                setSelectedData(data);
                                setShowDeleteModal(true);
                            }}
                        >
                            <i className="fe fe-trash" />
                        </button>
                    </div>
                ),
        },
    ];

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        refetch();
    }, [organizationId]);

    useEffect(() => {
        if (hasError) {
            toastAlert("error");
            setHasError(false);
        }
    }, [error, hasError]);

    return (
        <>
            {showModal && (
                <OrganizationModal setShowModal={setShowModal} onSettled={refetch} />
            )}
            {showDeleteModal && (
                <DeleteModal
                    title="Organization"
                    onAccept={() => deleteOrg(selectedData?.id)}
                    setShowModal={() => {
                        setShowDeleteModal();
                        setSelectedData(null);
                    }}
                    additionalInfo={selectedData?.name}
                />
            )}
            {showEditModal && (
                <EditOrganizationModal
                    setShowModal={setShowEditModal}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    refetch={refetch}
                />
            )}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">Organizations</span>
                </div>
                <div className="right-content">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => setShowModal(true)}>
                            <span>Create</span>
                            <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <Card>
                <Card.Body className="pt-4 example1-table">
                    <Table
                        loading={isLoading || isRefetching}
                        data={data ? data : []}
                        columns={columns}
                        total={data ? data?.length : 0}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

export default Organizations;
