export const totalPaylines = (gameName, data) => {
  let payline;
  if (gameName)
    switch (gameName) {
      case "stones-of-magic":
      case "secret-totems":
      case "olympus-quest":
        payline = data?.spin?.spins?.[0]?.stages
          ?.map((item) => item?.payouts)
          ?.reduce((accumulator, currentValue) => {
            if (currentValue?.values?.length) {
              return accumulator + currentValue?.values?.length;
            }
            return accumulator;
          }, 0);
        break;

      case "cyber-town":
      case "precious-bugs":
      case "toreadors-fortune":
        if (data?.spin?.bonus_reels?.spins_results?.length > 0) {
          payline = data?.spin?.bonus_reels?.spins_results
            ?.filter((item) => item?.payouts?.spin_payouts)
            ?.reduce((accumulator, currentValue) => {
              return accumulator + currentValue?.payouts?.spin_payouts?.length;
            }, 0);
        } else {
          payline = data?.bonus_award || data?.base_award ? 1 : 0;
        }
        break;

      case gameName.includes("book-of-jones"):
        payline = data?.spin?.payouts?.spin_payouts?.length;
        break;

      default:
        payline = "-";
        break;
    }

  return payline;
};
