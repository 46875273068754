import { CONFIG } from "../config";
import { IGame } from "../components/Lobby";

const getGames = (
  onSuccess: (games: IGame[]) => void,
  onFailure: (reason: any) => void
): void => {
  const url = new URL("https://" + window.location.host + "/api/game");
  const accessToken = localStorage.getItem("access_token");
  const headers: HeadersInit = {};

  if (accessToken !== null) {
    headers["X-Auth"] = accessToken;
  }

  fetch(url.toString(), {
    method: "GET",
    headers: headers,
  })
    .then((body) => body.json())
    .then((result) => onSuccess(result.data))
    .catch(onFailure);
};

const getIntegrators = (
  id: string,
  onSuccess: (integrators: []) => void,
  onFailure: (reason: any) => void
): void => {
  const url = new URL(
    "https://" + window.location.host + `/api/organizations/${id}/integrators`
  );
  const accessToken = localStorage.getItem("access_token");
  const headers: HeadersInit = {};

  if (accessToken !== null) {
    headers["X-Auth"] = accessToken;
  }

  fetch(url.toString(), {
    method: "GET",
    headers: headers,
  })
    .then((body) => body.json())
    .then((result) => onSuccess(result.data))
    .catch(onFailure);
};

const getCachedGameIndex = (): number => {
  const item = localStorage.getItem(CONFIG.local_storage_key);
  let index = -1;
  if (item) {
    index = parseInt(item);
  }

  return index;
};

export { getGames, getIntegrators, getCachedGameIndex };
