import React, {useEffect, useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { updateRole } from "../../../services";
import { toast, Slide } from "react-toastify";

export const EditRoleModal = ({ setShowModal, selectedData, setSelectedData, refetch }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { mutate } = useMutation(updateRole, {
        onSuccess: () => {
            toastAlert("success");
            setShowModal(false);
            selectedData && setSelectedData(null);
            refetch()
        },
        onError: (error) => {
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Role was updated successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        setLoading(true);
        formData.id = selectedData?.id;
        mutate(formData);
    });

    useEffect(() => {
        if (hasError) {
            toastAlert("error");
            setHasError(false);
        }
    }, [error, hasError]);

    return (
        <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
            <Modal.Header>
                <h6 className="modal-title">Edit Permission</h6>
                <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form
                    className={`needs-validation${isSubmitted ? "" : ""}`}
                    onSubmit={e => {
                        setIsSubmitted(true);
                        onSubmit(e);
                    }}
                >
                    <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>{" "}
                        <Form.Control
                            placeholder="Enter name"
                            type="text"
                            defaultValue={selectedData?.name}
                            {...register("name", {
                                required: true,
                                validate: value => value?.trim() !== "",
                            })}
                            isInvalid={isSubmitted && errors?.name}
                            isValid={isSubmitted && !errors?.name}
                        />
                        {errors?.name && <div className="invalid-feedback">Required</div>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Type</Form.Label>{" "}
                        <Form.Control
                            placeholder="Enter type"
                            type="text"
                            defaultValue={selectedData?.type}
                            {...register("type", {
                                required: true,
                                validate: value => value?.trim() !== "",
                            })}
                            isInvalid={isSubmitted && errors?.description}
                            isValid={isSubmitted && !errors?.description}
                        />
                        {errors?.description && <div className="invalid-feedback">Required</div>}
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label className="">Description</Form.Label>{" "}
                        <textarea
                            defaultValue={selectedData?.description}
                            className={`form-control${
                                !isSubmitted
                                    ? ""
                                    : errors?.description
                                        ? " is-invalid"
                                        : " is-valid"
                            }`}
                            placeholder="Enter description"
                            rows="5"
                            {...register("description", {
                                required: true,
                                validate: value => value?.trim() !== "",
                            })}
                        />
                        {errors?.action && <div className="invalid-feedback">Required</div>}
                    </Form.Group>
                    <Button
                        disabled={loading}
                        variant=""
                        type="submit"
                        style={{ minHeight: 51 }}
                        className="btn btn-primary btn-block tx-16"
                    >
                        {loading ? (
                            <span className="text-white">
                  Loading...
                </span>
                        ) : (
                            "Edit"
                        )}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
