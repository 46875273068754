import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

export const DeleteModal = ({
  setShowModal,
  title,
  onAccept,
  additionalInfo,
}) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      {loading && (
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 10,
            backgroundColor: "rgb(255 255 255 / 43%)",
          }}
          lg={12}
          as="li"
          className="w-100 h-100 d-flex align-items-center justify-content-center position-absolute"
        >
          <Spinner variant="warning" />
        </div>
      )}
      <Modal.Header>
        <h6 className="modal-title">{title}</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="tx-14 mb-3">
          <p className="mb-2">
            Do you want to delete this {title?.toLowerCase()}?
          </p>
          {additionalInfo ? (
            <p className="">
              <strong>{additionalInfo}</strong>
            </p>
          ) : (
            ""
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <button
              disabled={loading}
              className="btn btn-primary w-100"
              onClick={() => {
                setLoading(true);
                onAccept();
              }}
            >
              Submit
            </button>
          </div>
          <div className="col-6">
            <button
              disabled={loading}
              className="btn btn-danger w-100"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
