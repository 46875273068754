import React from 'react';
import { Button, Modal, Row } from "react-bootstrap";

export const DeletePairModal = ({ setDeleteModal, onSubmit }) => {

    return (
        <Modal
            size="sm"
            show
            onHide={() => setDeleteModal(false)}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        setDeleteModal(false);
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className='wager-sets-modal'>
                    <Row className="row-sm">
                        <div>
                            <h4 className='font-weight-bold text-center mg-45'>
                                Are you sure to remove this organization pair?
                            </h4>
                        </div>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button className='wd-45p' variant="primary" onClick={() => {
                    onSubmit();
                    setDeleteModal(false);
                }}>
                    Yes
                </Button>
                <Button className='wd-45p' variant="secondary" onClick={() => {
                    setDeleteModal(false);
                }}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};