export const copyToClipboard = async (text, cb) => {
  try {
    await navigator.clipboard.writeText(text);
    cb && cb("success");
  } catch (err) {
    console.error("Failed to copy: ", err);
    cb && cb("error");
  }
};

export const addComma = number => Number(number).toLocaleString();

export const validateEmail = email =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(email);

export const stringify = (obj, indent = 2) =>
  JSON.stringify(
    obj,
    (key, value) => {
      if (
        Array.isArray(value) &&
        !value.some(x => x && typeof x === "object")
      ) {
        return `\uE000${JSON.stringify(
          value.map(v =>
            typeof v === "string" ? v.replace(/"/g, "\uE001") : v
          )
        )}\uE000`;
      }
      return value;
    },
    indent
  ).replace(/"\uE000([^\uE000]+)\uE000"/g, match =>
    match
      .substr(2, match.length - 4)
      .replace(/\\"/g, '"')
      .replace(/\uE001/g, '\\"')
  );
