import React, { useState, useEffect } from "react";
import { getReportsByGame, getReportsByGameFile } from "../../../services";
import { useCurrency, useProfile } from "../../../contexts";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Filters from "./components/Filters";
import Tabs from "./components/Tabs";
import { BasicTable } from "./components/Table";
import { Col, Row, Card, Spinner } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { chartOptions } from "./utils";
import { useNotificationContext } from "../../../contexts/notificationContext";
import { useWebSocketContext } from "../../../contexts/websocketContext";
import { getIdToken } from "../../../services/httpClient";
import "./styles.scss";
import { useDemo } from "../../../contexts/demoContext";
import CustomFilterModal from "./components/CustomFilterModal";

ChartJS.register(...registerables);
ChartJS.defaults.color = "#eaecf3";

const ByGame = () => {
  const [data, setData] = useState();
  const [params, setParams] = useState({
    starting_from: "",
    ending_at: "",
    integrator: "",
    operator: "",
  });

  const { sourceUrl, setSourceUrl } = useWebSocketContext();
  const { resetDownloadsBadge } = useNotificationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { currency } = useCurrency();
  const { organizationId } = useProfile();
  const { is_demo } = useDemo();

  const [range, setRange] = useState("today");
  const [tab, setTab] = useState("users");
  const [activeRange, setActiveRange] = useState("today");
  const [activeTab, setActiveTab] = useState("users");
  const [openCustomFilter, setOpenCustomFilter] = useState(false);

  const [games, setGames] = useState();
  const [userCount, setUserCount] = useState();
  const [roundCount, setRoundCount] = useState();
  const [bets, setBets] = useState();
  const [wins, setWins] = useState();
  const [revenue, setRevenue] = useState();
  const [rtp, setRtp] = useState();
  const [freeRoundCount, setFreeRoundCount] = useState();
  const [freeRoundBets, setFreeRoundBets] = useState();
  const [freeRoundWins, setFreeRoundWins] = useState();
  const [freeRoundRevenue, setFreeRoundRevenue] = useState();
  const [roundPerUser, setRoundPerUser] = useState();
  const [betsPerUser, setBetsPerUser] = useState();
  const [revenuePerUser, setRevenuePerUser] = useState();

  const [renderData, setRenderData] = useState(userCount);

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );
  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const { isLoading, refetch, isRefetching } = useQuery(
    [
      "by_game-reports",
      {
        is_demo,
        currency,
        ...params,
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getReportsByGame,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        setData(data);
      },
    }
  );

  const { refetch: fetchByGameFile } = useQuery(
    [
      "by_game-file",
      {
        is_demo,
        currency,
        ...params,
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getReportsByGameFile,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        const newSourceUrl = `${sourceUrl}${data.id}?token=${getIdToken()}`;
        setSourceUrl(newSourceUrl);
      },
    }
  );

  const [chartData, setChartData] = useState({
    type: "bar",
    labels: games,
    datasets: [
      {
        barPercentage: 1,
        barThickness: 15,
        maxBarThickness: 20,
        minBarLength: 2,
        data: new Array(games?.length).fill(0),
        backgroundColor: "#38cab3",
      },
    ],
  });

  const modifiedData = data?.map((item) => {
    const { rtp, game } = item;

    return {
      ...item,
      rtp: rtp.toFixed(2),
      game: game,
    };
  });

  const getGames = () => {
    const gameNames = data?.map((data) => {
      return data.game;
    });
    setGames(Array.from(new Set(gameNames)));
  };
  const getGameUsers = () => {
    const gameUsers = data?.reduce((acc, dataItem) => {
      const { game, user_count } = dataItem;

      if (!acc[game]) {
        acc[game] = user_count;
      } else {
        acc[game] += user_count;
      }

      return acc;
    }, {});

    setUserCount(gameUsers);
  };
  const getGameRounds = () => {
    const gameRounds = data?.reduce((acc, dataItem) => {
      const { game, round_count } = dataItem;

      if (!acc[game]) {
        acc[game] = round_count;
      } else {
        acc[game] += round_count;
      }

      return acc;
    }, {});

    setRoundCount(gameRounds);
  };
  const getGameBets = () => {
    const gameBets = data?.reduce((acc, dataItem) => {
      const { game, wager } = dataItem;

      if (!acc[game]) {
        acc[game] = wager;
      } else {
        acc[game] += wager;
      }

      return acc;
    }, {});

    setBets(gameBets);
  };
  const getGameWins = () => {
    const gameWins = data?.reduce((acc, dataItem) => {
      const { game, award } = dataItem;

      if (!acc[game]) {
        acc[game] = award;
      } else {
        acc[game] += award;
      }

      return acc;
    }, {});

    setWins(gameWins);
  };
  const getGameRevenue = () => {
    const gameRevenue = data?.reduce((acc, dataItem) => {
      const { game, revenue } = dataItem;

      if (!acc[game]) {
        acc[game] = revenue;
      } else {
        acc[game] += revenue;
      }

      return acc;
    }, {});

    setRevenue(gameRevenue);
  };
  const getGameRtp = () => {
    const gameRtp = data?.reduce((acc, dataItem) => {
      const { game, rtp } = dataItem;

      if (!acc[game]) {
        acc[game] = rtp;
      } else {
        acc[game] += rtp;
      }

      return acc;
    }, {});

    setRtp(gameRtp);
  };
  const getFreeRounds = () => {
    const freeRounds = data?.reduce((acc, dataItem) => {
      const { game, pfr_round_count } = dataItem;

      if (!acc[game]) {
        acc[game] = pfr_round_count;
      } else {
        acc[game] += pfr_round_count;
      }

      return acc;
    }, {});

    setFreeRoundCount(freeRounds);
  };
  const getFreeRoundsBets = () => {
    const freeRoundsBets = data?.reduce((acc, dataItem) => {
      const { game, pfr_wager } = dataItem;

      if (!acc[game]) {
        acc[game] = pfr_wager;
      } else {
        acc[game] += pfr_wager;
      }

      return acc;
    }, {});

    setFreeRoundBets(freeRoundsBets);
  };
  const getFreeRoundsWins = () => {
    const freeRoundsWins = data?.reduce((acc, dataItem) => {
      const { game, pfr_award } = dataItem;

      if (!acc[game]) {
        acc[game] = pfr_award;
      } else {
        acc[game] += pfr_award;
      }

      return acc;
    }, {});

    setFreeRoundWins(freeRoundsWins);
  };
  const getFreeRoundsRevenue = () => {
    const freeRoundsRevenue = data?.reduce((acc, dataItem) => {
      const { game, pfr_revenue } = dataItem;

      if (!acc[game]) {
        acc[game] = pfr_revenue;
      } else {
        acc[game] += pfr_revenue;
      }

      return acc;
    }, {});

    setFreeRoundRevenue(freeRoundsRevenue);
  };
  const getRoundsPerUser = () => {
    const roundsPerUser = data?.reduce((acc, dataItem) => {
      const { game, round_per_user } = dataItem;

      if (!acc[game]) {
        acc[game] = round_per_user;
      } else {
        acc[game] += round_per_user;
      }

      return acc;
    }, {});

    setRoundPerUser(roundsPerUser);
  };
  const getBetsPerUser = () => {
    const betsPerUser = data?.reduce((acc, dataItem) => {
      const { game, wager_per_user } = dataItem;

      if (!acc[game]) {
        acc[game] = wager_per_user;
      } else {
        acc[game] += wager_per_user;
      }

      return acc;
    }, {});

    setBetsPerUser(betsPerUser);
  };
  const getRevenuePerUser = () => {
    const revenuePerUser = data?.reduce((acc, dataItem) => {
      const { game, revenue_per_user } = dataItem;

      if (!acc[game]) {
        acc[game] = revenue_per_user;
      } else {
        acc[game] += revenue_per_user;
      }

      return acc;
    }, {});

    setRevenuePerUser(revenuePerUser);
  };

  const handleSubmit = (range) => {
    const queryParams = new URLSearchParams(location.search);
    const formattedStartingFrom = params.starting_from
      ? params.starting_from
      : "";
    const formattedEndingAt = params.ending_at ? params.ending_at : "";
    navigate({
      pathname: "/reports/by-game/",
      search: `?${createSearchParams({
        ...params,
        starting_from: formattedStartingFrom,
        ending_at: formattedEndingAt,
        integrator: queryParams.get("integrator") || "",
        operator: queryParams.get("operator") || "",
      })}`,
    });
    setRange(range);
    setActiveRange(range);
  };

  useEffect(() => {
    getGames();
    getGameUsers();
    getGameRounds();
    getGameBets();
    getGameWins();
    getGameRevenue();
    getGameRtp();
    getFreeRounds();
    getFreeRoundsBets();
    getFreeRoundsWins();
    getFreeRoundsRevenue();
    getRoundsPerUser();
    getBetsPerUser();
    getRevenuePerUser();
  }, [data]);

  useEffect(() => {
    let dataToRender;
    switch (tab) {
      case "users":
        dataToRender = userCount;
        break;
      case "rounds":
        dataToRender = roundCount;
        break;
      case "bets":
        dataToRender = bets;
        break;
      case "wins":
        dataToRender = wins;
        break;
      case "revenue":
        dataToRender = revenue;
        break;
      case "rtp":
        dataToRender = rtp;
        break;
      case "freeRounds":
        dataToRender = freeRoundCount;
        break;
      case "freeRoundsBets":
        dataToRender = freeRoundBets;
        break;
      case "freeRoundsWins":
        dataToRender = freeRoundWins;
        break;
      case "freeRoundsRevenue":
        dataToRender = freeRoundRevenue;
        break;
      case "roundPerUser":
        dataToRender = roundPerUser;
        break;
      case "betsPerUser":
        dataToRender = betsPerUser;
        break;
      case "revenuePerUser":
        dataToRender = revenuePerUser;
        break;
      default:
        dataToRender = userCount;
        break;
    }
    setRenderData(dataToRender);
  }, [tab, userCount, is_demo, currency]);

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: renderData,
        },
      ],
    }));
  }, [renderData, params]);

  useEffect(() => {
    let startingFrom, endingAt, integrator, operator;
    const queryParams = new URLSearchParams(location.search);
    integrator = queryParams.get("integrator") || "";
    operator = queryParams.get("operator") || "";
    if (range === "today") {
      startingFrom = moment().format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD HH:mm:ssZ");
    } else if (range === "yesterday") {
      startingFrom = moment().subtract(1, "day").format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().subtract(1, "day").format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "last-week") {
      startingFrom = moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "last-month") {
      startingFrom = moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "3-months") {
      startingFrom = moment()
        .subtract(3, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "6-months") {
      startingFrom = moment()
        .subtract(6, "month")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "1-year") {
      startingFrom = moment()
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:01Z");
      endingAt = moment().format("YYYY-MM-DD 23:59:59Z");
    } else if (range === "all-time") {
      startingFrom = "";
      endingAt = "";
    }
    setParams({
      starting_from: startingFrom,
      ending_at: endingAt,
      integrator: integrator,
      operator: operator,
    });
  }, [range, location]);

  useEffect(() => {
    if (params.starting_from) {
      refetch();
    }
  }, [
    params,
    currency,
    organizationId,
    is_demo,
    refetch,
    selectedIntegrator,
    selectedOperator,
  ]);

  useEffect(() => {
    // refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">By game</span>
        </div>
      </div>
      <Row className="row-sm">
        <Col md={12}>
          <div className="mg-b-20">
            <Filters
              setRange={setRange}
              activeRange={activeRange}
              setActiveRange={setActiveRange}
              handleSubmit={handleSubmit}
              setOpenCustomFilter={setOpenCustomFilter}
            />
          </div>
          <Row>
            <Col md={8} className="solid-chart mg-l-80">
              <div className="chart-container">
                {renderData ? (
                  <Bar
                    data={chartData}
                    options={chartOptions}
                    height={100}
                    className="barchart"
                    id="chartBar1"
                  />
                ) : (
                  <Spinner variant="primary" className="" />
                )}
              </div>
            </Col>
            <div>
              <Tabs
                setTab={setTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </Row>
        </Col>
      </Row>
      <div className="d-flex align-items-end justify-content-end mg-t-20 mg-r-25">
        <button
          className="btn btn-primary mg-r-25"
          onClick={() => {
            fetchByGameFile();
            resetDownloadsBadge();
          }}
        >
          Export
        </button>
      </div>
      <Card className="mg-t-10 card custom-card">
        <Card.Body className="">
          <div className="table-responsive">
            <Row>
              <Col sm={12} className="col-12">
                <BasicTable
                  isGameTable={true}
                  loading={isLoading || isRefetching}
                  data={modifiedData ? modifiedData : []}
                  range={range}
                  setRange={setRange}
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      <CustomFilterModal
        openCustomFilter={openCustomFilter}
        setOpenCustomFilter={setOpenCustomFilter}
        closeModal={() => setOpenCustomFilter(false)}
        params={params}
        setParams={setParams}
        setRange={setRange}
        setActiveRange={setActiveRange}
      />
    </React.Fragment>
  );
};

export default ByGame;
