import React from "react";

export const CurrencyContext = React.createContext(null);

export const useCurrency = () => {
  const context = React.useContext(CurrencyContext);

  if (context === undefined) {
    throw new Error(
      "useCurrency can only be used inside CurrencyContextProvider"
    );
  }

  return context;
};

export const CurrencyContextProvider = ({ children }) => {
  const [currency, setCurrency] = React.useState(
    localStorage.getItem("currency") || "usd"
  );

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
