import React, { Fragment, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { formatGameName, FormattedNumber } from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { basic } from "react-table/src/sortTypes";
import { Spinner } from "react-bootstrap";
import { useCurrency } from "../../../../contexts";
import GamePopup from "./GamePopup";
import CountryPopup from "../../ByCountry/components/CountryPopup";

const countriesNames = require("i18n-iso-countries");
countriesNames.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const BasicTable = ({
  data,
  isGameTable,
  loading,
  height = null,
  isPopup,
  range,
  setRange,
}) => {
  const { currency } = useCurrency();
  const [showGameModal, setShowGameModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const currencySymbol = getSymbolFromCurrency(currency);

  const columns = React.useMemo(
    () => [
      {
        accessor: isGameTable ? "game" : "country",
        Header: isGameTable ? "Game Name" : "Country",
        Cell: ({ value }) => (
          <div
            className={`${
              isPopup
                ? ""
                : "text-primary cursor-pointer d-block text-truncate me-2"
            }`}
            style={{
              textDecoration: isPopup ? "none" : "underline",
              maxWidth: 140,
            }}
            onClick={() => {
              if (!isPopup)
                if (isGameTable) {
                  setSelectedItem(value);
                  setShowGameModal(true);
                } else {
                  setSelectedItem(value);
                  setShowCountryModal(true);
                }
            }}
          >
            {isGameTable
              ? formatGameName(value)
              : countriesNames.getName(value, "en")}
          </div>
        ),
      },
      {
        accessor: "user_count",
        Header: "Users, q",
      },
      {
        accessor: "round_count",
        Header: "Game Rounds, q",
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "wager",
        Header: () => {
          return <span>Bets, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "award",
        Header: () => {
          return <span>Wins, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "revenue",
        Header: () => {
          return <span>GGR, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
        sortType: basic,
      },
      {
        accessor: "rtp",
        Header: "RTP, %",
      },
      {
        accessor: "pfr_round_count",
        Header: "Free rounds, q",
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "pfr_wager",
        Header: () => {
          return <span>Free round bets, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "pfr_award",
        Header: () => {
          return <span>Free round wins, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "pfr_revenue",
        Header: () => {
          return <span>Free round GGR, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "round_per_user",
        Header: "Game Round/User, q",
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "wager_per_user",
        Header: () => {
          return <span>Bets/User, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
      },
      {
        accessor: "revenue_per_user",
        Header: () => {
          return <span>GGR/User, {currencySymbol}</span>;
        },
        Cell: ({ value }) => <FormattedNumber value={value} />,
        sortType: basic,
      },
    ],
    [currency]
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, headerGroups, getTableBodyProps, prepareRow, rows } =
    tableInstance;

  return (
    <Fragment key={currency}>
      <GamePopup
        showGameModal={showGameModal}
        closeModal={() => setShowGameModal(false)}
        selectedItem={selectedItem}
        range={range}
        setRange={setRange}
      />
      <CountryPopup
        showCountryModal={showCountryModal}
        closeModal={() => setShowCountryModal(false)}
        selectedItem={selectedItem}
        range={range}
        setRange={setRange}
      />
      <table
        {...getTableProps()}
        className="table table-bordered text-nowrap border-bottom dataTable no-footer dtr-inline"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`sorting ${
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sorting_desc"
                        : "sorting_asc"
                      : ""
                  }`}
                >
                  <span className="tabletitle">{column.render("Header")}</span>
                  <span></span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {data?.length > 0 && data !== null && !loading && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td className="borderrigth" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {(data === null || data.length === 0 || loading) && (
        <div
          className="d-flex align-items-center justify-content-center text-primary mt-4"
          style={{ minHeight: height ? height : 300 }}
        >
          {(data === null || data.length === 0) && !loading ? (
            "No data to display"
          ) : (
            <Spinner variant="primary" />
          )}
        </div>
      )}
    </Fragment>
  );
};
