import React from "react";
import { Col, Row } from "react-bootstrap";

export const GeneralInfoBlock = ({ data }) => {
  return (
    <Row className="my-3 mt-4 tx-12">
      <Col xl={5} lg={5} md={12} xs={12}>
        <div className="mb-2">
          IP Address{" "}
          <span className="tx-bold">
            {data?.client_ip}
          </span>
        </div>
      </Col>
      <Col xl={5} lg={5} md={12} xs={12}>
        <div className="mb-2">
          Device User-Agent{" "}
          <span className="tx-bold">
            {data?.user_agent}
          </span>
        </div>
      </Col>
    </Row>
  );
};
