import React, { useEffect, useState } from "react";
import { Card, Col, Nav, Row, Tab, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./_userDetails.scss";
import { convertToCurrency, copyToClipboard } from "../../utilities";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  getGames,
  getSessionsList,
  getSpinsList,
  getUserDetails,
} from "../../services";
import { DatePicker, Select } from "antd";
import { useCurrency } from "../../contexts";
import { Table } from "../../components/Tables";
import { Slide, toast } from "react-toastify";

const UserDetails = () => {
  const { id } = useParams();
  const { currency } = useCurrency();

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );

  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const sessionInitialParams = {
    session_id: "",
    external_user_id: id,
  };

  const roundInitialParams = {
    starting_from: "",
    ending_at: "",
    game: "",
    round_id: "",
    external_user_id: id,
  };

  const [sessionParams, setSessionParams] = useState(sessionInitialParams);
  const [roundParams, setRoundParams] = useState(roundInitialParams);
  const [userId, setUserId] = useState("");
  const [data, setData] = useState("");
  const [tableData, setTableData] = useState("");
  const [roundTableData, setRoundTableData] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [roundPage, setRoundPage] = useState(1);
  const [roundLimit, setRoundLimit] = useState(10);

  const { data: games } = useQuery(["games"], getGames, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const handleSubmit = () => {
    setPage(1);
    getSessionData();
    getRoundData();
  };

  const { isLoading, refetch } = useQuery(
    ["user-details", { id }, { currency }],
    getUserDetails,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        console.log(data);
        setData(data);
      },
    }
  );

  const { refetch: getSessionData, isLoadingSession } = useQuery(
    [
      "session-spins",
      {
        currency,
        limit,
        page,
        ...sessionParams,
        order: "created_at desc",
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getSessionsList,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        console.log(data);
        setTableData(data);
      },
    }
  );

  const {
    isLoadingRound,
    refetch: getRoundData,
    isRefetching,
  } = useQuery(
    [
      "get-spins",
      {
        currency,
        limit: roundLimit,
        page: roundPage,
        ...roundParams,
        starting_from: roundParams.starting_from
          ? moment(roundParams.starting_from?.$d).format("YYYY-MM-DD HH:mm:00Z")
          : "",
        ending_at: roundParams.ending_at
          ? moment(roundParams.ending_at?.$d).format("YYYY-MM-DD HH:mm:59Z")
          : "",
        order: "created_at desc",
      },
    ],
    getSpinsList,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        console.log(data);
        setUserId(data.items[0].user_id);
        setRoundTableData(data);
      },
    }
  );

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success" ? "Copied succesfuly" : "Couldn't copy"}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        transition: Slide,
        autoClose: 600,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const sessionColumns = [
    {
      className: "text-center",
      key: "session_token",
      name: "Session ID",
      highlight: true,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/sessions/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data}
          </Link>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data, toastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "game",
      name: "Game",
      render: (data) => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data}
        </span>
      ),
    },
    {
      className: "text-center",
      key: "created_at",
      name: "Created at",
      highlight: true,
      render: (data) => (
        <>
          <span className="d-block">{moment(data).format("DD-MM-YYYY")}</span>
          <span className="d-block">{moment(data).format("HH:mm UTC")}</span>
        </>
      ),
    },
    {
      className: "text-center",
      key: "wager",
      name: "Wager",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "award",
      name: "Awards",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "revenue",
      name: "GGR",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "rtp",
      name: "RTP",
    },
  ];

  const roundColumns = [
    {
      className: "text-center",
      key: "id",
      name: "Round ID",
      highlight: true,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/gameplays/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate mx-auto"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data}
          </Link>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data, toastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "session_token",
      name: "Session ID",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/sessions/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data}
          </Link>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data, toastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "game",
      name: "Game name",
    },
    {
      className: "text-center",
      key: "wager",
      name: "Wager",
      render: (data) => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "all",
      name: "Awards",
      render: (data) =>
        convertToCurrency(data?.base_award + data?.bonus_award, currency),
    },
    {
      className: "text-center",
      key: "is_pfr",
      name: "PFR",
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      className: "text-center",
      key: "created_at",
      name: "Created at",
      render: (data) => (
        <>
          {!data ? (
            "-"
          ) : (
            <>
              <span className="d-block">
                {moment(data).format("DD-MM-YYYY")}
              </span>
              <span className="d-block">
                {moment(data).format("HH:mm UTC")}
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  const paginate2 = {
    page: roundPage,
    limit: roundLimit,
    setPage: setRoundPage,
    setLimit: setRoundLimit,
  };

  const renderData = (displayData) => {
    if (isLoading) {
      return <Spinner size="sm" />;
    }
    if (data) {
      return displayData;
    }
    return " - ";
  };

  useEffect(() => {
    refetch();
  }, [currency, selectedOperator, selectedIntegrator]);

  useEffect(() => {
    getSessionData();
  }, [currency, limit, page, sessionParams]);

  useEffect(() => {
    getRoundData();
  }, [currency, roundLimit, roundPage, roundParams]);

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            User detail
          </span>
        </div>
      </div>

      <Row>
        <Col lg={12} md={12}>
          <Card>
            <Card.Body className="p-3">
              <h1 className="tx-22 mb-4">User ID: {id}</h1>
              <h2 className="tx-22 mb-4">Inner User ID: {userId}</h2>
              <Row className="m-2 py-3 bd-t">
                <Col xl={3} lg={3} md={12} xs={12}>
                  <div className="tx-16 text-muted">Bets</div>
                  <div className="tx-18 text-info">
                    {renderData(
                      convertToCurrency(data?.wager_without_pfr, data?.currency)
                    )}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
                  <div className="tx-16 text-muted">Free spin bets</div>
                  <div className="tx-18 text-info text-truncate">
                    {renderData(
                      convertToCurrency(data?.wager_with_pfr, data?.currency)
                    )}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
                  <div className="tx-16 text-muted">Total bets</div>
                  <div className="tx-18 text-info text-truncate">
                    {renderData(convertToCurrency(data?.wager, data?.currency))}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
                  <div className="tx-16 text-muted">GGR</div>
                  <div className="tx-18 text-info text-truncate">
                    {renderData(
                      convertToCurrency(data?.revenue, data?.currency)
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="m-2 py-3 bd-t">
                <Col xl={3} lg={3} md={12} xs={12}>
                  <div className="tx-16 text-muted">Wins</div>
                  <div className="tx-18 text-info">
                    {renderData(
                      convertToCurrency(data?.award_without_pfr, data?.currency)
                    )}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
                  <div className="tx-16 text-muted">Free spin wins</div>
                  <div className="tx-18 text-info">
                    {renderData(
                      convertToCurrency(data?.award_with_pfr, data?.currency)
                    )}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
                  <div className="tx-16 text-muted">Total wins</div>
                  <div className="tx-18 text-info">
                    {renderData(convertToCurrency(data?.award, data?.currency))}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
                  <div className="tx-16 text-muted">Margin</div>
                  <div className="tx-18 text-info">
                    {renderData(
                      convertToCurrency(data?.margin, data?.currency)
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="m-2 py-3 bd-t">
                <Col xl={3} lg={3} md={12} xs={12}>
                  <div className="tx-16 text-muted">Integrator</div>
                  <div className="tx-18 text-info">
                    {renderData(data?.integrator)}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
                  <div className="tx-16 text-muted">Provider</div>
                  <div className="tx-18 text-info">
                    {renderData(data?.operator)}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
                  <div className="tx-16 text-muted">Created At</div>
                  <div className="tx-18 text-info">
                    {renderData(
                      moment(data?.created_at).format("DD-MM-YYYY HH:mm UTC")
                    )}
                  </div>
                </Col>
                <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
                  <div className="tx-16 text-muted">Updated At</div>
                  <div className="tx-18 text-info">
                    {renderData(
                      moment(data?.updated_at).format("DD-MM-YYYY HH:mm UTC")
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <h2 style={{ padding: "1.25rem" }} className="pb-0 tx-20 mb-0">
            User history
          </h2>
          <span className=" py-0 ">
            <div className="profile-tab tab-menu-heading border-bottom-0 mg-t-30">
              <Tab.Container id="left-tabs-example" defaultActiveKey="Sessions">
                <Nav
                  variant="pills"
                  className="nav profile-tabs main-nav-line tabs-menu profile-nav-line bg-white border-0 br-0 mb-0"
                >
                  <Nav.Item className="me-1 mg-t-5">
                    <Nav.Link className=" mb-2 mt-2" eventKey="Sessions">
                      Sessions
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="me-1 mg-t-5">
                    <Nav.Link className="mb-2 mt-2" eventKey="Game Rounds">
                      Game Rounds
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Row className="row-sm">
                  <Col lg={12} md={12}>
                    <div className="custom-card main-content-body-profile">
                      <Tab.Content>
                        <Tab.Pane eventKey="Sessions">
                          <div
                            className="main-content-body tab-pane  active"
                            id="sessions"
                          >
                            <Card className="br-0">
                              <Card.Body className="p-0 border-top br-0 p-0">
                                <div className="p-4 br-0">
                                  <div className="m-t-30">
                                    <form>
                                      <div className="d-flex">
                                        <div className="form-group col-3">
                                          <label className="form-label">
                                            Session ID
                                          </label>
                                          <input
                                            placeholder="Enter Session ID(s)..."
                                            className="form-control"
                                            type="text"
                                            value={sessionParams.session_id}
                                            onChange={(e) =>
                                              setSessionParams({
                                                ...sessionParams,
                                                session_id: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex my-3">
                                        <div className="d-flex alig-items-center ms-auto">
                                          <button
                                            type="button"
                                            className="btn btn-info me-2"
                                            onClick={() => handleSubmit()}
                                          >
                                            Submit
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={(e) =>
                                              setSessionParams(
                                                sessionInitialParams
                                              )
                                            }
                                          >
                                            Discard
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                    <div className="border-top" />
                                    <div className="p-t-10 mg-t-35 mg-l-10">
                                      <h5 className="text-primary m-b-5 tx-14">
                                        Results
                                      </h5>
                                    </div>
                                    <Table
                                      loading={isLoadingSession || isRefetching}
                                      data={tableData ? tableData.items : []}
                                      columns={sessionColumns}
                                      paginate={paginate}
                                      total={tableData ? tableData?.total : 0}
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Game Rounds">
                          <div
                            className="main-content-body tab-pane  active"
                            id="about"
                          >
                            <Card className="br-0">
                              <Card.Body className="p-0 border-top br-0 p-0">
                                <div className="p-4 br-0">
                                  <div className="m-t-30">
                                    <form>
                                      <div className="d-flex">
                                        <div className="form-group col-4">
                                          <label className="form-label">
                                            Start and end date
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <DatePicker
                                              placeholder="Select Start Date..."
                                              className="form-control me-4"
                                              clearIcon={null}
                                              suffixIcon={null}
                                              popupClassName={"timepicker"}
                                              minuteStep={5}
                                              showTime={{
                                                format: "HH:mm",
                                              }}
                                              value={roundParams.starting_from}
                                              onChange={(date) =>
                                                setRoundParams({
                                                  ...roundParams,
                                                  starting_from: date,
                                                })
                                              }
                                              disabledDate={(current) => {
                                                if (roundParams.ending_at) {
                                                  let customDate = moment(
                                                    roundParams.ending_at?.$d
                                                  ).format("YYYY-MM-DD HH:mm");
                                                  return (
                                                    current &&
                                                    current >
                                                      moment(
                                                        customDate,
                                                        "YYYY-MM-DD HH:mm"
                                                      )
                                                  );
                                                }
                                                return false;
                                              }}
                                            />
                                            <DatePicker
                                              placeholder="Select End Date..."
                                              className="form-control mr-0"
                                              clearIcon={null}
                                              suffixIcon={null}
                                              minuteStep={5}
                                              showTime={{
                                                format: "HH:mm",
                                              }}
                                              value={roundParams.ending_at}
                                              onChange={(date) =>
                                                setRoundParams({
                                                  ...roundParams,
                                                  ending_at: date,
                                                })
                                              }
                                              disabledDate={(current) => {
                                                if (roundParams.starting_from) {
                                                  let customDate = moment(
                                                    roundParams.starting_from
                                                      ?.$d
                                                  ).format("YYYY-MM-DD HH:mm");
                                                  return (
                                                    current &&
                                                    current <
                                                      moment(
                                                        customDate,
                                                        "YYYY-MM-DD HH:mm"
                                                      )
                                                  );
                                                }
                                                return false;
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group col-2">
                                          <label className="form-label">
                                            Game
                                          </label>
                                          <Select
                                            className="form-control"
                                            placeholder="Select Game..."
                                            optionFilterProp="children"
                                            value={roundParams.game || null}
                                            onChange={(game) =>
                                              setRoundParams({
                                                ...roundParams,
                                                game,
                                              })
                                            }
                                            filterOption={(input, option) =>
                                              (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            options={
                                              games?.map((item) => ({
                                                value: item?.name,
                                                label:
                                                  item?.name?.toUpperCase(),
                                              })) || []
                                            }
                                          />
                                        </div>
                                        <div className="form-group col-2">
                                          <label className="form-label">
                                            Game Round ID
                                          </label>
                                          <input
                                            placeholder="Enter Round ID(s)..."
                                            className="form-control"
                                            type="text"
                                            value={roundParams.round_id}
                                            onChange={(e) =>
                                              setRoundParams({
                                                ...roundParams,
                                                round_id: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex my-3">
                                        <div className="d-flex alig-items-center ms-auto">
                                          <button
                                            type="button"
                                            className="btn btn-info me-2"
                                            onClick={() => {
                                              handleSubmit();
                                            }}
                                          >
                                            Submit
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={(e) =>
                                              setRoundParams(roundInitialParams)
                                            }
                                          >
                                            Discard
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                    <div className="border-top" />
                                    <div className="p-t-10 mg-t-35 mg-l-10">
                                      <h5 className="text-primary m-b-5 tx-14">
                                        Results
                                      </h5>
                                    </div>
                                    <Table
                                      loading={isLoadingRound || isRefetching}
                                      data={
                                        roundTableData
                                          ? roundTableData.items
                                          : []
                                      }
                                      columns={roundColumns}
                                      paginate={paginate2}
                                      total={
                                        roundTableData
                                          ? roundTableData?.total
                                          : 0
                                      }
                                    />
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </span>
        </Col>
      </Row>

      {/* <!-- Row --> */}
      <Row className=" row-sm">
        <Col lg={12} md={12}>
          <div className="tab-content"></div>
          {/* </div> */}
        </Col>
      </Row>
      {/* <!-- row closed --> */}
    </div>
  );
};

export default UserDetails;
