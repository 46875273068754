import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { Select } from "antd";
import { toast, Slide } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addExchange, getMainCurrencies } from "../../../services";

export const CreateExchangeModal = ({ setShowModal, allias }) => {
  const queryClient = useQueryClient();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  // Fetch main currencies
  useQuery("get-currencies", getMainCurrencies, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
    onSuccess: (data) => {
      setCurrencyData(data);
    },
  });

  useEffect(() => {
    getMainCurrencies().then((data) => {
      setCurrencyData(data);
    });
  }, []);

  const { mutate: mutateAddExchange } = useMutation(addExchange, {
    onSuccess: () => {
      toastAlert("success");
      queryClient.invalidateQueries("exchanges"); // Optionally invalidate queries to refresh data
      setShowModal(false);
      reset(); // Reset form fields
    },
    onError: () => {
      toastAlert("error");
      setShowModal(false);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Created exchange successfully`
          : `Couldn't create exchange`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = (data) => {
    setLoading(true);

    const formattedData = {
      ...data,
      rate: parseFloat(data.rate),
      allias,
      from: selectedData,
    };

    mutateAddExchange(formattedData, {
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Modal show onHide={() => setShowModal(false)} centered size="md">
      <Modal.Header>
        <h6 className="modal-title">Create currency</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className={`needs-validation ${isSubmitted ? "was-validated" : ""}`}
        >
          <Form.Group className="form-group">
            <Form.Label>From</Form.Label>
            <Select
              className="form-control"
              maxTagCount={1}
              optionFilterProp="children"
              placeholder={"Select games..."}
              value={selectedData}
              onChange={(data) => setSelectedData(data)}
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              options={
                currencyData
                  ?.filter((item) => allias !== item)
                  .map((item) => ({
                    value: item,
                    label: item,
                  })) || []
              }
            />

            <Form.Label>To</Form.Label>
            <Form.Control
              placeholder="Currency to exchange..."
              type="text"
              defaultValue={allias}
              readOnly
              isInvalid={!!errors.to}
            />
            {errors.to && (
              <div className="invalid-feedback">{errors.to.message}</div>
            )}

            <Form.Label>Rate</Form.Label>
            <Form.Control
              placeholder="Enter currency exchange rate..."
              type="text"
              {...register("rate", {
                required: "Exchange rate is required",
                pattern: {
                  value: /^\d+(\.\d+)?$/,
                  message: "Enter a valid rate (e.g., 1.23 or 1.0885178677)",
                },
              })}
              isInvalid={!!errors.rate}
            />
            {errors.rate && (
              <div className="invalid-feedback">{errors.rate.message}</div>
            )}
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
                className="spinner-border me-2 text-light"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Create"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
