import React, {useEffect, useState} from "react";
import MainLayout from "./MainLayout";
import {setAccessToken} from "./api/auth";
import {getAuth} from "./localstorage/auth";
import {Alert} from "react-bootstrap";

const Lobby = () => {
    const [error, setError] = useState("");

    useEffect(() => {
        const authObj = getAuth();
        if (authObj !== null) {
            setAccessToken(authObj);
        }
    }, []);

    useEffect(() => {
        document.title = "HeronByte Lobby";
    }, []);

    if (error !== "") {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <MainLayout/>
    );
};

export default Lobby;
