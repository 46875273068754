import React, {useEffect, useState} from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { authLogIn } from "../../services";
import { useForm } from "react-hook-form";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      token: "",
      provider: "email",
    },
  });

  const { mutate } = useMutation(authLogIn, {
    onSuccess: ({ data }) => {
      window.localStorage.setItem("access_token", data.data.access_token);
      window.localStorage.setItem("refresh_token", data.data.refresh_token);
      window.localStorage.setItem("expired_at", data.data.expired_at);
      window.location.reload();
    },
    onError: (data) => {
      const errorMessage = data?.response?.data?.data;
      if (errorMessage === "not found") {
        setError("id", { type: "wrong", message: "Account doesn't exist" });
      }
      if (errorMessage === "not valid password") {
        setError("token", { type: "wrong", message: "Wrong password" });
      }
      setLoading(false);
    },
  });

  const onSubmit = handleSubmit(formData => {
    setLoading(true);
    mutate(formData);
  });


  useEffect(() => {
    const theme = localStorage.getItem('theme');
    const appElement = document.querySelector('.page-single');
    if (theme === 'dark') {
      appElement.classList.add('dark-theme');
    } else {
      appElement.classList.remove('dark-theme');
    }
  }, []);

  return (
    <React.Fragment>
      <div className="square-box">
        {" "}
        <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>{" "}
        <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>{" "}
        <div></div> <div></div> <div></div>{" "}
      </div>
      <div className="page bg-primary-gradient1">
        <div className="page-single">
          <div className="container" style={{ marginTop: "89px" }}>
            <Row>
              <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
              >
                <div className="card-sigin">
                  {/* <!-- Demo content--> */}
                  <div className="main-card-signin d-md-flex">
                    <div className="wd-100p">
                      <div className="d-flex mb-4">
                        <Link to="#">
                          <img
                            src={require("../../assets/img/brand/favicon.png")}
                            className="sign-favicon ht-40"
                            alt="logo"
                          />
                        </Link>
                      </div>
                      <div className="">
                        <div className="main-signup-header">
                          <h2>Welcome back!</h2>
                          <h6 className="font-weight-semibold mb-4">
                            Please sign in to continue.
                          </h6>
                          <div className="panel panel-primary">
                            <div className=" tab-menu-heading mb-2 border-bottom-0">
                              <div className="tabs-menu1">
                                <Form
                                  className={`needs-validation${
                                    isSubmitted ? "" : ""
                                  }`}
                                  onSubmit={e => {
                                    setIsSubmitted(true);
                                    onSubmit(e);
                                  }}
                                >
                                  <Form.Group className="form-group">
                                    <Form.Label className="">Email</Form.Label>{" "}
                                    <Form.Control
                                      autoComplete="off"
                                      placeholder="Enter your email"
                                      type="text"
                                      {...register("id", {
                                        required: true,
                                        validate: value => value?.trim() !== "",
                                      })}
                                      isInvalid={isSubmitted && errors?.id}
                                      isValid={isSubmitted && !errors?.id}
                                    />
                                    {errors?.id &&
                                      errors?.id?.type === "wrong" && (
                                        <div className="invalid-feedback">
                                          {errors?.id?.message}
                                        </div>
                                      )}
                                  </Form.Group>
                                  <Form.Group className="form-group">
                                    <Form.Label>Password</Form.Label>{" "}
                                    <Form.Control
                                      autoComplete="off"
                                      placeholder="Enter your password"
                                      type="password"
                                      {...register("token", {
                                        required: true,
                                        validate: value => value?.trim() !== "",
                                      })}
                                      isInvalid={isSubmitted && errors?.token}
                                      isValid={isSubmitted && !errors?.token}
                                    />
                                    {errors?.token &&
                                      errors?.token?.type === "wrong" && (
                                        <div className="invalid-feedback">
                                          {errors?.token?.message}
                                        </div>
                                      )}
                                  </Form.Group>
                                  <div className="main-signin-footer text-center mb-3 mt-3">
                                    <p><Link to="/forgot-password" className="mb-3">Forgot password?</Link></p>
                                  </div>
                                  <Button
                                    disabled={loading}
                                    variant=""
                                    type="submit"
                                    style={{ minHeight: 51 }}
                                    className="btn btn-primary btn-block tx-16"
                                  >
                                    {loading ? (
                                        <span className="text-white">
                                          Loading...
                                        </span>
                                    ) : (
                                      "Sign In"
                                    )}
                                  </Button>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
