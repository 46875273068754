import httpClient from "./httpClient";

export const getGames = async () => {
  const { data } = await httpClient.get("/dictionaries/games");
  if (data) {
    return data.data;
  }
};

export const getIntegrators = async () => {
  const { data } = await httpClient.get("/dictionaries/integrators");
  if (data) {
    return data.data;
  }
};

export const getOperators = async () => {
  const { data } = await httpClient.get("/dictionaries/integrator-operators");
  if (data) {
    return data.data;
  }
};

export const getHosts = async () => {
  const { data } = await httpClient.get("/dictionaries/hosts");
  if (data) {
    return data.data;
  }
};

export const getCurrenciesData = async () => {
  const { data } = await httpClient.get("/dictionaries/currencies");
  if (data) {
    return data.data;
  }
};

export const getJurisdictions = async () => {
  const { data } = await httpClient.get("/dictionaries/jurisdictions");
  if (data) {
    return data.data;
  }
};

export const addJurisdiction = async (formData) => {
  const { data } = await httpClient.post("/dictionaries/jurisdictions", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const deleteJurisdiction = async (jurisdiction) => {
  const { data } = await httpClient.delete(
    `/dictionaries/jurisdictions/${jurisdiction}`
  );
  if (data) {
    return data.data;
  }
};

export const getLanguages = async () => {
  const { data } = await httpClient.get("/dictionaries/languages");
  if (data) {
    return data.data;
  }
};

export const addLanguage = async (formData) => {
  const { data } = await httpClient.post("/dictionaries/languages", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const deleteLanguage = async (language) => {
  const { data } = await httpClient.delete(
    `/dictionaries/languages/${language}`
  );
  if (data) {
    return data.data;
  }
};

export const getLocales = async () => {
  const { data } = await httpClient.get("/dictionaries/locales");
  if (data) {
    return data.data;
  }
};

export const addLocale = async (formData) => {
  const { data } = await httpClient.post("/dictionaries/locales", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const deleteLocale = async (locale) => {
  const { data } = await httpClient.delete(`/dictionaries/locales/${locale}`);
  if (data) {
    return data.data;
  }
};

export const getMainCurrencies = async () => {
  const { data } = await httpClient.get("/dictionaries/main-currencies");
  if (data) {
    return data.data;
  }
};
export const getEnvironmentName = async () => {
  const { data } = await httpClient.get("/info/environment_name");
  if (data) {
    return data.data;
  }
};
export const getLogo = async () => {
  const { data } = await httpClient.get("/info/logo");
  if (data) {
    return data.data;
  }
};

export const getGameUrl = async (formData) => {
  const { data } = await httpClient.post("/lobby/start_game", { ...formData });
  if (data) {
    return data.data;
  }
};
