import React, {useEffect, useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {getOrganizationGames, getWagerSets, integrateGame} from "../../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

export const IntegrateModal = ({ setShowModal, onSettled, integrators, error, setError, setHasError, allGames }) => {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);

    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [selectedGames, setSelectedGames] = useState([]);
    const [shortLink, setShortLink] = useState(false);

    const [wagerSets, setWagerSets] = useState([]);
    const [selectedWagerSet, setSelectedWagerSet] = useState({
        label: "",
        value: "",
      });

    const {
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues: {
            game_id: [],
            provider_id: "",
            short_link: "",
        },
    });

    const { refetch: refetchGames, isFetching} = useQuery(
        [
            "games-info",
            {
                selectedOrganization
            },
        ],
        getOrganizationGames,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: selectedOrganization !== null,
            onSuccess: data => {
                setGames(data);
            },
        }
    );

    const { refetch: refetchWagerSets } = useQuery(
        [
          "wager-set",
          {
            limit: 100,
            page: 1,
          },
        ],
        getWagerSets,
        {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          onSuccess: (data) => {
            const wagersArray = data?.items?.map((wager) => ({
              label: wager.name,
              value: wager.id,
            }));
            setWagerSets(wagersArray);
          },
        }
      );

    const { mutate } = useMutation(integrateGame, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setShowModal(false);
        },
        onError: (error) => {
            setError(error.response?.data?.data || "An unknown error occurred");
            if (error.response?.data?.data === "not found") {
                setHasError(false);
            } else {
                setHasError(true);
                setShowModal(false);
                setLoading(false);
            }
        },
    });

    const onOrganizationChange = (org) => {
        setSelectedOrganization(org);
        const modifiedList = allGames
            .filter(game => !games.some(g => g.GameID === game.id))

        setFilteredGames(modifiedList)
    }

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Integration was modified successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        formData.game_id = selectedGames;
        formData.organizationId = selectedOrganization;
        formData.provider_id = selectedOrganization;
        formData.short_link = shortLink;
        formData.wager_set_id =
            selectedWagerSet.value || "00000000-0000-0000-0000-000000000000";
        setLoading(true);
        mutate(formData);
    });

    useEffect(() => {
        refetchWagerSets();
    }, []);

    useEffect(() => {
        if (selectedOrganization) {
            const modifiedList = allGames.filter(game => !games.some(g => g.GameID === game.id));
            setFilteredGames(modifiedList);
            // If you want to pre-select games that are not integrated yet, you can set the value here
            setValue("game_id", modifiedList.map(game => game.id));
        }
    }, [games, allGames, selectedOrganization, setValue]);

    return (
        <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
            <Modal.Header>
                <h6 className="modal-title">Integrate Game</h6>
                <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form
                    className={`needs-validation${isSubmitted ? "" : ""}`}
                    onSubmit={e => {
                        setIsSubmitted(true);
                        onSubmit(e);
                    }}
                >
                    <Form.Group className="form-group">
                        <Form.Label className="">Integrator</Form.Label>
                        <Select
                            className="form-control"
                            optionFilterProp="children"
                            placeholder={"Select integrator..."}
                            value={selectedOrganization}
                            onChange={(org) => onOrganizationChange(org)}
                            dropdownStyle={{
                                zIndex: '9999999999'
                            }}
                            options={
                                integrators?.map(item => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || []
                            }
                        />
                        <Form.Label>Game</Form.Label>
                        <Select
                            mode={"multiple"}
                            className="form-control"
                            maxTagCount={2}
                            optionFilterProp="children"
                            placeholder={"Select games..."}
                            value={selectedGames}
                            onChange={(game) => setSelectedGames(game)}
                            dropdownStyle={{
                                zIndex: '9999999999'
                            }}
                            options={
                                filteredGames?.map(item => ({
                                    value: item?.id,
                                    label: item?.name,
                                })) || []
                            }
                        />
                        <Form.Label>Wager set</Form.Label>
                        <Select
                          className="form-control"
                          optionFilterProp="children"
                          placeholder={"Select wager set..."}
                          value={selectedWagerSet.label}
                          onChange={(_, wager) =>
                            setSelectedWagerSet({
                              label: wager.label,
                              value: wager.value,
                            })
                          }
                          dropdownStyle={{
                            zIndex: "9999999999",
                          }}
                          options={
                            wagerSets?.map((item) => ({
                              value: item.value,
                              label: item.label,
                            })) || []
                          }
                        />
                        <Form.Label className="custom-control custom-checkbox custom-control-md mg-t-20">
                          <Form.Control
                              type="checkbox"
                              className="custom-control-input"
                              name="example-checkbox2"
                              value="option2"
                              checked={shortLink}
                              onChange={() => setShortLink(!shortLink)}
                          />
                          <span className="mg-r-30 custom-control-label custom-control-label-md">Short Link</span>
                        </Form.Label>
                    </Form.Group>
                    <Button
                        disabled={loading}
                        variant=""
                        type="submit"
                        style={{ minHeight: 51 }}
                        className="btn btn-primary btn-block tx-16"
                    >
                        {loading ? (
                            <span className="text-white">
                  Loading...
                </span>
                        ) : (
                            "Create"
                        )}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
