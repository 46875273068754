import React from "react";

function CrashGameRenderer(data) {
  const { spin } = data.data;

  const getStatusDescription = (status) => {
    switch (status) {
      case 0:
        return "Cancelled by user";
      case 1:
        return "Closed by system";
      case 2:
        return "Closed by disconnect";
      case 3:
        return "Payout success";
      case 4:
        return "Auto payout success";
      case 5:
        return "Round lost";
      default:
        return "Unknown status";
    }
  };

  let tableRows;
  tableRows = (
    <div className="aviator-round">
      <p className="mt-5 tx-16 tx-bold">Round Result</p>
      <table className={`game-stats-info__stats-class`}>
        <tbody>
          {spin?.status !== undefined && spin?.status !== null && (
            <tr>
              <td>Status</td>
              <td id="spin-status">{getStatusDescription(spin?.status)}</td>
            </tr>
          )}
          {spin?.status !== undefined && spin?.status !== null && (
            <tr>
              <td>Coefficient</td>
              <td id="spin-coefficient">{spin?.coefficient}</td>
            </tr>
          )}
          {spin?.box_index !== undefined && spin?.box_index !== null && (
            <tr>
              <td>Box index</td>
              <td id="spin-box-index">{spin?.box_index}</td>
            </tr>
          )}
          {spin?.level !== undefined && spin?.level !== null && (
            <tr>
              <td>Level</td>
              <td id="spin-level">{spin?.level}</td>
            </tr>
          )}
          {/* {spin?.path !== undefined && spin?.path !== null && (
            <tr>
              <td>Path</td>
              <td id="spin-path">{spin?.path}</td>
            </tr>
          )} */}
          {spin?.risk_mode !== undefined && spin?.risk_mode !== null && (
            <tr>
              <td>Risk mode</td>
              <td id="spin-risk-mode">{spin?.risk_mode}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return tableRows;
}

export default CrashGameRenderer;
