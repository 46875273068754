import React from "react";
import Lobby from "./components/Lobby";
import { ErrorBoundaries } from "./components/ErrorBoundaries";

const MainLayout = () => {
    return (
        <ErrorBoundaries>
            <Lobby />
        </ErrorBoundaries>
    );
};

export default MainLayout;
