export function replaceSymbols(reelWindow, scatterValues) {
    const indexToSymbolMap = {};

    scatterValues.forEach(scatter => {
        if (scatter.indexes?.length > 0) {
            scatter.indexes.forEach(index => {
                indexToSymbolMap[index] = scatter.symbol;
            });
        } else {
            reelWindow = reelWindow.map(reel => {
                if (reel.includes(scatter.symbol)) {
                    return reel.fill(scatter.symbol);
                } else {
                    return reel;
                }
            });
        }
    });

    for (let rowIndex = 0; rowIndex < reelWindow.length; rowIndex++) {
        for (let columnIndex = 0; columnIndex < reelWindow[rowIndex].length; columnIndex++) {
            const index = columnIndex * reelWindow.length + rowIndex;
            const replacementSymbol = indexToSymbolMap[index];
            if (replacementSymbol !== undefined) {
                reelWindow[rowIndex][columnIndex] = replacementSymbol;
            }
        }
    }

    return reelWindow;
}
