export const bonusGame = (data) => {
    const hasBonusGameInStages = data?.spin?.spins?.[0]?.stages?.some(item => item?.bonus_game);
    const hasBonusSpins = data?.spin?.bonus_spins?.length > 0;
    const hasBonusSpinsResults = data?.spin?.bonus?.spins_results?.length > 0;
    const hasBonusSpinsArray = data?.spin?.bonus?.spins?.length > 0;
    const hasBonusArray = data?.spin?.bonus?.length > 0;

    return (
        hasBonusGameInStages ||
        hasBonusSpins ||
        hasBonusSpinsResults ||
        hasBonusSpinsArray ||
        hasBonusArray
    );
};


