export const isFSRetriggered = (data) => {
    let lastValue = null;
    let isRetriggered = false;
    if (data?.spin?.bonus) {
        isRetriggered = data?.spin?.bonus?.base_spins_bonus_triggered?.total_spins < data?.spin?.bonus?.spins_results?.length;
    }
    data?.spin?.spins?.[0]?.stages?.forEach(item => {
        if (!item?.bonus_game || isRetriggered) return;
        item?.bonus_game?.spins?.forEach((spin, key) => {
            if (
                key === item?.bonus_game?.spins?.length - 1 &&
                lastValue > spin?.free_spins_left
            ) {
                lastValue = null;
            } else {
                if (key === 0) {
                    lastValue = spin?.free_spins_left
                    return;
                }
                if (lastValue < spin?.free_spins_left) {
                    isRetriggered = true;
                } else {
                    lastValue = spin?.free_spins_left;
                }
            }
        });
    })
    return isRetriggered ? "Yes" : "No";
};
