import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getPublicSession } from "../../services";
import { GameStateCard } from "../SessionDetail/gameStateCard";

const History = () => {
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const game = query.get("game");

  const { data } = useQuery(
    ["session-detail", { id }, { game }],
    getPublicSession,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <React.Fragment>
      <h2 style={{ padding: "1.25rem" }} className="pb-0 tx-20 mb-0">
        Game Rounds
      </h2>

      {data
        ? data.map((sessionData) =>
            sessionData?.spins
              ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((spin) => {
                return spin ? (
                  <GameStateCard data={spin} key={spin.id} isPublic={true} />
                ) : null;
              })
          )
        : null}
    </React.Fragment>
  );
};

export default History;
