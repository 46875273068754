import React from "react";
import { symbolImageLink } from "../symbols/symbolImageLink";
import { getRowsForGame } from "../rows/rows";
import "./AsymmetricGridRenderer.css";

function AsymmetricGridRenderer(stage) {
  const { payouts, reel_window } = stage.stage;
  let tableRows;
  tableRows = (
    <div className="table-div">
      <div className="first-row-container">
        {reel_window?.slice(0, 5).map((column, columnIndex) => {
          const reversedColumn = [...column].reverse();
          const firstSymbol = reversedColumn[0];
          let symbolIndex = reversedColumn.indexOf(firstSymbol);
          let isSymbolInWinLine = false;
          const reversedSymbolIndex = reversedColumn.length - 1 - symbolIndex;

          if (
            payouts.values?.some((payout) => {
              const subIndexes = payout.indexes?.[columnIndex];
              return (
                Array.isArray(subIndexes) &&
                subIndexes.includes(reversedSymbolIndex)
              );
            })
          ) {
            isSymbolInWinLine = true;
          }

          const brightness = isSymbolInWinLine ? 1 : 0.4;

          return (
            <div
              className={`symbol-container symbol-size-first-row column-${columnIndex} row-0`}
              key={`0-${columnIndex}`}
              style={{
                filter: `brightness(${brightness})`,
                backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                  stage.gameName
                )}/${String(firstSymbol)?.toLowerCase()}_2.png)`,
              }}
            >
              {!symbolImageLink(stage.gameName) &&
                (firstSymbol ? String(firstSymbol).toUpperCase() : "")}
            </div>
          );
        })}
      </div>

      <div className="columns-container">
        {reel_window?.map((column, columnKey) => {
          const reversedColumn = [...column].reverse();
          const filteredColumn =
            columnKey > 0 && columnKey < 5
              ? reversedColumn.slice(1)
              : reversedColumn;
          return (
            <div className="column-container" key={columnKey}>
              {getRowsForGame(stage.gameName)?.map((row, rowKey) => {
                let symbol, symbolIndex;

                symbol = filteredColumn[row];
                symbolIndex = filteredColumn.indexOf(symbol);

                const reversedSymbolIndex =
                  filteredColumn.length - 1 - symbolIndex;
                let isSymbolInWinLine = false;
                if (
                  payouts.values?.some((payout) => {
                    const subIndexes = payout.indexes?.[columnKey];
                    return (
                      Array.isArray(subIndexes) &&
                      subIndexes.includes(reversedSymbolIndex)
                    );
                  })
                ) {
                  isSymbolInWinLine = true;
                }
                let size;
                switch (filteredColumn.length) {
                  case 2:
                    size = 1;
                    break;
                  case 3:
                    size = 2;
                    break;
                  case 4:
                    size = 3;
                    break;
                  case 5:
                    size = 4;
                    break;
                  case 6:
                    size = 5;
                    break;
                  default:
                    size = 6;
                }

                const brightness = isSymbolInWinLine ? 1 : 0.4;

                return (
                  <div
                    className={`${reversedSymbolIndex} symbol-container symbol-size-${size}`}
                    key={`${rowKey}-${columnKey}`}
                    style={{
                      filter: `brightness(${brightness})`,
                      backgroundImage: symbol
                        ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                            stage.gameName
                          )}/${String(symbol)?.toLowerCase()}_${size}.png)`
                        : "none",
                    }}
                  >
                    {!symbolImageLink(stage.gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );

  return tableRows;
}

export default AsymmetricGridRenderer;
