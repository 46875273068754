import React from "react";
import { symbolImageLink } from "../symbols/symbolImageLink";
import { getRowsForGame } from "../rows/rows";
import "./AsymmetricGridRendererAvalanch.css";

function AsymmetricGridRendererAvalanche({ stage, gameName, winlineIndex }) {
  const { payouts, reel_window } = stage;

  const calculateSymbolSize = (column, rowIndex) => {
    let size = 1;
    for (let i = rowIndex + 1; i < column.length; i++) {
      if (column[i] === 0) {
        size++;
      } else {
        break;
      }
    }
    return size;
  };

  const rows = getRowsForGame(gameName);
  const winline = payouts.values[winlineIndex];

  let tableRows = (
    <div className="table-div">
      <div className="columns-container">
        {reel_window?.map((column, columnKey) => {
          const reversedColumn = [...column].reverse();
          return (
            <div className="column-container" key={columnKey}>
              {rows.map((row, rowKey) => {
                const symbol = reversedColumn[row];
                if (symbol === 0) return null;

                const symbolIndex = reversedColumn.length - 1 - rowKey;
                let isSymbolInWinLine = false;

                if (winline?.indexes[columnKey]?.includes(symbolIndex)) {
                  isSymbolInWinLine = true;
                }

                const size = calculateSymbolSize(
                  column,
                  column.length - 1 - rowKey
                );
                const brightness = isSymbolInWinLine ? 1 : 0.4;

                return (
                  <div
                    className={`symbol-container symbol-size_${size}`}
                    key={`${rowKey}-${columnKey}`}
                    style={{
                      filter: `brightness(${brightness})`,
                      backgroundImage: symbol
                        ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                            gameName
                          )}/${String(symbol)?.toLowerCase()}_${size}.png)`
                        : "none",
                    }}
                  >
                    {!symbolImageLink(gameName) &&
                      (symbol ? String(symbol).toUpperCase() : "")}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );

  return tableRows;
}

export default AsymmetricGridRendererAvalanche;
