import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { editIntegratedGame } from "../../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

export const EditIntegrationModal = ({
  setShowModal,
  selectedEditItem,
  refetchGames,
}) => {
  const { Game, game_id, rtp, volatility, wager_set, organization_id, short_link} =
  selectedEditItem;
  const { available_rtp, available_volatility, available_wager_sets } = Game;

  const wagersArray = available_wager_sets?.map((wager) => ({
    label: wager.name,
    value: wager.id,
  }));

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedRtp, setSelectedRtp] = useState(rtp);
  const [shortLink, setShortLink] = useState(short_link);
  const [selectedVolatility, setSelectedVolatility] = useState(volatility);
  const [selectedWagerSet, setSelectedWagerSet] = useState({
    label: wager_set?.name || "",
    value: wager_set?.id || "",
  });

  const { handleSubmit } = useForm({
    defaultValues: {
      game_id: "",
      rtp: "",
      volatility: "",
      wager_set_id: "",
      organization_id: "",
      short_link: "",
    },
  });

  const { mutate } = useMutation(editIntegratedGame, {
    onSuccess: () => {
      toastAlert("success");
      refetchGames();
      setShowModal(false);
    },
    onError: (error) => {
      toastAlert("error");
      setShowModal(false);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Integration was modified successfully`
          : `Error: Oops, something went wrong`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    formData.game_id = game_id;
    formData.rtp = selectedRtp;
    formData.volatility = selectedVolatility;
    formData.organization_id = organization_id;
    formData.short_link = shortLink;
    formData.wager_set_id =
      selectedWagerSet.value || "00000000-0000-0000-0000-000000000000";
    setLoading(true);
    mutate(formData);
  });

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Edit game integration</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={(e) => {
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label className="">RTP</Form.Label>
            <Select
              className="form-control"
              optionFilterProp="children"
              placeholder={"Select rtp..."}
              value={selectedRtp}
              onChange={(rtp) => setSelectedRtp(rtp)}
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              options={
                available_rtp?.map((item) => ({
                  value: item,
                  label: item,
                })) || []
              }
            />
            <Form.Label>Volatility</Form.Label>
            <Select
              className="form-control"
              optionFilterProp="children"
              placeholder={"Select volatility..."}
              value={selectedVolatility}
              onChange={(volatility) => setSelectedVolatility(volatility)}
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              options={
                available_volatility?.map((item) => ({
                  value: item,
                  label: item,
                })) || []
              }
            />
            <Form.Label>Wager set</Form.Label>
            <Select
              className="form-control"
              optionFilterProp="children"
              placeholder={"Select wager set..."}
              value={selectedWagerSet.label}
              onChange={(_, wager) =>
                setSelectedWagerSet({
                  label: wager.label,
                  value: wager.value,
                })
              }
              dropdownStyle={{
                zIndex: "9999999999",
              }}
              options={
                wagersArray?.map((item) => ({
                  value: item.value,
                  label: item.label,
                })) || []
              }
            />
            <Form.Label className="custom-control custom-checkbox custom-control-md mg-t-20">
              <Form.Control
                  type="checkbox"
                  className="custom-control-input"
                  name="example-checkbox2"
                  value="option2"
                  checked={shortLink}
                  onChange={() => setShortLink(!shortLink)}
              />
              <span className="mg-r-30 custom-control-label custom-control-label-md">Short Link</span>
            </Form.Label>
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? <span className="text-white">Loading...</span> : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
