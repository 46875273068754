import React, {useEffect, useState} from "react";
import {Breadcrumb, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { toast, Slide } from "react-toastify";
import {
  getPermissons,
  deletePermission,
} from "../../services";
import { Table } from "../../components/Tables";
import {useProfile} from "../../contexts";
import {copyToClipboard} from "../../utilities";
import {PermissionsModal} from "./components/permissionsModal";
import {DeleteModal} from "../Users-Roles/components/deleteModal";
import {EditPermissionsModal} from "./components/editPermissionModal";

const Permissons = () => {

  const { profileData } = useProfile();
  const [data, setData] = useState();
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const {
    data: permissionsData,
      refetch,
    isLoading,
    isRefetching,
  } = useQuery(
    [
      "permissions",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getPermissons,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        setData(data);
      },
    }
  );

  const toastAlert = type =>
    toast.success(
        <p className="text-white tx-16 mb-0 ">
          {type === "success"
              ? `Permission was modified successfully`
              : `Error: ${error}`}
        </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const { mutate: deletePermissionFromList } = useMutation(deletePermission, {
    onSuccess: () => {
      setShowDeleteModal(false);
      toastAlert("success");
      refetch();
      selectedData && setSelectedData(null);
    },
    onError: (error) => {
      setError(error.response.data.data);
      setHasError(true);
      setShowDeleteModal(false);
      selectedData && setSelectedData(null);
    },
  });

  const handleItemClick = (propertyValue) => {
    const selectedItem = data?.find(item => item.id === propertyValue);
    setSelectedData(selectedItem);
    setShowEditModal(true);
  };

  const columns = [
    {
      className: "text-center",
      key: "id",
      name: "ID",
      highlight: true,
      render: data => (
          <div className="d-flex align-items-center justify-content-center">
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip className="tooltip tooltip-primary1">Edit permission</Tooltip>}
            >
            <span
                onClick={() => handleItemClick(data)}
                className="d-block text-truncate text-primary text-underline cursor-pointer me-2"
                style={{
                  maxWidth: 80,
                  textDecoration: "underline",
                }}
            >
              {data}
            </span>
            </OverlayTrigger>
            <button
                className="btn tx-10 p-1"
                onClick={() => copyToClipboard(data, toastAlert)}
            >
              <i className='mdi mdi-content-copy'></i>
            </button>
          </div>
      ),
    },
    {
      className: "text-center",
      key: "name",
      name: "Name",
    },
    {
      className: "text-center",
      key: "description",
      name: "Description",
      render: data =>
          <span
              className=" me-2"
              style={{
                maxWidth: 300,
                whiteSpace: 'normal',
              }}
          >
              {data}
            </span>
    },
    {
      className: "text-center",
      key: "action",
      name: "Action",
      width: 200,
    },
    {
      className: "text-center",
      key: "endpoint",
      name: "End point",
      width: 350,
    },

    {
      className: "text-center",
      key: "subject",
      name: "Subject",
      width: 200,
    },
    {
      className: "text-center",
      key: "all",
      name: "Manage",
      width: 60,
      render: data =>
          data?.roles?.[0]?.type === "admin" ||
          data?.roles?.[0]?.type === "root" ? null : (
              <div className="d-flex align-items-center justify-content-center">
                <button
                    className={`btn ${
                        data?.id === profileData?.id
                            ? "btn-dark-light"
                            : "btn-outline-danger"
                    } d-flex align-items-center justify-content-center p-1`}
                    disabled={data?.id === profileData?.id}
                    onClick={() => {
                      if (data?.id === profileData?.id) {
                        return;
                      }
                      setSelectedData(data);
                      setShowDeleteModal(true);
                    }}
                >
                  <i className="fe fe-trash" />
                </button>
              </div>
          ),
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  useEffect(() => {
    if (hasError) {
      toastAlert("error");
      setHasError(false);
    }
  }, [error, hasError]);

  return (
    <>
      {showModal && (
          <PermissionsModal setShowModal={setShowModal} onSettled={refetch} />
      )}
      {showDeleteModal && (
          <DeleteModal
              title="Permission"
              onAccept={() => deletePermissionFromList(selectedData?.id)}
              setShowModal={() => {
                setShowDeleteModal();
                setSelectedData(null);
              }}
              additionalInfo={`Name: ${selectedData?.name}`}
          />
      )}
      {showEditModal && (
          <EditPermissionsModal
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setShowModal={setShowEditModal}
              refetch={refetch}
          />
      )}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Permissions
          </span>
        </div>
        <div className="right-content">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => setShowModal(true)}>
              <span>Create</span>
              <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card>
        <Card.Body className="pt-4 example1-table">
          <Table
            loading={isLoading || isRefetching}
            data={data ? data : []}
            columns={columns}
            paginate={paginate}
            total={permissionsData ? permissionsData?.total : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Permissons;
