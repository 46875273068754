import React from "react";
import {stops} from "../helpers/stops/stops";

export const StopsTable = ({gameName, data}) => {
    return (
        stops(gameName, data)?.length > 0 && (
            <>
                <p className="tx-16 tx-bold mt-4 mb-2">Stops</p>
                <table
                    className="game-stats-info__stats-class mt-0 mb-4"
                    id="win_lines_table"
                >
                    <thead>
                    <tr>
                        <td colSpan={stops(gameName, data).length}>Stops</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {stops(gameName, data)?.map((stop, itemKey) => (
                            <td key={itemKey}>{stop}</td>
                        ))}
                    </tr>
                    </tbody>
                </table>
            </>
        )
)
}