import React, { forwardRef } from "react";
import { Carousel } from "react-bootstrap";
import { symbolImageLink } from "../../helpers/symbols/symbolImageLink";
import "./WinlinesCarouselAssymetric.css";

const WinlinesCarouselAssymetric = forwardRef(({ stage, gameName }, ref) => {
  const renderSpinMatrixForWinline = (payout, key) => {
    const { reel_window, multipliers, fire_mask, progress, wild_mask } = stage;
    let multiplierIndex = 0;
    let size;

    const payoutsMultipliers = stage.payouts.values[key].multipliers;

    const renderSymbol = (symbol) => {
      let displayText;
      if (symbol === 12) {
        const multiplier = multipliers
          ? multipliers[multiplierIndex]
          : payoutsMultipliers[multiplierIndex];
        displayText =
          multiplier === 1 && gameName !== "coral-reef-flexiways"
            ? "WILD"
            : `X${multiplier}`;
        if (gameName === "coral-cash") {
          displayText = `X${multiplier}`;
        }

        const style = multiplier === 1 ? { fontSize: 34 } : null;
        if (multipliers) {
          if (multiplierIndex < multipliers.length - 1) {
            multiplierIndex++;
          }
        } else if (payoutsMultipliers) {
          if (multiplierIndex < payoutsMultipliers.length - 1) {
            multiplierIndex++;
          }
        }

        return (
          <span
            style={
              (style,
              {
                margin:
                  size >= 5 ? "auto 20px auto 25px" : "auto 20px auto 10px",
                fontSize: size >= 5 || displayText === "WILD" ? "28px" : "52px",
              })
            }
            className="winNumber"
          >
            {displayText}
          </span>
        );
      }
      return null;
    };

    const tableRows = (
      <div className="table-div">
        <div className="columns-container">
          {reel_window?.map((column, columnKey) => {
            const isAnubisSecret = gameName === "anubis-secret";
            const columnToRender = isAnubisSecret
              ? [...column].reverse()
              : column;

            return (
              <div className="column-container" key={columnKey}>
                {columnToRender.map((symbol, rowIndex) => {
                  const adjustedRowIndex = isAnubisSecret
                    ? column.length - 1 - rowIndex
                    : rowIndex;

                  const isSymbolInWinLine =
                    payout.indexes[columnKey] === adjustedRowIndex;
                  const isSymbolInFireMask =
                    fire_mask && fire_mask[columnKey][adjustedRowIndex];

                  const reverseWildMask =
                    wild_mask &&
                    wild_mask.map((mask) => {
                      return mask;
                    });
                  const isSymbolInWildMask =
                    wild_mask && reverseWildMask[columnKey][adjustedRowIndex];

                  switch (column.length) {
                    case 2:
                      size = 1;
                      break;
                    case 3:
                      size = 2;
                      break;
                    case 4:
                      size = 3;
                      break;
                    case 5:
                      size = 4;
                      break;
                    case 6:
                      size = 5;
                      break;
                    default:
                      size = 6;
                  }

                  const brightness = isSymbolInWinLine ? 1 : 0.4;
                  return (
                    <div
                      className={`${adjustedRowIndex} symbol-container symbol-size-${size}`}
                      key={`${adjustedRowIndex}-${columnKey}`}
                      style={{
                        display: "flex",
                        justifyContent:
                          multipliers || payoutsMultipliers
                            ? multipliers ||
                              payoutsMultipliers[multiplierIndex] === 1
                              ? "flex-start"
                              : "center"
                            : null,
                        alignItems: "center",
                        filter: `brightness(${brightness})`,
                        backgroundImage:
                          isSymbolInFireMask &&
                          progress.current === 10 &&
                          symbol
                            ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                                gameName
                              )}/${String(18).toLowerCase()}_${size}.png)`
                            : isSymbolInWildMask
                            ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                                gameName
                              )}/${String(18).toLowerCase()}_${size}.png)`
                            : symbol
                            ? `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${symbolImageLink(
                                gameName
                              )}/${String(symbol).toLowerCase()}_${size}.png)`
                            : "none",
                      }}
                    >
                      {(multipliers || payoutsMultipliers) &&
                      (gameName === "cat-mansion" ||
                        gameName === "coral-reef-flexiways" ||
                        gameName === "coral-cash") &&
                      symbol
                        ? renderSymbol(symbol)
                        : null}
                      {!symbolImageLink(gameName) &&
                        (symbol ? String(symbol).toUpperCase() : "")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
        <thead>
          <tr className="text-center media-text-position">
            <td colSpan={5} className="py-3">
              Table - {key + 1}
            </td>
          </tr>
        </thead>
        <tbody className="table-body">{tableRows}</tbody>
      </table>
    );
  };

  const renderWinResultsTable = (activeKey) => {
    const activePayout = stage.payouts?.values[activeKey];

    return (
      <table
        className="game-stats-info__stats-class mt-0"
        id="win_results_table"
      >
        <thead>
          <tr className="text-center">
            <td colSpan={4} className="py-3">
              Winning result
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <th>Payline</th>
            <th>Award</th>
            <th>Amount</th>
            <th>Symbol</th>
          </tr>
          {activePayout && (
            <tr className="text-center">
              <td>
                {activePayout.payline
                  ? activePayout.payline + 1
                  : activeKey + 1}
              </td>
              {activePayout.award_with_multipliers ? (
                <td>{activePayout.award_with_multipliers / 1000}</td>
              ) : (
                <td>{activePayout.award / 1000}</td>
              )}
              {activePayout.amount ? (
                <td>{activePayout.amount / 1000}</td>
              ) : null}
              <td>{activePayout.symbol}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
      {stage.payouts?.values?.map((payout, key) => (
        <Carousel.Item key={key}>
          <div className="win-matrix-container">
            {renderSpinMatrixForWinline(payout, key)}
          </div>
          {renderWinResultsTable(key)}
        </Carousel.Item>
      ))}
    </Carousel>
  );
});

export default WinlinesCarouselAssymetric;
