import React, {useEffect, useState} from "react";
import {Button, Form, Modal, Nav, Tab, Tabs} from "react-bootstrap";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {addAccountsOrganization, addUserOrganization, deleteAccountsOrganization, deleteUserOrganization, getOrganizationOperators} from "../../../services";
import {toast, Slide} from "react-toastify";
import {Select} from "antd";

export const EditUserOrganizationsModal = ({
                                               setShowModal,
                                               selectedData,
                                               orgs,
                                               userOrgs,
                                               setSelectedData,
                                               refetch,
                                               error,
                                               hasError,
                                               setHasError,
                                               setError,
                                           }) => {
                                               
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const [operators, setOperators] = useState(null);
    const [addSelectedOrg, setAddSelectedOrg] = useState("");
    const [deleteSelectedOrg, setDeleteSelectedOrg] = useState("");
    
    const [activeTab, setActiveTab] = useState('link-40');
    const [addSelectedOperator, setAddSelectedOperator] = useState("");
    const [deleteSelectedOperator, setDeleteSelectedOperator] = useState("");
    const [organizationId, setOrganizationId] = useState(null);

    const { refetch: refetchOperators, isFetching} = useQuery(
        [
            "operators-info",
            {
                organizationId
            },
        ],
        getOrganizationOperators,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: organizationId !== null && organizationId !== '',
            onSuccess: data => {
              setOperators(data);
            },
        }
    );

    const addPermissions = useMutation(addUserOrganization, {
        onSuccess: () => {
            toastAlert("success");
            refetch();
        },
        onError: (error) => {
            console.log(error.response.data.data)
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const addOperators = useMutation(addAccountsOrganization, {
        onSuccess: () => {
            toastAlert("success");
            refetch();
        },
        onError: (error) => {
            console.log(error.response.data.data)
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const deleteOperators = useMutation(deleteAccountsOrganization, {
        onSuccess: () => {
            toastAlert("success");
            refetch();
        },
        onError: (error) => {
            console.log(error.response.data.data)
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const {mutate: deletePermissions} = useMutation(deleteUserOrganization, {
        onSuccess: () => {
            toastAlert("success");
            refetch();
        },
        onError: (error) => {
            console.log(error.response.data.data)
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Account organizations were updated successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    // const onSubmit = async () => {
    //     setLoading(true);
    //     try {
    //         if (addSelectedOrg !== "") {
    //             await addPermissions.mutate({
    //                 id: selectedData?.id,
    //                 organization_id: addSelectedOrg,
    //             });
    //         }
    //         if (deleteSelectedOrg !== "") {
    //             await deletePermissions({
    //                 id: selectedData?.id,
    //                 organization_id: deleteSelectedOrg,
    //             });
    //         }
    //     } catch (error) {

    //     } finally {
    //         setLoading(false);
    //         setShowModal(false);
    //     }
    // };

    const onSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        if (activeTab === "link-40") {
          if (addSelectedOrg !== "") {
            await addPermissions.mutate({
              id: selectedData?.id,
              organization_id: addSelectedOrg,
            });
          }
          if (deleteSelectedOrg !== "") {
            await deletePermissions({
              id: selectedData?.id,
              organization_id: deleteSelectedOrg,
            });
          }
        } else if (activeTab === "link-41") {
          if (addSelectedOperator) {
            await addOperators.mutate({
                id: selectedData?.id,
                integrator_id: organizationId,
                operator_id: addSelectedOperator,
              });
          }
          if (deleteSelectedOperator) {
            await deleteOperators.mutate({
              id: selectedData?.id,
              integrator_id: organizationId,
              operator_id: deleteSelectedOperator,
            });
          }
        }
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoading(false);
        setShowModal(false);
      }
    };

    const handleTabSelect = (k) => {
        setActiveTab(k);
    };

    useEffect(() => {
        setIsSubmitted(false);
        // setAddSelectedOrg(null);
        // setDeleteSelectedOrg(null);
        setAddSelectedOperator(null);
        setDeleteSelectedOperator(null);
        setOrganizationId(null);
    }, [activeTab]);

    useEffect(() => {
        if (organizationId) {
            refetchOperators();
        }
    }, [organizationId]);

    useEffect(() => {
        if (hasError) {
            toastAlert("error");
            setHasError(false);
            setError("");
        }
    }, [error, hasError]);

    return (
      <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
        <Modal.Header>
          <h6 className="modal-title">Edit Account Organizations</h6>
          <Button
            variant=""
            type="button"
            onClick={() => {
              setShowModal(false);
              setSelectedData(null);
            }}
          >
            <span aria-hidden="true" className="text-dark">
              X
            </span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="link-40" activeKey={activeTab} onSelect={handleTabSelect}>
            <div className="bg-gray-100 nav-bg">
              <Nav className="nav nav-tabs">
                <Nav.Item>
                  <Nav.Link eventKey="link-40">Organization</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-41">Operators</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="link-40">
                <form
                  className={`needs-validation${isSubmitted ? "" : ""}`}
                  onSubmit={(e) => {
                    setIsSubmitted(true);
                    onSubmit(e);
                  }}
                >
                  <Form.Group className="form-group">
                    <Form.Label>Add Organization</Form.Label>
                    <Select
                      className="form-control mr-0"
                      optionFilterProp="children"
                      dropdownStyle={{
                        zIndex: "9999999999",
                      }}
                      options={orgs}
                      placeholder="Select organizations to add..."
                      onChange={(value) => setAddSelectedOrg(value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Remove Organization</Form.Label>
                    <Select
                      className="form-control mr-0"
                      optionFilterProp="children"
                      dropdownStyle={{
                        zIndex: "9999999999",
                      }}
                      options={userOrgs}
                      placeholder="Select organizations to remove..."
                      onChange={(value) => setDeleteSelectedOrg(value)}
                    />
                  </Form.Group>
                  <Button
                    disabled={loading}
                    variant=""
                    type="submit"
                    style={{ minHeight: 51 }}
                    className="btn btn-primary btn-block tx-16"
                  >
                    {loading ? (
                      <span className="text-white">Loading...</span>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </form>
              </Tab.Pane>
              <Tab.Pane eventKey="link-41">
                <form
                  className={`needs-validation${isSubmitted ? "" : ""}`}
                  onSubmit={(e) => {
                    setIsSubmitted(true);
                    onSubmit(e);
                  }}
                >
                  <Form.Group className="form-group">
                    <Form.Label>Select Organization</Form.Label>
                    <Select
                      className="form-control mr-0"
                      optionFilterProp="children"
                      dropdownStyle={{
                        zIndex: "9999999999",
                      }}
                      options={userOrgs}
                      value={organizationId}
                      placeholder="Select organization..."
                      onChange={(value) => setOrganizationId(value)}
                    />

                    <Form.Label>Select Operator to add</Form.Label>
                    <Select
                      className="form-control mr-0"
                      optionFilterProp="children"
                      dropdownStyle={{
                        zIndex: "9999999999",
                      }}
                      loading={isFetching}
                      options={
                          operators?.map(item => ({
                            value: item?.id,
                            label: item?.name?.toUpperCase(),
                          })) || []
                      }
                      placeholder="Select operators to add..."
                      value={addSelectedOperator}
                      onChange={(value) => setAddSelectedOperator(value)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Select Operator to remove </Form.Label>
                    <Select
                      className="form-control mr-0"
                      optionFilterProp="children"
                      dropdownStyle={{
                        zIndex: "9999999999",
                      }}
                      options={
                        selectedData?.operators?.map(item => ({
                          value: item?.id,
                          label: item?.name?.toUpperCase(),
                        })) || []
                      }
                      value={deleteSelectedOperator}
                      placeholder="Select operators to remove..."
                      onChange={(value) => setDeleteSelectedOperator(value)}
                    />
                  </Form.Group>
                  <Button
                    disabled={loading}
                    variant=""
                    type="submit"
                    style={{ minHeight: 51 }}
                    className="btn btn-primary btn-block tx-16"
                  >
                    {loading ? (
                      <span className="text-white">Loading...</span>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </form>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    );
};
