import React from "react";
import { Collapse } from "react-bootstrap";

export const RequestBlock = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const requestData = {
    ...data?.request,
    wager: data?.request?.wager / 1000,
  };

  return (
    <div className="bd-b">
      <div
        className="d-flex align-items-center justify-content-between pb-2 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <h5 className="mb-0 text-info tx-bold tx-16">Request</h5>
        <i className={`ms-auto text-info fe fe-${open ? "minus" : "plus"}`}></i>
      </div>
      <Collapse in={open}>
        <div className="bg-gray-200 radius-10 overflow-hidden mb-3">
          <pre>{JSON.stringify(requestData, null, " ")}</pre>
        </div>
      </Collapse>
    </div>
  );
};
