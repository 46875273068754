import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotificationContext = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
    const [downloadsBadge, setDownloadsBadge] = useState(false);

    const updateDownloadsBadge = (status) => {
        setDownloadsBadge(status);
    };

    const markUserVisitedDownloads = () => {
        setDownloadsBadge(false);
    };

    const resetDownloadsBadge = () => {
        setDownloadsBadge(false);
    };

    return (
        <NotificationContext.Provider value={
            {
            downloadsBadge,
            updateDownloadsBadge,
            markUserVisitedDownloads,
            resetDownloadsBadge }
        }>
            {children}
        </NotificationContext.Provider>
    );
};
