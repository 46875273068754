import {stages} from "../../helpers/stages/stages";

export function calculateFiguresIndexes (gameName, data, key) {
    let currentFigures = [];
    let currentTopFigures = [];

    for (let i = 0; i <= key; i++) {
        const currentStage = stages(gameName, data)?.[i];
        const {
            new_figures_position,
            new_reel_figures,
            payouts,
            new_top_figures
        } = currentStage || {};

        if (new_reel_figures && new_reel_figures.length > 0) {
            new_reel_figures.forEach((newFigure) => {
                const duplicateFigures = currentFigures.filter(
                    (figSet) => figSet[0]?.x === newFigure.x && figSet[0]?.y === newFigure.y
                );

                if (duplicateFigures.length > 0) {
                    duplicateFigures.forEach((duplicateSet) => {
                        duplicateSet.push(newFigure);
                    });
                } else {
                    currentFigures.push([newFigure]);
                }
            });
        }

        if (new_top_figures && new_top_figures.length > 0) {
            new_top_figures.forEach((newFigure) => {
                currentTopFigures.findIndex(
                    (topFig) => topFig.x === newFigure.x
                );
                currentTopFigures.push(newFigure);
            });
        }

        if (new_figures_position) {
            currentFigures = currentFigures.map((figureSet) => {
                if (i !== key) {
                    return figureSet.map((figure) => {
                        const newPosition = new_figures_position.find((newFigure) => newFigure.id === figure?.id);
                        if (newPosition) {
                            return {
                                ...figure,
                                y: newPosition.y,
                            };
                        } else {
                            return figure;
                        }
                    });
                } else {
                    return figureSet;
                }
            });

            currentTopFigures = currentTopFigures.map((figure) => {
                const newPosition = new_figures_position.find((newTopFigure) => newTopFigure.id === figure.id);
                if (newPosition) {
                    return {
                        ...figure,
                        x: newPosition.x,
                    };
                } else {
                    return figure;
                }
            });
        }

        if (i !== key) {
            const payoutFigureIds = [];

            currentFigures = currentFigures.map((figureSet) => {
                return figureSet.filter(
                    (fig) => !fig?.is_special || fig.name === 'f' || fig.name === 'w'
                );
            });

            currentTopFigures = currentTopFigures.filter(
                (topFig) => !topFig.is_special || topFig.name === 'f' || topFig.name === 'w'
            );

            payouts?.values?.forEach((payout) => {
                if (payout.figures) {
                    payoutFigureIds.push(...payout.figures);
                }
            });

            currentFigures = currentFigures.map((figureSet) => {
                return figureSet.filter(
                    (fig) =>
                        !payoutFigureIds.includes(fig?.id) &&
                        (!fig?.is_special || fig.name === 'f' || fig.name === 'w')
                );
            });

            currentTopFigures = currentTopFigures.filter(
                (topFig) =>
                    !payoutFigureIds.includes(topFig.id) &&
                    (!topFig.is_special || topFig.name === 'f' || topFig.name === 'w')
            );
        }
    }
    return {currentFigures, currentTopFigures}
}