import React, { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  createSearchParams,
  Link,
} from "react-router-dom";
import { Col, Row, Card } from "react-bootstrap";
import { Table } from "../../../components/Tables";
import { DatePicker, Select } from "antd";
import moment from "moment";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getSpinsList, getGames, getSpinsFile } from "../../../services";
import { useCurrency } from "../../../contexts";
import { convertToCurrency, copyToClipboard } from "../../../utilities";
import { useProfile } from "../../../contexts";
import { Slide, toast } from "react-toastify";
import dayjs from "dayjs";
import { useNotificationContext } from "../../../contexts/notificationContext";
import { useWebSocketContext } from "../../../contexts/websocketContext";
import { getIdToken } from "../../../services/httpClient";
import "./Gameplays.scss";
import { useDemo } from "../../../contexts/demoContext";

export default function Gameplays() {
  const { sourceUrl, setSourceUrl } = useWebSocketContext();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [data, setData] = React.useState();

  const { resetDownloadsBadge } = useNotificationContext();
  const [orderParams, setOrderParams] = useState("created_at desc");
  const { currency } = useCurrency();
  const { organizationId } = useProfile();
  const navigate = useNavigate();
  const location = useLocation();
  const { is_demo } = useDemo();
  const queryParams = new URLSearchParams(location.search);

  const initialParams = {
    starting_from: "",
    ending_at: "",
    session_id: "",
    game: "",
    round_id: "",
    external_user_id: "",
    integrator: queryParams.get("integrator") || "",
    operator: queryParams.get("operator") || "",
  };

  const [params, setParams] = useState(initialParams);

  const refetchTimeout = useRef(null);
  const isMountedRef = useRef(false);

  const selectedIntegrator = useSelector(
    (state) => state.filters.selectedIntegrator
  );

  const selectedOperator = useSelector(
    (state) => state.filters.selectedOperator
  );

  const { isLoading, refetch, isRefetching } = useQuery(
    [
      "get-spins",
      {
        is_demo,
        currency,
        limit,
        page,
        ...params,
        starting_from: params.starting_from
          ? moment(params.starting_from?.$d).format("YYYY-MM-DD HH:mm:00Z")
          : "",
        ending_at: params.ending_at
          ? moment(params.ending_at?.$d).format("YYYY-MM-DD HH:mm:59Z")
          : "",
        order: orderParams,
        ...(selectedIntegrator && {
          integrator: selectedIntegrator,
        }),
        ...(selectedOperator && {
          operator: selectedOperator,
        }),
      },
    ],
    getSpinsList,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        console.log(data, "data");
        setData(data);
      },
    }
  );

  const { refetch: fetchSpinsFile } = useQuery(
    [
      "spins-file",
      {
        is_demo,
        currency,
        ...params,
        starting_from: params.starting_from
          ? moment(params.starting_from?.$d).format("YYYY-MM-DD HH:mm:00Z")
          : "",
        ending_at: params.ending_at
          ? moment(params.ending_at?.$d).format("YYYY-MM-DD HH:mm:59Z")
          : "",
      },
    ],
    getSpinsFile,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: (data) => {
        const newSourceUrl = `${sourceUrl}${data.id}?token=${getIdToken()}`;
        setSourceUrl(newSourceUrl);
      },
    }
  );

  const { data: games } = useQuery(["games"], getGames, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success" ? "Copied successfully" : "Couldn't copy"}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 1000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const columns = [
    {
      className: "text-center",
      key: "id",
      name: "Round ID",
      highlight: true,
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/gameplays/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate mx-auto"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data}
          </Link>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data, toastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "session_token",
      name: "Session ID",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/sessions/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data}
          </Link>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data, toastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "external_user_id",
      name: "User ID",
      render: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <Link
            to={`/reports/users/${data}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data}
          </Link>
          <button
            className="btn tx-10 p-1"
            onClick={() => copyToClipboard(data, toastAlert)}
          >
            <i className="mdi mdi-content-copy"></i>
          </button>
        </div>
      ),
    },
    {
      className: "text-center",
      key: "is_shown",
      name: "Status",
      render: (data) => (data ? "SHOWN" : "NOT SHOWN"),
    },
    {
      className: "text-center",
      key: "game",
      name: "Game name",
    },
    {
      className: "text-center",
      key: "integrator",
      name: "Integrator",
    },
    {
      className: "text-center",
      key: "wagerGameRounds",
      name: "Wager",
      render: (data) => convertToCurrency(data?.wager, currency),
    },
    {
      className: "text-center",
      key: "all",
      name: "Award",
      render: (data) => convertToCurrency(data?.final_award, currency),
    },
    {
      className: "text-center",
      key: "is_pfr",
      name: "PFR",
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      className: "text-center",
      key: "created_at",
      name: "Created at",
      render: (data) => (
        <>
          {!data ? (
            "-"
          ) : (
            <>
              <span className="d-block">
                {moment(data).format("DD-MM-YYYY")}
              </span>
              <span className="d-block">
                {moment(data).format("HH:mm UTC")}
              </span>
            </>
          )}
        </>
      ),
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  const handleSubmit = () => {
    setPage(1);
    const formattedStartingFrom = params.starting_from
      ? params.starting_from.toISOString()
      : "";
    const formattedEndingAt = params.ending_at
      ? params.ending_at.toISOString()
      : "";
    navigate({
      pathname: "/reports/gameplays/",
      search: `?${createSearchParams({
        ...params,
        starting_from: formattedStartingFrom,
        ending_at: formattedEndingAt,
      })}`,
    });
    refetch();
  };

  useEffect(() => {
    setParams({
      starting_from: queryParams.get("starting_from")
        ? dayjs(queryParams.get("starting_from"), "YYYY-MM-DD HH:mm:ssZ")
        : "",
      ending_at: queryParams.get("ending_at")
        ? dayjs(queryParams.get("ending_at"), "YYYY-MM-DD HH:mm:ssZ")
        : "",
      status: queryParams.get("status") || "",
      game: queryParams.get("game") || "",
      session_id: queryParams.get("session_id") || "",
      external_user_id: queryParams.get("external_user_id") || "",
      integrator: queryParams.get("integrator") || "",
      operator: queryParams.get("operator") || "",
      round_id: queryParams.get("round_id") || "",
    });
    isMountedRef.current = true;
    clearTimeout(refetchTimeout.current);
    refetchTimeout.current = setTimeout(() => {
      if (isMountedRef.current) {
        refetch();
      }
    }, 20);

    isMountedRef.current = true;
  }, [location, refetch]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [
    page,
    limit,
    currency,
    organizationId,
    is_demo,
    orderParams,
    selectedIntegrator,
    selectedOperator,
  ]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Game Rounds
          </span>
        </div>
      </div>
      <Row>
        <form
          className="wd-95p"
          style={{ marginLeft: "30px" }}
          id="filters"
          onSubmit={(e) => e.preventDefault()}
        >
          <h5>Filters</h5>
          <div className="row px-0">
            <div className="form-group col-4">
              <label className="form-label">1. Start and end date</label>
              <div className="d-flex align-items-center">
                <DatePicker
                  placeholder="Select Start Date..."
                  className="form-control me-4"
                  clearIcon={null}
                  suffixIcon={null}
                  popupClassName={"timepicker"}
                  minuteStep={5}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                    format: "HH:mm",
                  }}
                  value={params.starting_from}
                  onChange={(e) => setParams({ ...params, starting_from: e })}
                  disabledDate={(current) => {
                    if (params.ending_at) {
                      let customDate = moment(params.ending_at?.$d).format(
                        "YYYY-MM-DD HH:mm"
                      );
                      return (
                        current &&
                        current > moment(customDate, "YYYY-MM-DD HH:mm")
                      );
                    }
                    return false;
                  }}
                />
                <DatePicker
                  placeholder="Select End Date..."
                  className="form-control mr-0"
                  clearIcon={null}
                  suffixIcon={null}
                  minuteStep={5}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                    format: "HH:mm",
                  }}
                  value={params.ending_at}
                  onChange={(e) => setParams({ ...params, ending_at: e })}
                  disabledDate={(current) => {
                    if (params.starting_from) {
                      let customDate = moment(params.starting_from?.$d).format(
                        "YYYY-MM-DD HH:mm"
                      );
                      return (
                        current &&
                        current < moment(customDate, "YYYY-MM-DD HH:mm")
                      );
                    }
                    return false;
                  }}
                />
              </div>
            </div>
            <div className="form-group col-2">
              <label className="form-label">2. Game</label>
              <Select
                className="form-control mr-0"
                showSearch
                placeholder="Select Game..."
                optionFilterProp="label"
                value={params.game || null}
                onChange={(game) => setParams({ ...params, game })}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  games?.map((item) => ({
                    value: item?.name,
                    label: item?.name?.toUpperCase(),
                  })) || []
                }
              />
            </div>
            <div className="form-group col-2">
              <label className="form-label">3. Session ID</label>
              <input
                placeholder="Enter Session ID(s)..."
                className="form-control"
                type="text"
                value={params.session_id}
                onChange={(e) =>
                  setParams({ ...params, session_id: e.target.value })
                }
              />
            </div>
            <div className="form-group col-2">
              <label className="form-label">4. User ID</label>
              <input
                placeholder="Enter User ID(s)..."
                className="form-control"
                type="text"
                value={params.external_user_id}
                onChange={(e) =>
                  setParams({ ...params, external_user_id: e.target.value })
                }
              />
            </div>
            <div className="form-group col-2">
              <label className="form-label">5. Round ID</label>
              <input
                placeholder="Enter Round ID(s)..."
                className="form-control"
                type="text"
                value={params.round_id}
                onChange={(e) =>
                  setParams({ ...params, round_id: e.target.value })
                }
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between my-3">
            <button
              className="btn btn-secondary"
              onClick={(e) => setParams(initialParams)}
            >
              Discard
            </button>
            <div className="d-flex alig-items-center flex-row justify-content-end">
              <button
                className="btn btn-info me-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  fetchSpinsFile();
                  resetDownloadsBadge();
                }}
              >
                Export
              </button>
            </div>
          </div>
        </form>
        <Col sm={12} className="col-12">
          <Card>
            <Card.Body className="pt-4 example1-table">
              <Table
                loading={isLoading || isRefetching}
                data={data ? data.items : []}
                columns={columns}
                paginate={paginate}
                total={data ? data?.total : 0}
                setOrderParams={setOrderParams}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
