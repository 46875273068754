import React, {useEffect, useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { addRolePermission, deleteRolePermission } from "../../../services";
import { toast, Slide } from "react-toastify";
import { Select } from "antd";

export const EditRolePermissionsModal = ({ setShowModal, selectedData, permissions, rolePermissions, setSelectedData, refetch }) => {
    const client = useQueryClient();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const [addPermissionsSelected, setAddPermissionsSelected] = useState([]);
    const [deletePermissionsSelected, setDeletePermissionsSelected] = useState([]);

    const addPermissions = useMutation(addRolePermission, {
        onSuccess: () => {
            toastAlert("success");
            client.invalidateQueries("roles");
            refetch();
        },
        onError: (error) => {
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const { mutate: deletePermissions } = useMutation(deleteRolePermission, {
        onSuccess: () => {
            toastAlert("success");
            client.invalidateQueries("roles");
            refetch();
        },
        onError: (error) => {
            setError(error.response.data.data);
            setHasError(true);
            setShowModal(false);
            selectedData && setSelectedData(null);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Role permission were updated successfully`
                    : `Error: ${error}`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = () => {
        setLoading(true);

        if (addPermissionsSelected.length > 0) {
            addPermissions.mutate({
                id: selectedData?.id,
                permissions: addPermissionsSelected,
            });
        }

        if (deletePermissionsSelected.length > 0) {
            deletePermissions({
                id: selectedData?.id,
                permissions: deletePermissionsSelected,
            });
        }

        setLoading(false);
        setShowModal(false);
    };

    useEffect(() => {
        if (hasError) {
            toastAlert("error");
            setHasError(false);
        }
    }, [error, hasError]);

    return (
        <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
            <Modal.Header>
                <h6 className="modal-title">Edit Role Permission</h6>
                <Button variant="" type="button" onClick={() => {
                    setShowModal(false);
                    setSelectedData(null);
                }}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <form
                    className={`needs-validation${isSubmitted ? "" : ""}`}
                    onSubmit={e => {
                        setIsSubmitted(true);
                        onSubmit(e);
                    }}
                >
                    <Form.Group className="form-group">
                        <Form.Label>Add Permissions</Form.Label>{" "}
                        <Select
                            mode="multiple"
                            className="form-control mr-0"
                            optionFilterProp="children"
                            maxTagTextLength={7}
                            maxTagCount={4}
                            dropdownStyle={{
                                zIndex: '9999999999'
                            }}
                            options={permissions}
                            placeholder="Select permissions to add..."
                            onChange={(value) => setAddPermissionsSelected(value)}
                        />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Delete Permissions</Form.Label>{" "}
                        <Select
                            mode="multiple"
                            className="form-control mr-0"
                            optionFilterProp="children"
                            maxTagTextLength={5}
                            maxTagCount={4}
                            dropdownStyle={{
                                zIndex: '9999999999'
                            }}
                            options={rolePermissions}
                            placeholder="Select permissions to delete..."
                            onChange={(value) => setDeletePermissionsSelected(value)}
                        />
                    </Form.Group>
                    <Button
                        disabled={loading}
                        variant=""
                        type="submit"
                        style={{ minHeight: 51 }}
                        className="btn btn-primary btn-block tx-16"
                    >
                        {loading ? (
                            <span className="text-white">
                  Loading...
                </span>
                        ) : (
                            "Update"
                        )}
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};
