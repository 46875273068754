import httpClient from "./httpClient";

//Users

export const getUsers = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/accounts?${params.toString()}`);
  if (data) {
    return data.data;
  }
};

export const createUser = async formData => {
  const { data } = await httpClient.post("/accounts", { ...formData });
  if (data) {
    return data.data;
  }
};

export const updateUser = async formData => {
  const { data } = await httpClient.put(`/accounts/${formData.id}`, { ...formData });
  if (data) {
    return data.data;
  }
};

export const addAccountsOrganization = async formData => {
  const { data } = await httpClient.post(`/accounts/${formData?.id}/operators`, {
    integrator_id: formData.integrator_id,
    operator_id: formData.operator_id,
  });
  if (data) {
    return data.data;
  }
};

export const deleteAccountsOrganization = async formData => {
  const { data } = await httpClient.delete(`/accounts/${formData?.id}/operators`, {
    data: {
      integrator_id: formData.integrator_id,
      operator_id: formData.operator_id,
    },
  });
  if (data) {
    return data.data;
  }
};

export const addUserOrganization = async formData => {
  const { data } = await httpClient.post(`/accounts/${formData?.id}/organizations`, {
    organization_id: formData.organization_id,
  });
  if (data) {
    return data.data;
  }
};

export const deleteUserOrganization = async formData => {
  const { data } = await httpClient.delete(
      `/accounts/${formData?.id}/organizations`, {
        data: {
          organization_id: formData.organization_id,
        },
      }
  );
  if (data) {
    return data.data;
  }
};

export const addUserRole = async formData => {
  const { data } = await httpClient.post(`/accounts/${formData?.id}/roles`, {
    role_id: formData?.add_role_id,
  });
  if (data) {
    return data.data;
  }
};

export const deleteUserRole = async formData => {
  const { data } = await httpClient.delete(
      `/accounts/${formData?.id}/roles`, {
        data: {
          role_id: formData?.role_id,
        },
      }
  );
  if (data) {
    return data.data;
  }
};

export const deleteUser = async id => {
  const { data } = await httpClient.delete(`/accounts/${id}`);
  if (data) {
    return data.data;
  }
};

//Roles

export const getRoles = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/roles?${params.toString()}`);
  if (data) {
    return queryKey?.[1]?.userType && queryKey?.[1]?.userType !== "root"
      ? data.data?.filter(
          role => role?.type !== "admin" && role?.type !== "root"
        )
      : data.data;
  }
};

export const createRole = async formData => {
  const { data } = await httpClient.post("/roles", { ...formData });
  if (data) {
    return data.data;
  }
};

export const updateRole = async formData => {
  const { data } = await httpClient.put(`/roles/${formData.id}`, { ...formData });
  if (data) {
    return data.data;
  }
};

export const deleteRole = async id => {
  const { data } = await httpClient.delete(`/roles/${id}`);
  if (data) {
    return data.data;
  }
};
