import { Box, Button } from "@material-ui/core";
import React from "react";

interface Props {
    isVisibleIframe: boolean;
    toggle: () => void;
    reload: () => void;
}

const IframeControl = (props: Props) => {
    const { toggle, reload, isVisibleIframe } = props;

    return (
        <Box display="flex">
            {!isVisibleIframe && (
                <button className="btn btn-outline-light" onClick={toggle}>
                    Run in iframe
                </button>
            )}
            {isVisibleIframe && (
                <button className="btn btn-outline-light" onClick={toggle}>
                    Hide game
                </button>
            )}
            {isVisibleIframe && (
                <Box ml={2}>
                    <button className="btn btn-outline-light" onClick={reload}>
                        Reload iframe
                    </button>
                </Box>
            )}
        </Box>
    );
};

export { IframeControl };
