import { Box, FormControl } from "@material-ui/core";
import { CustomCheckbox } from "./Checkbox";
import { FreeSpinButton } from "./FreeSpinButton";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getGameUrl } from "../../../services";
import { Slide, toast } from "react-toastify";

interface Props {
  game: any;
  env: string;
  integrator: string;
  setSearchParams(s: string): void;
  setSession(s: string): void;
}

const MockQueryBuilder = (props: Props) => {
  const { game, integrator, env, setSearchParams, setSession } = props;

  const [userID, setUserID] = useState(uuid());
  const [sessionID, setSessionID] = useState(uuid());

  const [isLowBalance, setIsLowBalance] = useState(false);
  const [isCheatsShown, setIsCheatsShown] = useState(false);
  const [isLobbyUrl, setIsLobbyUrl] = useState(false);
  const [isShortLink, setIsShortLink] = useState(false);
  const [loading, setLoading] = useState(false);

  const [jurisdiction, setJurisdiction] = useState(game.jurisdictions[0]);
  const [currency, setCurrency] = useState(game.currencies[0]);
  const [userLocale, setUserLocale] = useState("en_US");
  const [rtp, setRtp] = useState("");
  const [volatility, setVolatility] = useState("");
  const [wagerSet, setWagerSet] = useState<any>(null);

  const generateSession = () => {
    const formData: any = {
      game: game.name,
      user_id: userID,
      integrator: integrator,
      show_cheats: isCheatsShown,
      lobby_url: isLobbyUrl ? window.location.href : "",
      low_balance: isLowBalance,
      short_link: isShortLink,
      user_locale: userLocale,
      currency: currency,
      jurisdiction: jurisdiction,

      // session_id: sessionID,
      // wager_set_id: wagerSet
    };

    if (isShortLink) {
      formData.session_id = sessionID;
      formData.wager_set_id = wagerSet;
    }

    if (volatility && isShortLink) {
      formData.volatility = volatility;
    }
    if (rtp && isShortLink) {
      formData.rtp = +rtp;
    }

    setLoading(true);
    mutate(formData);
  };

  const { mutate } = useMutation(getGameUrl, {
    onSuccess: (data) => {
      toastAlert("success");
      setSession(data);
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const toastAlert = (type: any) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Session generated successfully`
          : `Failed to generate session`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const buildSearchParams = (): string => {
    const sp = new URLSearchParams();

    sp.set("user_id", userID);
    sp.set("jurisdiction", jurisdiction);
    sp.set("currency", currency);
    sp.set("user_locale", userLocale.replace("_", "-"));
    sp.set("integrator", integrator);

    if (isLowBalance) {
      sp.set("low_balance", "true");
    }

    if (isCheatsShown) {
      sp.set("showcheats", "true");
    }

    if (isLobbyUrl) {
      sp.set("lobbyurl", window.location.href);
    }

    return sp.toString();
  };

  useEffect(() => {
    if (game?.available_rtp && game?.available_rtp?.length > 0) {
      setRtp(game.available_rtp[0]);
    }

    if (game?.available_volatility && game?.available_volatility?.length > 0) {
      setVolatility(game.available_volatility[0]);
    }

    if (game?.available_wager_sets && game?.available_wager_sets?.length > 0) {
      setWagerSet(game.available_wager_sets[0].id);
    }
  }, [game]);

  useEffect(() => {
    setSearchParams(buildSearchParams());
  }, [
    userID,
    isLowBalance,
    isCheatsShown,
    isLobbyUrl,
    jurisdiction,
    integrator,
    currency,
    userLocale,
    env,
    setSearchParams,
    buildSearchParams,
  ]);

  return (
    <div>
      <div className="d-flex pt-2 align-items-end">
        <form className="wd-35p" noValidate autoComplete="off">
          <p className="form-label">User</p>
          <input
            className="form-control"
            value={userID}
            onChange={() => {
              return null;
            }}
          />
        </form>
        <button
          onClick={() => setUserID(uuid())}
          className="btn btn-primary mg-l-4"
        >
          Generate
        </button>
      </div>

      {/* checkboxes */}
      <div className="d-flex flex-column">
        <div className="d-flex flex-row wd-50p mt-3 px-3 justify-content-between">
          <CustomCheckbox
            name="low balance"
            onChange={() => setIsLowBalance(!isLowBalance)}
            value={isLowBalance}
          />
          <CustomCheckbox
            name="cheats"
            onChange={() => setIsCheatsShown(!isCheatsShown)}
            value={isCheatsShown}
          />
          <CustomCheckbox
            name="lobbyurl"
            onChange={() => setIsLobbyUrl(!isLobbyUrl)}
            value={isLobbyUrl}
          />
          <CustomCheckbox
            name="short link"
            onChange={() => setIsShortLink(!isShortLink)}
            value={isShortLink}
          />
        </div>

        {/* selects */}
        <div className="d-flex flex-row wd-60p mt-3 px-3 justify-content-between">
          <FormControl className="wd-30p">
            <p className="form-label">Jurisdiction</p>
            <select
              name={"jurisdiction"}
              className="form-control mb-2"
              value={jurisdiction}
              onChange={(e) => setJurisdiction(e.target.value)}
            >
              {game.jurisdictions?.map(
                (
                  jur:
                    | boolean
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined,
                  index: number
                ) => {
                  // eslint-disable-next-line react/jsx-key
                  return <option key={index}>{jur}</option>;
                }
              )}
            </select>
          </FormControl>

          <FormControl className="wd-30p">
            <p className="form-label">Currency</p>
            <select
              name={"currency"}
              className="form-control mb-2"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              {game.currencies?.map(
                (
                  cur:
                    | boolean
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined,
                  index: number
                ) => {
                  // eslint-disable-next-line react/jsx-key
                  return <option key={index}>{cur}</option>;
                }
              )}
            </select>
          </FormControl>

          <FormControl className="wd-30p">
            <p className="form-label">Locale</p>
            <select
              name={"userLocale"}
              value={userLocale}
              className="form-control mb-2"
              onChange={(e) => setUserLocale(e.target.value)}
            >
              {game.user_locales?.map(
                (
                  loc:
                    | boolean
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined,
                  index: number
                ) => {
                  // eslint-disable-next-line react/jsx-key
                  return <option key={index}>{loc}</option>;
                }
              )}
            </select>
          </FormControl>
        </div>

        {isShortLink && (
          <div className="d-flex pt-2 align-items-end">
            <form className="wd-35p" noValidate autoComplete="off">
              <p className="form-label">Session ID</p>
              <input
                className="form-control"
                value={sessionID}
                onChange={() => {
                  return null;
                }}
              />
            </form>
            <button
              onClick={() => setSessionID(uuid())}
              className="btn btn-primary mg-l-4"
            >
              Generate
            </button>
          </div>
        )}

        {isShortLink && (
          <div className="d-flex flex-row wd-60p mt-3 px-3 justify-content-between">
            <FormControl className="wd-30p">
              <p className="form-label">RTP</p>
              <select
                name={"rtp"}
                value={rtp}
                className="form-control mb-2"
                onChange={(e) => setRtp(e.target.value)}
              >
                {game.available_rtp?.map((rtpOption: number, index: number) => (
                  <option key={index} value={rtpOption}>
                    {rtpOption}
                  </option>
                ))}
              </select>
            </FormControl>

            <FormControl className="wd-30p">
              <p className="form-label">Volatility</p>
              <select
                name={"volatility"}
                value={volatility}
                className="form-control mb-2"
                onChange={(e) => setVolatility(e.target.value)}
              >
                {game.available_volatility?.map(
                  (volatility: number, index: number) => (
                    <option key={index} value={volatility}>
                      {volatility}
                    </option>
                  )
                )}
              </select>
            </FormControl>

            <FormControl className="wd-30p">
              <p className="form-label">Wager set</p>
              <select
                name={"wager_set"}
                value={wagerSet}
                className="form-control mb-2"
                onChange={(e) => setWagerSet(e.target.value)}
              >
                {game.available_wager_sets?.map((wager: any, index: number) => (
                  <option key={index} value={wager?.id} label={wager?.name}>
                    {wager?.name}
                  </option>
                ))}
              </select>
            </FormControl>
          </div>
        )}
      </div>
      <Box my={5}>
        <FreeSpinButton
          gameName={game.name}
          userID={userID}
          currency={currency}
          env={env}
          gameOwner={game.owner}
          gameWagerSet={game.wager_set}
          integrator={integrator}
        />
      </Box>

      <button
        disabled={loading}
        onClick={() => generateSession()}
        className="btn btn-primary mg-l-4"
      >
        Generate Session
      </button>
    </div>
  );
};

function uuid() {
  return (`${1e7}` + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export default MockQueryBuilder;
