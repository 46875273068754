import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  addJurisdiction,
  addLanguage,
  addLocale,
  deleteJurisdiction,
  deleteLanguage,
  deleteLocale,
  getCurrenciesData,
  getGameInfo,
  getJurisdictions,
  getLanguages,
  getLocales,
  getWagerSets,
  updateGame,
} from "../../../services";
import { Slide, toast } from "react-toastify";
import { Select } from "antd";
import { useProfile } from "../../../contexts";

const GameDetail = () => {
  const { organizationId } = useProfile();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [locales, setLocales] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [wagerSets, setWagerSets] = useState([]);
  const [defaultWagerSet, setDefaultWagerSet] = useState(null);
  const [rtp, setRtp] = useState([]);
  const [volatility, setVolatility] = useState([]);
  const [newOptionRtp, setNewOptionRtp] = useState("");
  const [newOptionVolatility, setNewOptionVolatility] = useState("");
  const [newOptionRtpError, setNewOptionRtpError] = useState("");
  const [newOptionVolatilityError, setNewOptionVolatilityError] = useState("");
  const [newLocale, setNewLocale] = useState("");
  const [newLocaleError, setNewLocaleError] = useState("");
  const [newJurisdiction, setNewJurisdiction] = useState("");
  const [newJurisdictionError, setNewJurisdictionError] = useState("");
  const [newLanguage, setNewLanguage] = useState("");
  const [newLanguageError, setNewLanguageError] = useState("");
  const [gambleDouble, setGambleDouble] = useState("");
  const [gambleDoubleUpError, setGambleDoubleUpError] = useState("");

  const [selectedData, setSelectedData] = useState({
    name: "",
    api_url: "",
    client_url: "",
    organization_id: "",
    wager_set_id: "",
    available_wager_sets_id: [],
    currencies: [],
    jurisdictions: [],
    languages: [],
    user_locales: [],
    is_demo: false,
    is_freespins: false,
    is_public: false,
    is_statistic_shown: false,
    online_volatility: false,
    available_rtp: [],
    rtp: "",
    available_volatility: ["low", "medium", "high"],
    volatility: "",
    gambleDoubleUp: "",
  });

  const validateGambleDouble = (value) => {
    const intValue = parseInt(value, 10);
    if (!value && value !== 0) {
      setGambleDoubleUpError("Please enter a value.");
      return true;
    }
    if (
      !Number.isInteger(intValue) ||
      isNaN(intValue) ||
      intValue < 0 ||
      intValue > 10
    ) {
      setGambleDoubleUpError("Value must be an integer between 0 and 10.");
      return false;
    }
    setGambleDoubleUpError("");
    return true;
  };

  const addNewItem = (
    itemName,
    newItem,
    setNewItemError,
    items,
    onAddItem,
    setNewItem
  ) => {
    if (!newItem) {
      setNewItemError(`Please enter ${itemName.toLowerCase()} to edit.`);
      return;
    }

    let itemValue = newItem;
    if (typeof newItem === "object") {
      itemValue = newItem.value;
    }

    const itemExists = items.some((existingItem) => {
      if (typeof existingItem === "object") {
        return existingItem.value === itemValue;
      } else {
        return existingItem === itemValue;
      }
    });

    if (itemExists) {
      setNewItemError(
        `This ${itemName.toLowerCase()} already exists in the list.`
      );
      return;
    }

    setLoading(true);
    const newItemData = {
      value: newItem,
    };
    setNewItemError("");
    onAddItem(newItemData);
    setNewItem("");
  };

  const addNewLocale = () => {
    addNewItem(
      "Locale",
      newLocale,
      setNewLocaleError,
      locales,
      onAddLocale,
      setNewLocale
    );
  };

  const addNewLanguage = () => {
    addNewItem(
      "Language",
      newLanguage,
      setNewLanguageError,
      languages,
      onAddLanguage,
      setNewLanguage
    );
  };

  const addNewJurisdiction = () => {
    addNewItem(
      "Jurisdiction",
      newJurisdiction,
      setNewJurisdictionError,
      jurisdictions,
      onAddJurisdiction,
      setNewJurisdiction
    );
  };

  const handleDeleteItem = (
    itemName,
    item,
    setItemError,
    items,
    onDeleteItem,
    setItem
  ) => {
    if (!item) {
      setItemError(`Please enter ${itemName.toLowerCase()} to edit.`);
      return;
    }

    let itemValue = item;
    if (typeof item === "object") {
      itemValue = item.value;
    }

    const itemExists = items.some((existingItem) => {
      if (typeof existingItem === "object") {
        return existingItem.value === itemValue;
      } else {
        return existingItem === itemValue;
      }
    });

    if (!itemExists) {
      setItemError(`This ${itemName.toLowerCase()} is not listed.`);
      return;
    }

    setLoading(true);

    setItemError("");
    onDeleteItem(item);
    setItem("");
  };

  const handleDeleteLanguage = () => {
    handleDeleteItem(
      "Language",
      newLanguage,
      setNewLanguageError,
      languages,
      onDeleteLanguage,
      setNewLanguage
    );
  };

  const handleDeleteJurisdiction = () => {
    handleDeleteItem(
      "Jurisdiction",
      newJurisdiction,
      setNewJurisdictionError,
      jurisdictions,
      onDeleteJurisdiction,
      setNewJurisdiction
    );
  };

  const handleDeleteLocale = () => {
    handleDeleteItem(
      "Locale",
      newLocale,
      setNewLocaleError,
      locales,
      onDeleteLocale,
      setNewLocale
    );
  };

  const addNewOptionRtp = () => {
    const newOptionValue = parseFloat(newOptionRtp.trim().replace(",", "."));

    if (
      isNaN(newOptionValue) ||
      newOptionValue < 50 ||
      newOptionValue > 100 ||
      !Number.isInteger(newOptionValue)
    ) {
      setNewOptionRtpError(
        "Please enter a valid integer value between 50 and 100."
      );
      return;
    }

    const newOptionIntValue = parseInt(newOptionValue, 10);

    if (selectedData.available_rtp.includes(newOptionValue)) {
      // if (selectedData.available_rtp.includes(newOptionValue.toString())) {
      setNewOptionRtpError("This RTP value already exists in the list.");
      return;
    }

    setSelectedData((prevData) => ({
      ...prevData,
      available_rtp: [...prevData.available_rtp, newOptionIntValue].sort(
        (a, b) => a - b
      ),
    }));
    setNewOptionRtp("");
    setNewOptionRtpError("");
  };

  const addNewOptionVolatility = () => {
    const newOptionValue = newOptionVolatility.trim().toLowerCase();

    const validValues = ["low", "low-medium", "medium", "medium-high", "high"];

    if (!validValues.includes(newOptionValue)) {
      setNewOptionVolatilityError(
        "Invalid volatility value. Please enter 'low', 'medium', 'high', or combinations like 'low-medium', 'medium-high'."
      );
      return;
    }

    if (selectedData.available_volatility.includes(newOptionValue)) {
      setNewOptionVolatilityError(
        "This volatility value already exists in the list."
      );
      return;
    }

    const updatedVolatility = [
      ...selectedData.available_volatility,
      newOptionValue,
    ].sort((a, b) => {
      const order = {
        low: 1,
        "low-medium": 2,
        medium: 3,
        "medium-high": 4,
        high: 5,
      };
      return order[a] - order[b];
    });

    setSelectedData((prevData) => ({
      ...prevData,
      available_volatility: updatedVolatility,
    }));

    setNewOptionVolatility("");
    setNewOptionVolatilityError("");
  };

  const handleInputGamble = (value) => {
    if (/^\d{0,2}$/.test(value)) {
      addGambleDouble(value);
    }
  };

  const addGambleDouble = (gamble) => {
    if (validateGambleDouble(gamble)) {
      setSelectedData((prevData) => ({
        ...prevData,
        gambleDoubleUp: parseInt(gamble, 10),
      }));
      setGambleDouble(gamble);
    }
  };

  const removeItem = (key) => (indexesToSave) => {
    setSelectedData((prevData) => {
      const updatedItems = prevData[key].filter((_, index) =>
        indexesToSave.includes(index)
      );

      if ("available_rtp" === key && !updatedItems.includes(rtp)) {
        setRtp(updatedItems[0] || "");
        updateSelectedData({ rtp: updatedItems[0] || "" });
      }

      if (
        "available_volatility" === key &&
        !updatedItems.includes(volatility)
      ) {
        setVolatility(updatedItems[0] || "");
        updateSelectedData({ volatility: updatedItems[0] || "" });
      }

      return {
        ...prevData,
        [key]: updatedItems,
      };
    });
  };

  const { data, isLoading, refetch } = useQuery(
    ["game-detail", { id }],
    getGameInfo,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { refetch: refetchCurrencies } = useQuery(
    ["currencies"],
    getCurrenciesData,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const currencyArray = data.map((currency) => ({
          label: currency.toUpperCase(),
          value: currency.toUpperCase(),
        }));
        setCurrencies(currencyArray);
      },
    }
  );

  const { refetch: refetchJurisdictions } = useQuery(
    ["jurisdictions"],
    getJurisdictions,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const jurArray = data.map((jurisdiction) => ({
          label: jurisdiction.toUpperCase(),
          value: jurisdiction,
        }));
        setJurisdictions(jurArray);
      },
    }
  );

  const { refetch: refetchLocales } = useQuery(["locales"], getLocales, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      const locArray = data.map((locale) => ({
        label: locale,
        value: locale,
      }));
      setLocales(locArray);
    },
  });

  const { refetch: refetchLanguages } = useQuery(["languages"], getLanguages, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (data) => {
      const langArray = data.map((lannguage) => ({
        label: lannguage.toUpperCase(),
        value: lannguage,
      }));
      setLanguages(langArray);
    },
  });

  const { refetch: refetchWagerSets } = useQuery(
    [
      "wager-set",
      {
        limit: 100,
        page: 1,
      },
    ],
    getWagerSets,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data) => {
        const wagersArray = data?.items?.map((wager) => ({
          label: wager.name,
          value: wager.id,
        }));
        setWagerSets(wagersArray);
      },
    }
  );

  const handleCancelClick = () => {
    navigate(-1);
  };

  const updateSelectedData = (newData) => {
    //   setSelectedData((prevData) => {
    //     const updatedData = { ...prevData };

    //     Object.keys(newData).forEach((key) => {
    //       if (key === "available_rtp" || key === "available_volatility") {
    //         updatedData[key] = newData[key].split(",").map((value) => value.trim());
    //       } else {
    //         updatedData[key] = newData[key];
    //       }
    //     });

    //     return updatedData;
    //   });

    return new Promise((resolve) => {
      setSelectedData((prevData) => {
        const updatedData = { ...prevData };
        Object.keys(newData).forEach((key) => {
          if (key === "available_rtp" || key === "available_volatility") {
            updatedData[key] = newData[key]
              .split(",")
              .map((value) => value.trim());
          } else {
            updatedData[key] = newData[key];
          }
        });
        resolve(updatedData);
        return updatedData;
      });
    });
  };

  const handleCheckboxChange = (checkboxName) => {
    updateSelectedData({
      [checkboxName]: !selectedData[checkboxName],
    });
  };

  const { mutate } = useMutation(updateGame, {
    onSuccess: () => {
      toastAlert("success");
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const { mutate: onAddLocale } = useMutation(addLocale, {
    onSuccess: () => {
      toastAlert("success");
      refetchLocales();
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const { mutate: onDeleteLocale } = useMutation(deleteLocale, {
    onSuccess: () => {
      toastAlert("success");
      refetchLocales();
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const { mutate: onAddJurisdiction } = useMutation(addJurisdiction, {
    onSuccess: () => {
      toastAlert("success");
      refetchJurisdictions();
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const { mutate: onDeleteJurisdiction } = useMutation(deleteJurisdiction, {
    onSuccess: () => {
      toastAlert("success");
      refetchJurisdictions();
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const { mutate: onAddLanguage } = useMutation(addLanguage, {
    onSuccess: () => {
      toastAlert("success");
      refetchLanguages();
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const { mutate: onDeleteLanguage } = useMutation(deleteLanguage, {
    onSuccess: () => {
      toastAlert("success");
      refetchLanguages();
      refetch();
      setLoading(false);
    },
    onError: () => {
      toastAlert("error");
      setLoading(false);
    },
  });

  const toastAlert = (type) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Game was updated successfully`
          : `Couldn't update game data`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit((formData) => {
    if (!validateGambleDouble(gambleDouble)) {
      return;
    }

    setLoading(true);
    formData = getValues();
    formData.id = id;
    formData.is_demo = selectedData?.is_demo;
    formData.is_freespins = selectedData?.is_freespins;
    formData.is_public = selectedData?.is_public;
    formData.is_statistic_shown = selectedData?.is_statistic_shown;
    formData.online_volatility = selectedData?.online_volatility;
    formData.currencies = selectedData?.currencies;
    formData.languages = selectedData?.languages;
    formData.jurisdictions = selectedData?.jurisdictions;
    formData.user_locales = selectedData?.user_locales;

    formData.available_rtp = selectedData?.available_rtp;
    formData.rtp = selectedData?.rtp;
    formData.available_volatility = selectedData?.available_volatility;
    formData.volatility = selectedData?.volatility;
    formData.available_wager_sets_id = selectedData?.available_wager_sets_id;
    formData.gamble_double_up = selectedData?.gambleDoubleUp;
    formData.wager_set_id = selectedData?.wager_set_id;
    mutate(formData);
    setLoading(false);
    refetch();
  });

  useEffect(() => {
    refetch();
    refetchCurrencies();
    refetchJurisdictions();
    refetchLocales();
    refetchLanguages();
    refetchWagerSets();
  }, []);

  useEffect(() => {
    if (!isLoading && data) {
      setSelectedData((prevData) => ({
        ...prevData,
        name: data.name,
        api_url: data.api_url,
        client_url: data.client_url,
        organization_id: data.organization_id,
        wager_set_id: data.wager_set_id,
        is_demo: data.is_demo || false,
        is_freespins: data.is_freespins || false,
        is_public: data.is_public || false,
        is_statistic_shown: data.is_statistic_shown || false,
        online_volatility: data.online_volatility || false,
        currencies: data.currencies,
        jurisdictions: data.jurisdictions,
        languages: data.languages,
        user_locales: data.user_locales,

        available_rtp: data.available_rtp || [],
        rtp: data.rtp,
        available_volatility: data.available_volatility || [],
        volatility: data.volatility,
        available_wager_sets_id: data.available_wager_sets_id || [],
        gambleDoubleUp: data.gamble_double_up,
      }));
      setRtp(data.rtp);
      setVolatility(data.volatility);
      setGambleDouble(data.gamble_double_up);

      setValue("name", data.name);
      setValue("api_url", data.api_url);
      setValue("client_url", data.client_url);
      setValue("organization_id", data.organization_id);
      setValue("wager_set_id", data.wager_set_id);
      setValue("available_rtp", data.available_rtp);
      setValue("available_volatility", data.available_volatility);
      setValue("available_wager_sets_id", data.available_wager_sets_id);

      setDefaultWagerSet(data.wager_set_id);
    }
  }, [isLoading, data]);

  const getSelectedData = () => {
    return new Promise((resolve) => {
      setSelectedData((prevData) => {
        resolve(prevData);
        return prevData;
      });
    });
  };

  const updateAvailableWagerSet = async (value) => {
    await updateSelectedData({ available_wager_sets_id: value });
    setValue("available_wager_sets_id", value);

    const updatedData = await getSelectedData();

    if (
      !updatedData.available_wager_sets_id.includes(updatedData.wager_set_id)
    ) {
      const newWagerSetId =
        updatedData.available_wager_sets_id[0] || defaultWagerSet;
      await updateSelectedData({ wager_set_id: newWagerSetId });
      setValue("available_wager_sets_id", newWagerSetId);
    }

    if (updatedData.available_wager_sets_id.length > 0) {
    } else {
      if (defaultWagerSet) {
        await updateSelectedData({ wager_set_id: defaultWagerSet });
        setValue("wager_set_id", defaultWagerSet);
      }
    }
  };

  useEffect(() => {
    refetch();
    refetchCurrencies();
    refetchJurisdictions();
    refetchLocales();
    refetchLanguages();
  }, [organizationId]);

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Game Details
          </span>
        </div>
      </div>
      <Card className="wd-90p mg-l-40">
        <Card.Body className="custom-card">
          <div className="text-wrap">
            <div className="border">
              <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                <div className="bg-gray-100 nav-bg">
                  <Nav className="nav nav-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="link-29">General info</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content className="card-body ">
                  <Tab.Pane eventKey="link-29">
                    <Form
                      className={`wd-100p needs-validation${
                        isSubmitted ? "" : ""
                      }`}
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate={true}
                    >
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              placeholder="Enter name"
                              type="text"
                              {...register("name", {
                                required: true,
                                validate: (value) => value?.trim() !== "",
                              })}
                              isInvalid={!!errors?.name}
                              isValid={isSubmitted && !errors?.name}
                            />
                            {errors?.name && (
                              <Form.Control.Feedback type="invalid">
                                Name is required
                              </Form.Control.Feedback>
                            )}
                            <Form.Label>API URL</Form.Label>
                            <Form.Control
                              placeholder="Enter url"
                              type="text"
                              {...register("api_url", {})}
                            />
                            <Form.Label>Client URL</Form.Label>
                            <Form.Control
                              placeholder="Enter client url"
                              type="text"
                              {...register("client_url", {})}
                            />
                            <Form.Label>Provider ID</Form.Label>
                            <Form.Control
                              placeholder="Enter provider ID"
                              type="text"
                              disabled
                              {...register("organization_id", {
                                required: true,
                                validate: (value) => value?.trim() !== "",
                              })}
                              isInvalid={!!errors?.organization_id}
                              isValid={isSubmitted && !errors?.organization_id}
                            />
                            {errors?.organization_id && (
                              <Form.Control.Feedback type="invalid">
                                Provider ID is required
                              </Form.Control.Feedback>
                            )}
                            <Form.Label>Available wager set</Form.Label>
                            <div className="d-flex flex-row">
                              <Controller
                                name="available_wager_sets_id"
                                control={control}
                                rules={{
                                  required: "Available wager set is required",
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    // {...field}
                                    value={selectedData.available_wager_sets_id}
                                    mode="multiple"
                                    className={`form-control mr-0 ${
                                      fieldState.error ? "is-invalid" : ""
                                    }`}
                                    optionFilterProp="label"
                                    maxTagCount={2}
                                    options={wagerSets}
                                    placeholder="Select available wager set to add..."
                                    dropdownStyle={{
                                      zIndex: "9999999999",
                                    }}
                                    onChange={(value) => {
                                      field.onChange(value);
                                      updateAvailableWagerSet(value);
                                      // updateSelectedData({ available_wager_sets_id: value });
                                    }}
                                  />
                                )}
                              />
                            </div>
                            {errors.available_wager_sets_id && (
                              <div className="invalid-feedback-show">
                                {errors.available_wager_sets_id.message}
                              </div>
                            )}
                            <Form.Label>Wager set</Form.Label>
                            <Controller
                              name="wager_set_id"
                              control={control}
                              rules={{ required: "Wager set is required" }}
                              render={({ field }) => (
                                <Select
                                  // {...field}
                                  // value={selectedData.wager_set_id}
                                  value={wagerSets.filter((wager) =>
                                    selectedData?.wager_set_id?.includes(
                                      wager.value
                                    )
                                  )}
                                  showSearch
                                  className={`form-control ${
                                    errors.wager_set_id ? "is-invalid" : ""
                                  }`}
                                  optionFilterProp="children"
                                  popupClassName={"custom-dropdown"}
                                  dropdownStyle={{
                                    zIndex: "9999999999",
                                  }}
                                  options={wagerSets.filter((wager) =>
                                    selectedData.available_wager_sets_id.includes(
                                      wager.value
                                    )
                                  )}
                                  // options={wagerSets}
                                  placeholder="Select wager set..."
                                  onChange={(value) => {
                                    field.onChange(value);
                                    updateSelectedData({ wager_set_id: value });
                                  }}
                                />
                              )}
                            />
                            {errors.wager_set_id && (
                              <div className="invalid-feedback">
                                {errors.wager_set_id.message}
                              </div>
                            )}

                            <Form.Label>Available RTP</Form.Label>
                            <Select
                              mode="multiple"
                              className="form-control mr-0"
                              optionFilterProp="children"
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              value={
                                selectedData?.available_rtp.length
                                  ? selectedData?.available_rtp.map(
                                      (rtp, index) => ({
                                        label: rtp,
                                        key: index,
                                      })
                                    )
                                  : []
                              }
                              options={selectedData?.available_rtp.map(
                                (rtp) => ({ label: rtp })
                              )}
                              onChange={removeItem("available_rtp")}
                            />

                            <Form.Label>Add Available RTP</Form.Label>
                            <div className="d-flex flex-row">
                              <Form.Control
                                placeholder="Add new RTP ..."
                                className={`${
                                  newOptionRtpError ? "is-invalid" : ""
                                } mg-r-15`}
                                type="text"
                                value={newOptionRtp}
                                onClick={() =>
                                  newOptionRtpError && setNewOptionRtpError("")
                                }
                                onChange={(e) =>
                                  setNewOptionRtp(e.target.value)
                                }
                              />
                              <Button
                                className="btn btn-primary"
                                onClick={addNewOptionRtp}
                              >
                                Add
                              </Button>
                            </div>
                            {newOptionRtpError && (
                              <div className="invalid-feedback-show">
                                {newOptionRtpError}
                              </div>
                            )}

                            <Form.Label>Available volatility</Form.Label>
                            <Select
                              mode="multiple"
                              className="form-control mr-0"
                              optionFilterProp="children"
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              value={
                                selectedData?.available_volatility.length
                                  ? selectedData?.available_volatility.map(
                                      (volatility, index) => ({
                                        label: volatility,
                                        key: index,
                                      })
                                    )
                                  : []
                              }
                              options={selectedData?.available_volatility.map(
                                (volatility) => ({ label: volatility })
                              )}
                              onChange={removeItem("available_volatility")}
                            />

                            <Form.Label>Add volatility</Form.Label>
                            <div className="d-flex flex-row">
                              <Form.Control
                                placeholder="Add new volatility ..."
                                className={`${
                                  newOptionVolatilityError ? "is-invalid" : ""
                                } mg-r-15`}
                                type="text"
                                value={newOptionVolatility}
                                onClick={() =>
                                  newOptionVolatilityError &&
                                  setNewOptionVolatilityError("")
                                }
                                onChange={(e) =>
                                  setNewOptionVolatility(e.target.value)
                                }
                              />
                              <Button
                                className="btn btn-primary"
                                onClick={addNewOptionVolatility}
                              >
                                Add
                              </Button>
                            </div>
                            {newOptionVolatilityError && (
                              <div className="invalid-feedback-show">
                                {newOptionVolatilityError}
                              </div>
                            )}

                            <Form.Label>Gamble double</Form.Label>
                            <div className="d-flex flex-row">
                              <Form.Control
                                placeholder="Enter gamble double ..."
                                className={`${
                                  gambleDoubleUpError ? "is-invalid" : ""
                                } mg-r-15`}
                                type="text"
                                value={gambleDouble}
                                onClick={() =>
                                  gambleDoubleUpError &&
                                  setGambleDoubleUpError("")
                                }
                                onChange={(e) => {
                                  handleInputGamble(e.target.value);
                                }}
                              />
                            </div>
                            {gambleDoubleUpError && (
                              <div className="invalid-feedback-show">
                                {gambleDoubleUpError}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="form-group">
                            <Form.Label>Currencies</Form.Label>
                            <Select
                              mode="multiple"
                              className="form-control mr-0"
                              optionFilterProp="label"
                              maxTagCount={4}
                              value={selectedData?.currencies}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={currencies}
                              placeholder="Select currencies to add..."
                              onChange={(value) =>
                                updateSelectedData({ currencies: value })
                              }
                            />
                            <Form.Label>Jurisdictions</Form.Label>
                            <Select
                              mode="multiple"
                              className="form-control mr-0"
                              optionFilterProp="label"
                              maxTagCount={3}
                              value={selectedData?.jurisdictions}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={jurisdictions}
                              placeholder="Select jurisdictions to add..."
                              onChange={(value) =>
                                updateSelectedData({ jurisdictions: value })
                              }
                            />
                            <Form.Label>Edit jurisdiction</Form.Label>
                            <div className="d-flex flex-row">
                              <Form.Control
                                placeholder="Add jurisdiction for editing ..."
                                className={`${
                                  newJurisdictionError ? "is-invalid" : ""
                                } mg-r-15`}
                                type="text"
                                value={newJurisdiction}
                                onClick={() =>
                                  newJurisdictionError &&
                                  setNewJurisdictionError("")
                                }
                                onChange={(e) =>
                                  setNewJurisdiction(e.target.value)
                                }
                              />
                              <Button
                                className="btn btn-primary mg-r-15"
                                onClick={addNewJurisdiction}
                              >
                                Add
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={handleDeleteJurisdiction}
                              >
                                Delete
                              </Button>
                            </div>
                            {newJurisdictionError && (
                              <div className="invalid-feedback-show">
                                {newJurisdictionError}
                              </div>
                            )}
                            <Form.Label>Locales</Form.Label>
                            <Select
                              mode="multiple"
                              className="form-control mr-0"
                              optionFilterProp="label"
                              maxTagCount={3}
                              value={selectedData?.user_locales}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={locales}
                              placeholder="Select jurisdictions to add..."
                              onChange={(value) =>
                                updateSelectedData({ user_locales: value })
                              }
                            />

                            <Form.Label>Edit locale</Form.Label>
                            <div className="d-flex flex-row">
                              <Form.Control
                                placeholder="Add locale for editing ..."
                                className={`${
                                  newLocaleError ? "is-invalid" : ""
                                } mg-r-15`}
                                type="text"
                                value={newLocale}
                                onClick={() =>
                                  newLocaleError && setNewLocaleError("")
                                }
                                onChange={(e) => setNewLocale(e.target.value)}
                              />
                              <Button
                                className="btn btn-primary mg-r-15"
                                onClick={addNewLocale}
                              >
                                Add
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={handleDeleteLocale}
                              >
                                Delete
                              </Button>
                            </div>
                            {newLocaleError && (
                              <div className="invalid-feedback-show">
                                {newLocaleError}
                              </div>
                            )}
                            <Form.Label>Languages</Form.Label>
                            <Select
                              mode="multiple"
                              className="form-control mr-0"
                              optionFilterProp="label"
                              maxTagCount={4}
                              value={selectedData?.languages}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={languages}
                              placeholder="Select languages to add..."
                              onChange={(value) =>
                                updateSelectedData({ languages: value })
                              }
                            />
                            <Form.Label>Edit language</Form.Label>
                            <div className="d-flex flex-row">
                              <Form.Control
                                placeholder="Add language for editing ..."
                                className={`${
                                  newLanguageError ? "is-invalid" : ""
                                } mg-r-15`}
                                type="text"
                                value={newLanguage}
                                onClick={() =>
                                  newLanguageError && setNewLanguageError("")
                                }
                                onChange={(e) => setNewLanguage(e.target.value)}
                              />
                              <Button
                                className="btn btn-primary mg-r-15"
                                onClick={addNewLanguage}
                              >
                                Add
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={handleDeleteLanguage}
                              >
                                Delete
                              </Button>
                            </div>
                            {newLanguageError && (
                              <div className="invalid-feedback-show">
                                {newLanguageError}
                              </div>
                            )}
                            <Form.Label>RTP</Form.Label>
                            <Select
                              mode="single"
                              className="form-control mr-0"
                              optionFilterProp="children"
                              value={rtp}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={selectedData?.available_rtp.map(
                                (option) => ({ label: option, value: option })
                              )}
                              placeholder="Select RTP ..."
                              onChange={(value) => {
                                updateSelectedData({ rtp: value });
                                setRtp(value);
                              }}
                            />

                            <Form.Label>Volatility</Form.Label>
                            <Select
                              mode="single"
                              className="form-control mr-0"
                              optionFilterProp="children"
                              value={volatility}
                              dropdownStyle={{
                                zIndex: "9999999999",
                              }}
                              options={selectedData?.available_volatility.map(
                                (option) => ({ label: option, value: option })
                              )}
                              placeholder="Select volatility ..."
                              onChange={(value) => {
                                updateSelectedData({ volatility: value });
                                setVolatility(value);
                              }}
                            />
                          </Form.Group>
                          <Row className="mg-t-40">
                            <Form.Label className="mg-r-30 mg-l-30 custom-control custom-checkbox custom-control-md">
                              <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="example-checkbox1"
                                checked={selectedData?.is_demo}
                                onChange={() => handleCheckboxChange("is_demo")}
                              />
                              <span className="custom-control-label custom-control-label-md tx-17">
                                Demo
                              </span>
                            </Form.Label>
                            <Form.Label className="custom-control custom-checkbox custom-control-md">
                              <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="example-checkbox2"
                                checked={selectedData?.is_freespins}
                                onChange={() =>
                                  handleCheckboxChange("is_freespins")
                                }
                              />
                              <span className="custom-control-label custom-control-label-md tx-17">
                                Free spins
                              </span>
                            </Form.Label>
                          </Row>
                          <Row className="mg-t-25">
                            <Form.Label className="custom-control custom-checkbox custom-control-md">
                              <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="example-checkbox1"
                                checked={selectedData?.is_public}
                                onChange={() =>
                                  handleCheckboxChange("is_public")
                                }
                              />
                              <span className="mg-r-30 mg-l-30 custom-control-label custom-control-label-md tx-17">
                                Public
                              </span>
                            </Form.Label>
                            <Form.Label className="custom-control custom-checkbox custom-control-md">
                              <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="example-checkbox2"
                                checked={selectedData?.is_statistic_shown}
                                onChange={() =>
                                  handleCheckboxChange("is_statistic_shown")
                                }
                              />
                              <span className="custom-control-label custom-control-label-md tx-17">
                                Show statistics
                              </span>
                            </Form.Label>
                          </Row>
                          <Row className="mg-t-25">
                            <Form.Label className="custom-control custom-checkbox custom-control-md">
                              <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="online_volatility"
                                checked={selectedData?.online_volatility}
                                onChange={() =>
                                  handleCheckboxChange("online_volatility")
                                }
                              />
                              <span className="mg-r-30 mg-l-30 custom-control-label custom-control-label-md tx-17">
                                Online volatility
                              </span>
                            </Form.Label>
                          </Row>
                        </Col>
                      </Row>
                      <div className="d-flex mg-t-100 wd-100p justify-content-center">
                        <Button
                          disabled={loading}
                          variant="primary"
                          type="submit"
                          className="mg-r-15"
                        >
                          Save
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            handleCancelClick();
                          }}
                        >
                          Return
                        </Button>
                      </div>
                    </Form>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default GameDetail;
