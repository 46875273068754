import httpClient from "./httpClient";

export const getCurrenciesInfo = async () => {
  const { data } = await httpClient.get("/currency");
  if (data) {
    return data.data;
  }
};

export const getCurrenciesExchangeRate = async ({ queryKey }) => {
  const allias = queryKey[0];
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
    `/currency/${allias}/exchange?${params.toString()}`
  );
  if (data) {
    return data.data.items;
  }
};

export const createCurrency = async (formData) => {
  const { data } = await httpClient.post("/currency", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const deleteCurrencyInfo = async (formData) => {
  const { data } = await httpClient.delete(`/currency/${formData}`, {
    data: formData,
  });
  if (data) {
    return data.data;
  }
};
export const deleteExchangeInfo = async (formData) => {
  const { created_at, from, rate, allias } = formData;
  const { data } = await httpClient.delete(`/currency/${allias}/exchange`, {
    data: {
      created_at,
      from,
      rate,
    },
  });
  if (data) {
    return data.data;
  }
};

export const addExchange = async (formData) => {
  const { allias, from, rate } = formData;
  const { data } = await httpClient.post(`/currency/${allias}/exchange`, {
    from,
    rate,
  });
  if (data) {
    return data.data;
  }
};

export const getAllCurrencies = async () => {
  const { data } = await httpClient.post("/currency/multiplier/search", {
    data: {},
  });
  if (data) {
    return data.data;
  }
};

export const getCurrenciesByPair = async ({ queryKey }) => {
  const { organization_pair_id } = queryKey[1];
  const { data } = await httpClient.post("/currency/multiplier/search", {
    organization_pair_id: organization_pair_id,
  });
  if (data) {
    return data.data;
  }
};

export const addCurrency = async (formData) => {
  const { data } = await httpClient.post("/currency/multiplier", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const updateCurrency = async (formData) => {
  const { data } = await httpClient.put("/currency/multiplier", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const deleteCurrency = async (formData) => {
  console.log(formData);
  const { data } = await httpClient.delete("/currency/multiplier", {
    data: formData,
  });
  if (data) {
    return data.data;
  }
};

export const getReportsByMultiplier = async ({ organization_pair_id }) => {
  const response = await httpClient.post(
    `/currency/multiplier/download`,
    {
      organization_pair_id: organization_pair_id,
    },
    {
      responseType: "blob",
    }
  );

  if (response.status === 200) {
    return response.data;
  }
};

export const uploadReportsByMultiplier = async ({ formData }) => {
  const response = await httpClient.post(
    `/currency/multiplier/upload`,
    formData
  );

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Ошибка при загрузке файла");
  }
};
